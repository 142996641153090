define("additive-account/validators/specific-value", ["exports", "ember-changeset-validations/utils/validation-errors", "@ember/utils"], function (_exports, _validationErrors, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateSpecificValue;
  function validateSpecificValue() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      shouldBe: null
    };
    if ((0, _utils.isEmpty)(options.shouldBe)) {
      throw new Error('Reference value is missing');
    }
    return (key, value) => {
      if (value === options.shouldBe) {
        return true;
      }
      return (0, _validationErrors.default)(key, {
        type: 'specificValue',
        value,
        context: options
      });
    };
  }
});