define("additive-account/utils/api-query-errors", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.combineErrors = combineErrors;
  /**
   * give me:
   *   ['email:validation.required', 'password:validation.required']
   * and I give you:
   *   {
   *     email: ['validation.required'],
   *     password:['validation.required'],
   *   }
   **/
  function combineErrors(errors) {
    if (typeof errors === 'undefined' || !Array.isArray(errors)) {
      return [];
    }
    let combinedErrors = {};
    errors.forEach(error => {
      var _combinedErrors$key;
      let [key, errorValue] = error.split(':');
      if ((0, _utils.isEmpty)(combinedErrors === null || combinedErrors === void 0 ? void 0 : combinedErrors[key])) {
        (0, _object.set)(combinedErrors, key, []);
      }
      combinedErrors === null || combinedErrors === void 0 || (_combinedErrors$key = combinedErrors[key]) === null || _combinedErrors$key === void 0 || _combinedErrors$key.push(errorValue);
    });
    return combinedErrors;
  }
});