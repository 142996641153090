define("additive-account/controllers/auth/signup", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/service", "@ember/string", "fetch", "ember-concurrency", "rsvp"], function (_exports, _environment, _controller, _object, _service, _string, _fetch, _emberConcurrency, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Possible query-params:
   *  - token: inivtationToken
   */
  var _default = _exports.default = _controller.default.extend({
    intl: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    queryParams: ['token'],
    errors: null,
    token: null,
    isLoading: _object.computed.alias('signUpUser.isRunning'),
    checkInvite: (0, _emberConcurrency.task)(function* (token) {
      return yield (0, _fetch.default)(`${_environment.default.APP.apiBaseHost}/api/invites/${token}`, {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      }).then(response => response.json()).then(response => {
        if (response && response.email) {
          const {
            email,
            inviterFullName,
            organizationName,
            organizationId,
            language
          } = response;
          (0, _object.set)(this, 'inviter', inviterFullName);
          (0, _object.set)(this, 'organizationName', organizationName);
          (0, _object.set)(this, 'organizationId', organizationId);
          language && this.uiLocale.setLocale(language);
          this.changeset.set('email', email);
          // changeset has to be persisted in order to prefille input
          this.changeset.save();
          return (0, _rsvp.resolve)({
            email,
            inviterFullName,
            organizationName
          });
        }
        return (0, _rsvp.reject)(response);
      });
    }),
    /**
     * User signup
     * 204 - success
     * 422 - validation error
     */
    signUpUser: (0, _emberConcurrency.task)(function* () {
      const changeset = this.changeset;
      const email = changeset.get('email');
      const body = {
        email,
        givenName: changeset.get('givenName'),
        familyName: changeset.get('familyName'),
        password: changeset.get('password'),
        language: this.uiLocale.locale
      };
      // on success:
      // clean up some pending states and redirect to confirm
      try {
        const response = yield (0, _fetch.default)(`${_environment.default.APP.apiBaseHost}/auth/signup/${this.token}`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
          }
        });
        if (!response.ok) {
          const {
            errors
          } = yield response.json();
          const intl = this.intl;
          // TODO: push to form
          Object.keys(errors).forEach(key => {
            const keyCamelized = (0, _string.camelize)(key);
            changeset.pushErrors(keyCamelized, intl.t(`global.errors.apiQueryErrors.${errors[key]}`));
            (0, _object.set)(this, 'isFormTouched', true);
          });
        } else {
          // TODO
          // signup was successfull
          if (this.token) {
            const organizationId = this.organizationId;
            (0, _object.set)(this, 'token', null);
            // Transition to organization screen
            this.transitionToRoute('instances.instance', organizationId);
          } else {
            this.transitionToRoute('confirm', {
              queryParams: {
                signup_email: email
              }
            });
          }
        }
      } catch (error) {
        // unexpected error
      }
    }),
    actions: {
      signUp() {
        const changeset = this.changeset;
        return changeset.validate().then(() => {
          if (changeset.get('isValid')) {
            return this.signUpUser.perform();
          }
          (0, _object.set)(this, 'isFormTouched', true);
          return (0, _rsvp.resolve)();
        });
      }
    }
  });
});