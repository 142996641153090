define("additive-account/components/aa-file-upload/input", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types", "@ember/object/internals", "@ember/template-factory"], function (_exports, _component, _component2, _object, _emberArgTypes, _propTypes, _internals, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    class="aa-image-upload flex flex-row items-center justify-center
      {{@class}}
      {{if @isDisabled 'cdefault' 'cpointer'}}
      {{if @url 'aa-image-upload__image-set' 'aa-image-upload__no-image-set'}}
      {{if @isSmall 'aa-image-upload__small'}}"
    for={{this._for}}
  >
    {{#if @url}}
      <div
        class="aa-image-upload__image-wrapper w-100 h-100 flex flex-grow-1 justify-center items-center"
      >
        <img class="aa-image-upload__image dib w-100" src={{@url}} alt={{@alt}} />
      </div>
      {{#if @showDeleteButton}}
        <UiIconButton
          @class="aa-image-upload__delete-logo absolute white top-0 right-0 cpointer pa05"
          @icon="close"
          @onClick={{@onDelete}}
        />
      {{/if}}
    {{else if @showLoading}}
      <div class="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center primary">
        <UiLoading />
      </div>
    {{else}}
      <div class="w-100 h-100 flex flex-grow-1 justify-center items-center">
        {{#if @placeholderTitle}}
          <div class="font-sm blue text-center">
            {{@placeholderTitle}}
          </div>
        {{else}}
          {{svg-jar "camera"}}
        {{/if}}
      </div>
    {{/if}}
    {{#unless @isDisabled}}
      <input type="file" id={{this._for}} class="dn" accept={{@accept}} onchange={{this._onChange}} />
    {{/unless}}
  </label>
  */
  {
    "id": "4mGY64TJ",
    "block": "[[[10,\"label\"],[15,0,[29,[\"aa-image-upload flex flex-row items-center justify-center\\n    \",[30,1],\"\\n    \",[52,[30,2],\"cdefault\",\"cpointer\"],\"\\n    \",[52,[30,3],\"aa-image-upload__image-set\",\"aa-image-upload__no-image-set\"],\"\\n    \",[52,[30,4],\"aa-image-upload__small\"]]]],[15,\"for\",[30,0,[\"_for\"]]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"aa-image-upload__image-wrapper w-100 h-100 flex flex-grow-1 justify-center items-center\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"aa-image-upload__image dib w-100\"],[15,\"src\",[30,3]],[15,\"alt\",[30,5]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[8,[39,1],null,[[\"@class\",\"@icon\",\"@onClick\"],[\"aa-image-upload__delete-logo absolute white top-0 right-0 cpointer pa05\",\"close\",[30,7]]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,8],[[[1,\"    \"],[10,0],[14,0,\"absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center primary\"],[12],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"w-100 h-100 flex flex-grow-1 justify-center items-center\"],[12],[1,\"\\n\"],[41,[30,9],[[[1,\"        \"],[10,0],[14,0,\"font-sm blue text-center\"],[12],[1,\"\\n          \"],[1,[30,9]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"camera\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[41,[51,[30,2]],[[[1,\"    \"],[10,\"input\"],[15,1,[30,0,[\"_for\"]]],[14,0,\"dn\"],[15,\"accept\",[30,10]],[15,\"onchange\",[30,0,[\"_onChange\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"@isDisabled\",\"@url\",\"@isSmall\",\"@alt\",\"@showDeleteButton\",\"@onDelete\",\"@showLoading\",\"@placeholderTitle\",\"@accept\"],false,[\"if\",\"ui-icon-button\",\"ui-loading\",\"svg-jar\",\"unless\"]]",
    "moduleName": "additive-account/components/aa-file-upload/input.hbs",
    "isStrictMode": false
  });
  let AaFileUploadInput = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AaFileUploadInput extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "onChange", _descriptor, this);
    }
    /**
     * the unique id of the input
     *
     * @computed for
     * @type {String}
     */
    get _for() {
      return `file-input-${(0, _internals.guidFor)(this)}`;
    }
    _onChange(event) {
      this.onChange(event);
      const inputElement = document.getElementById(this._for);
      inputElement.value = '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaFileUploadInput);
});