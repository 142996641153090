define("additive-account/helpers/country-name", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countryName = countryName;
  _exports.default = void 0;
  /**
   * Returns the name of the country given the country code and
   * an array of country objects containing the code and name property
   *
   * @export
   * @param {*} params
   * @returns {String} country name
   */
  function countryName(params) {
    const code = params[0];
    const countries = params[1];
    if (countries && countries.length) {
      const country = countries.find(country => country.code === code);
      return country ? country.name : code;
    }
    return code;
  }
  var _default = _exports.default = (0, _helper.helper)(countryName);
});