define("additive-account/routes/confirm", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The route has two purposes:
   *  - as destination after a user signs-up
   *  - and to validate the sign-up tokens
   */
  var _default = _exports.default = _route.default.extend({
    intl: (0, _service.inject)(),
    setupController(controller) {
      this._super(...arguments);
      const {
        email_verification_code,
        token,
        signup_email
      } = controller;
      // Check if its a regular sign-up or an invite
      if (email_verification_code) {
        controller.confirmUser.perform(email_verification_code);
      } else if (token) {
        // Check if its a regular sign-up or an invite
        controller.checkInvitationToken.perform(token);
      } else {
        const intl = this.intl;
        const pageContent = {
          illustration: 'mail-white',
          title: intl.t('confirm.signup.title'),
          description: intl.t('confirm.signup.description', {
            email: signup_email,
            htmlSafe: true
          }),
          actionLabel: intl.t('confirm.signup.actionLabel')
        };
        (0, _object.set)(controller, 'viewContent', pageContent);
      }
    }
  });
});