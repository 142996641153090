define("additive-account/routes/instances/create", ["exports", "additive-account/config/environment", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "additive-account/validations/organization", "ember-changeset-validations", "rsvp"], function (_exports, _environment, _route, _object, _service, _emberChangeset, _organization, _emberChangesetValidations, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    // route where an unauthenticated user gets redirected
    authenticationRoute: 'auth.login',
    model() {
      return this.store.createRecord('organization');
    },
    setupController(controller, model) {
      this._super(...arguments);

      // With given app_id and plan_name as query-param, pre-add the corresponding app with plan to cart
      // In case the app_id or plan_name is not a proper value, pre-add nothing and simply render the normal route.
      const {
        app_id,
        plan_name
      } = controller;
      if (app_id && plan_name) {
        // We have to fetch the apps to get the proper plan-amount and name to be able to pre-add to card
        // Unfortunatley in this case with given app_id and plan_name it will fetch the buyable-apps twice.
        // Here and the ui-app-selection component, maybe refactor to only fetch once
        this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/apps?buyable=1`).then(response => response.ok ? response.json() : (0, _rsvp.reject)()).then(response => {
          if (!controller || controller.isDestroying || !response.apps) {
            return;
          }
          const queryParamAppId = parseInt(controller.app_id);
          const queryParamPlanName = controller.plan_name;
          const preSelectedApp = (response.apps || []).findBy('id', queryParamAppId);
          if (!preSelectedApp || !preSelectedApp.plans) {
            return;
          }
          const preSelectedPlan = (preSelectedApp.plans || []).findBy('id', queryParamPlanName);
          if (!preSelectedPlan) {
            return;
          }
          const preSelectedAppName = preSelectedApp.name;
          const preSelectedPlanAmount = preSelectedPlan.amount;
          // Add the app to cart
          controller.addApp(queryParamAppId, preSelectedAppName, queryParamPlanName, preSelectedPlanAmount);
        });
        // with only app_id given as query-param, we cannot completley pre-add this case to cart
        // so start route with open plan-select-dialog for the given app
      } else if (app_id) {
        (0, _object.set)(controller, 'isAppSelectionOpen', true);
        (0, _object.set)(controller, 'preSelectedApp', {
          id: parseInt(controller.app_id)
        });
      }
      const intl = this.intl;
      /*
       * we pass the intl instance, to get the field names
       * from our translation file.
       */
      const fullOrgValidation = (0, _organization.fullOrganization)(intl);
      const changeset = new _emberChangeset.default(Object.assign({}, model, {
        appPlans: []
      }), (0, _emberChangesetValidations.default)(fullOrgValidation), fullOrgValidation);
      changeset.set('isPartner', false);
      (0, _object.set)(controller, 'changeset', changeset);
    }
  });
});