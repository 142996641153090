define("additive-account/adapters/corporate-design", ["exports", "additive-account/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    buildURL(type, id) {
      const baseUrl = `${this.host}/api/organizations/${id}/${type}`;
      return baseUrl;
    }
  });
});