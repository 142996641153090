define("additive-account/components/user-profile-view", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "additive-account/validations/user", "additive-account/utils/constants"], function (_exports, _component, _object, _service, _emberChangeset, _emberChangesetValidations, _user, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    isEditing: false,
    isDiscardChangesDialog: false,
    tagName: '',
    onConfirm() {},
    onClose() {},
    init() {
      this._super(...arguments);
      const intl = this.intl;
      const user = this.user;
      const validation = (0, _user.userValidation)(intl);
      const userChangeset = new _emberChangeset.default(user, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.set)(this, 'userChangeset', userChangeset);
      const languageOptions = _constants.APP_LANGUAGES.map(lang => ({
        name: intl.t(`languageSelect.${lang}`),
        value: lang
      }));
      (0, _object.set)(this, 'languageOptions', languageOptions);
    },
    actions: {
      onClose() {
        const userChangeset = this.userChangeset;

        // close the profile view if no changes have been made
        if (userChangeset.get('isPristine')) {
          this.onClose();
        } else {
          (0, _object.set)(this, 'isDiscardChangesDialog', true);
        }
      },
      confirmDiscard() {
        (0, _object.set)(this, 'isDiscardChangesDialog', false);
        this.onClose();
      }
    }
  });
});