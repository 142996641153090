define("additive-account/components/app-plan-card", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    currentUser: (0, _service.inject)(),
    classNames: ['app-plan-card', 'flex-sm flex-column-sm flex-grow-1', 'br1', 'mw-100 mw-50-sm mw-33-md'],
    /**
     * the selected app
     *
     * @argument activeApp
     * @type {Object}
     * @default null
     */
    activeApp: null,
    /**
     * the selected plan
     *
     * @argument plan
     * @type {Object}
     * @default null
     */
    plan: null,
    /**
     * whether the button is disabled
     *
     * @argument isButtonDisabled
     * @type {Boolean}
     * @default false
     */
    isButtonDisabled: false,
    /**
     * the button text
     *
     * @argument actionText
     * @type {String}
     * @default null
     */
    actionText: null,
    isRecommended: _object.computed.reads('plan.recommended'),
    /**
     * whether the price has the price from prefix
     *
     * @computed hasPriceFrom
     * @type {Boolean}
     */
    hasPriceFrom: (0, _object.computed)('activeApp.id', {
      get() {
        return this.activeApp && this.activeApp.id === 7;
      }
    }),
    addApp: () => {}
  });
});