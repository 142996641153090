define("additive-account/helpers/can-update-role", ["exports", "@ember/component/helper", "additive-account/utils/constants"], function (_exports, _helper, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canUpdateRole = canUpdateRole;
  _exports.default = void 0;
  /**
   * Checks if the current logged in user has the necessary right
   * to update an other user based on it's role.
   *
   * @param {String} userRole the current logged in users role
   * @param {String} role the role to be updated
   */
  function canUpdateRole(_ref) {
    let [userRole, role] = _ref;
    const map = _constants.ROLE_UPDATE_MAP[userRole];
    return (map || []).indexOf(role) > -1;
  }
  var _default = _exports.default = (0, _helper.helper)(canUpdateRole);
});