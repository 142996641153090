define("additive-account/controllers/instances/instance/apps", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "ember-concurrency", "additive-account/mixins/global-user", "@additive-apps/ui/utils/app-utils", "additive-account/utils/app-management"], function (_exports, _environment, _controller, _object, _service, _rsvp, _emberConcurrency, _globalUser, _appUtils, _appManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RIGHT_SIDENAV = 'apps-detail-sidenav';
  var _default = _exports.default = _controller.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiState: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * All apps of the organization
     *
     * @property apps
     * @type {Object[]}
     */
    apps: undefined,
    /**
     * All available apps containing all the possible plans
     *
     * @property availableApps
     * @type {Object[]}
     */
    availableApps: undefined,
    /**
     * All that the organization does not have
     *
     * @property addableApps
     * @type {Object[]}
     */
    addableApps: undefined,
    /**
     * Sets the duration of the toast that is displayed
     *
     * @property toastDuration
     * @type {Integer}
     */
    toastDuration: 2500,
    /**
     * Whether the dialog to add a new app is shown
     *
     * @property isAppListDialog
     * @type {Boolean}
     */
    isAppListDialog: false,
    /**
     * The key of the state of the detail drawer
     *
     * @property rightSidenavKey
     * @type {String}
     */
    rightSidenavKey: undefined,
    isModulesDialogOpen: false,
    routeIfUnauthorized: 'instances.instance',
    /**
     * whether the user is part of a Partner organization
     * @property isPartner
     * @type {Boolean}
     */
    isPartner: _object.computed.bool('globalUser.partnerSlug'),
    /**
     * the current organization
     * @property currentOrganzation
     * @type {Object}
     */
    currentOrganzation: _object.computed.alias('currentUser.currentOrganization'),
    /**
     * This will be true if the needed properties "currentOrganization" and "globalUser" are still loading.
     * We need both props to be able to check if the user can access this route.
     * During fetching, show a loading-icon.
     *
     * @property isLoading
     * @type {Boolean}
     */
    isLoading: (0, _object.computed)('currentUser.currentOrganization.partnerSlug', 'globalUser.partnerSlug', 'isOrgPartner', 'routeIfUnauthorized', {
      get() {
        const isOrgPartner = this.isOrgPartner;
        // This property comes from the globalUser mixin and will be true if the user is a partner of the current org
        if (isOrgPartner) {
          return false;
        }
        const currentOrganization = this.currentUser && this.currentUser.currentOrganization;
        const globalUser = this.globalUser;

        // Wait for organization and user fetched
        if (!currentOrganization || !globalUser) {
          return true;
        }

        // If we have fetched the org and the globalUser properly but the isOrgPartner prop is still false
        // then the user is not a proper org-partner and we route him away
        if (currentOrganization.partnerSlug && !isOrgPartner) {
          const routeIfUnauthorized = this.routeIfUnauthorized;
          this.transitionToRoute(routeIfUnauthorized);
        }

        // Hide loading and show content if is not a partner-org or is partner-org and proper partner-user
        if (!currentOrganization.partnerSlug || currentOrganization.partnerSlug && isOrgPartner) {
          return false;
        }
        return true;
      }
    }),
    /**
     * The name of the organisation which manages the app
     *
     * @property managedBy
     * @type {String}
     */
    managedByOrganisation: (0, _object.computed)('currentApp.{managed,managedBy}', 'currentOrganzation.partnerName', {
      get() {
        const {
          managedBy
        } = this.currentApp;
        if (managedBy === 'additive') {
          return 'ADDITIVE';
        }
        return this.currentOrganzation.partnerName;
      }
    }).readOnly(),
    /**
     * The organizations app-collection, filtered and sorted by Id
     *
     * @property filteredApps
     * @type {Object[]}
     */
    filteredApps: (0, _object.computed)('apps.[]', {
      get() {
        const apps = this.apps || [];
        const filteredApps = apps.filter(app => _appManagement.EXCLUDED_APP_IDS.indexOf(app.id) === -1);
        const sortedApps = filteredApps.sort((item, next) => item.id < next.id ? -1 : 1);
        const appsWithKeys = sortedApps.map(app => {
          const appKey = (0, _appUtils.getAppKeyById)(app.id);
          // eslint-disable-next-line
          (0, _object.set)(app, 'appKey', appKey);
          return app;
        });
        return appsWithKeys;
      }
    }).readOnly(),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'rightSidenavKey', RIGHT_SIDENAV);
    },
    generateAppToken: (0, _emberConcurrency.task)(function* (appId) {
      const currentOrganization = this.currentUser && this.currentUser.currentOrganization && this.currentUser.currentOrganization.id;
      const response = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${currentOrganization}/apps/${appId}/generate-token`, {
        method: 'post'
      });
      const app = yield response.json();
      this.store.pushPayload('app', app);
    }),
    actions: {
      /**
       * Activates a certain app with the given plan.
       *
       * @param {Object} app, the app to be subscribed to
       * @param {Object} plan the desired plan
       */
      addApp(app, plan) {
        const currentOrganization = this.currentUser && this.currentUser.currentOrganization && this.currentUser.currentOrganization.id;
        return this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${currentOrganization}/apps`, {
          method: 'post'
        }, {
          app_id: app.id,
          plan_name: plan.id
        }).then(response => {
          return response.json().then(json => {
            return response.ok ? json : (0, _rsvp.reject)({
              status: response.status,
              message: json.message
            });
          });
        }).then(response => {
          if (response && response.app) {
            // reload all apps to make sure added services are displayed as well
            return this.store.findAll('app').then(() => {
              const intl = this.intl;
              this.uiToast.showToast({
                type: 'success',
                title: intl.t('global.toasts.success.changes.title'),
                description: intl.t('global.toasts.success.changes.description'),
                duration: this.toastDuration
              });

              // Remove the current added app from addableApps
              const newAddableApps = this.addableApps.filter(_app => _app.id !== app.id);
              (0, _object.set)(this, 'addableApps', newAddableApps);
            });
          }
          return (0, _rsvp.reject)();
        }).catch(error => {
          const intl = this.intl;
          const uiDialog = this.uiDialog;

          // ask the user if he wanted to upgrade the app
          if (error.message === 'already_added') {
            uiDialog.showConfirm(intl.t('routes.apps.dialogs.error.title'), intl.t('routes.apps.dialogs.addAppDialog.errorDialog.alreadyAdded'), () => this.send('openUpgradePlanDialog', app), intl.t('routes.apps.dialogs.addAppDialog.errorDialog.alreadyAddedAction'));
          } else {
            uiDialog.showConfirm(intl.t('routes.apps.dialogs.error.title'), intl.t('routes.apps.dialogs.error.errorMessage'));
          }
        }).finally(() => (0, _object.set)(this, 'isAppListDialog', false));
      },
      /**
       * Opens the detail view of a given app
       *
       * @param {Object} app, the detail app
       */
      toggleDetail(app) {
        if (app && app.id) {
          (0, _object.set)(this, 'currentApp', app);
          this.uiState.getState(RIGHT_SIDENAV).open();
        } else {
          this.uiState.getState(RIGHT_SIDENAV).close();
        }
      },
      toggleModules(app) {
        if (app !== null && app !== void 0 && app.id) {
          (0, _object.set)(this, 'currentApp', app);
        }
        (0, _object.set)(this, 'isModulesDialogOpen', true);
      },
      copyToClipboard(text) {
        var _navigator;
        (_navigator = navigator) === null || _navigator === void 0 || (_navigator = _navigator.clipboard) === null || _navigator === void 0 || _navigator.writeText(text);
        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('global.toasts.copySuccess')
        });
      }
    }
  });
});