define("additive-account/routes/instances/instance/corporate-design", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "@additive-apps/auth/mixins/access-guard"], function (_exports, _route, _object, _service, _accessGuard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_accessGuard.default, {
    intl: (0, _service.inject)(),
    routeIfUnauthorized: 'instances.instance',
    setupController(controller) {
      this._super(...arguments);
      const intl = this.intl;
      (0, _object.set)(controller, 'routableTabs', [{
        name: intl.t('routes.corporateDesign.tabs.colors'),
        path: 'instances.instance.corporate-design.index'
      }, {
        name: intl.t('routes.corporateDesign.tabs.logo'),
        path: 'instances.instance.corporate-design.logo'
      }, {
        name: intl.t('routes.corporateDesign.tabs.typography'),
        path: 'instances.instance.corporate-design.typography'
      }]);
    }
  });
});