define("additive-account/components/user-role-form", ["exports", "@ember/component", "@ember/object", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: ['w-100'],
    hasAppRoles: _object.computed.equal('user.role', 'member'),
    /**
     * load available apps
     */
    loadAvailableApps: (0, _emberConcurrency.task)(function* () {
      const apps = yield this.store.findAll('app');
      (0, _object.set)(this, 'apps', apps);
    }).on('didInsertElement')
  });
});