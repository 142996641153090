define("additive-account/mirage/serializers/setting-salutation", ["exports", "additive-account/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    keyForModel() {
      return 'salutations';
    },
    keyForCollection() {
      return 'salutations';
    }
  });
});