define("additive-account/templates/components/model-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiModal
    @size="md"
    @subtitle={{this.subtitle}}
    @title={{this.title}}
    @onClose={{action "onClose"}}
    as |modal|
  >
    <modal.header />
    <modal.content as |content|>
      <content.body>
        <div class="flex flex-column">
          {{yield (hash input=(component "ui-input" class="w-100")) this.changeset}}
        </div>
      </content.body>
    </modal.content>
    <modal.footer>
      <UiButton
        @label={{t "global.actions.save"}}
        @onClick={{action "onConfirm" this.changeset}}
        data-test-profile-view-confirm
      />
    </modal.footer>
  </UiModal>
  
  <UiDiscardChanges
    @isOpen={{this.isDiscardChangesDialog}}
    @onDiscard={{action "confirmDiscard"}}
    @onClose={{fn (mut this.isDiscardChangesDialog)}}
  />
  */
  {
    "id": "hvrqBR22",
    "block": "[[[8,[39,0],null,[[\"@size\",\"@subtitle\",\"@title\",\"@onClose\"],[\"md\",[30,0,[\"subtitle\"]],[30,0,[\"title\"]],[28,[37,1],[[30,0],\"onClose\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,3],null,[[\"input\"],[[50,\"ui-input\",0,null,[[\"class\"],[\"w-100\"]]]]]],[30,0,[\"changeset\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,\"data-test-profile-view-confirm\",\"\"]],[[\"@label\",\"@onClick\"],[[28,[37,6],[\"global.actions.save\"],null],[28,[37,1],[[30,0],\"onConfirm\",[30,0,[\"changeset\"]]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@isOpen\",\"@onDiscard\",\"@onClose\"],[[30,0,[\"isDiscardChangesDialog\"]],[28,[37,1],[[30,0],\"confirmDiscard\"],null],[28,[37,8],[[28,[37,9],[[30,0,[\"isDiscardChangesDialog\"]]],null]],null]]],null]],[\"modal\",\"content\",\"&default\"],false,[\"ui-modal\",\"action\",\"yield\",\"hash\",\"component\",\"ui-button\",\"t\",\"ui-discard-changes\",\"fn\",\"mut\"]]",
    "moduleName": "additive-account/templates/components/model-form.hbs",
    "isStrictMode": false
  });
});