define("additive-account/utils/parse-url", ["exports", "@additive-apps/ui/utils/parse-url"], function (_exports, _parseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _parseUrl.default;
    }
  });
});