define("additive-account/validators/valid-color", ["exports", "ember-changeset-validations/utils/validation-errors"], function (_exports, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateColor;
  function validateColor() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, value) => {
      // return error if value is 'inherit' or 'transparent'
      if (value === 'inherit' || value === 'transparent') {
        return (0, _validationErrors.default)(key, {
          type: 'invalid',
          value,
          context: options
        });
      }

      // create image element with black color
      let image = document.createElement('img');
      image.style.color = 'rgb(0, 0, 0)';

      // set input as color of the image element
      image.style.color = value;

      // if the color of the image element is not black anymore the input is valid
      if (image.style.color !== 'rgb(0, 0, 0)') {
        return true;
      }

      // input rgb(0,0,0) would not be validated as valid input
      // therefore make a second check starting from a white color
      image.style.color = 'rgb(255, 255, 255)';
      image.style.color = value;
      if (image.style.color !== 'rgb(255, 255, 255)') {
        return true;
      } else {
        return (0, _validationErrors.default)(key, {
          type: 'invalid',
          value,
          context: options
        });
      }
    };
  }
});