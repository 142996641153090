define("additive-account/services/ui-filter", ["exports", "@additive-apps/ui/services/ui-filter"], function (_exports, _uiFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiFilter.default;
    }
  });
});