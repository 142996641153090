define("additive-account/controllers/instances/instance/corporate-design/typography", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "ember-concurrency", "rsvp", "@additive-apps/ui/utils/constants", "additive-account/utils/organization-utils"], function (_exports, _environment, _controller, _object, _computed, _service, _emberConcurrency, _rsvp, _constants, _organizationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * Current user is manager
     *
     * @property isManager
     * @type {Boolean}
     * @private
     */
    isManager: (0, _computed.alias)('currentUser.isManager'),
    /**
     * the fetched colors of the organization
     *
     * @property colors
     * @type {Object}
     * @default undefined
     */
    colors: undefined,
    /**
     * the fetched typography of the organization
     *
     * @property typography
     * @type {Object}
     * @default undefined
     */
    typography: undefined,
    /**
     * the fetched fonts of the organization
     *
     * @property fonts
     * @type {Object}
     * @default undefined
     */
    fonts: undefined,
    fetchCD: (0, _emberConcurrency.task)(function* () {
      const corporateDesign = yield _organizationUtils.fetchCorporateDesign.task.perform(this);
      (0, _object.set)(this, 'colors', corporateDesign.colors);
      (0, _object.set)(this, 'typography', corporateDesign.typography);
      (0, _object.set)(this, 'fonts', corporateDesign.fonts);
    }),
    updateProperty: (0, _emberConcurrency.task)(function* (changeset, property) {
      if (!changeset) {
        this.uiDialog.showError();
        return;
      }
      try {
        let tasks = [];
        yield changeset.validate();
        if (changeset.get('isValid')) {
          yield changeset.save();
          const updateTask = this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/corporate-design/${property}`, {
            method: 'PUT'
          }, {
            [property]: this[property]
          });
          tasks.push(updateTask);
          tasks.push((0, _emberConcurrency.timeout)(_constants.REQUEST_DEBOUNCE_MS));
          const [response] = yield (0, _emberConcurrency.all)(tasks);
          if (!response || !response.ok) {
            this.uiDialog.showError();
            return (0, _rsvp.resolve)();
          }
          const {
            intl
          } = this;
          this.uiToast.showToast({
            type: 'success',
            title: intl.t('global.toasts.success.changes.title'),
            description: intl.t('global.toasts.success.changes.description'),
            duration: 2000
          });
        }
      } catch (error) {
        throw new Error(`[organization-corporate-design] Could not update color ${error}`);
      }
    }),
    actions: {
      updateFonts(fonts) {
        (0, _object.set)(this, 'fonts', fonts);
        const corporateDesign = this.store.peekRecord('corporate-design', this.organizationId);
        if (corporateDesign) {
          corporateDesign.set('fonts', fonts);
        }
      }
    }
  });
});