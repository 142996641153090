define("additive-account/mixins/global-user", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object/computed", "@ember/object", "additive-account/utils/constants"], function (_exports, _mixin, _service, _computed, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-new-mixins*/
  var _default = _exports.default = _mixin.default.create({
    currentUser: (0, _service.inject)(),
    uiState: (0, _service.inject)(),
    /**
     * The global user
     *
     * @property globalUserState
     * @type {Object}
     */
    globalUser: (0, _computed.alias)('globalUserState.user'),
    /**
     * The global user state
     *
     * @property globalUserState
     * @type {Object}
     */
    globalUserState: (0, _object.computed)({
      get() {
        const globalUserState = this.uiState.getState(_constants.GLOBAL_USER_STATE_KEY);
        return globalUserState;
      }
    }),
    /**
     * Determines if the global user is a partner-user of the current organization
     *
     * @property isOrgPartner
     * @type {Boolean}
     */
    isOrgPartner: (0, _object.computed)('currentUser.currentOrganization.partnerSlug', 'globalUser.partnerSlug', {
      get() {
        var _this$currentUser;
        /**
         * On direct calling an instance route as first page, global user is not set
         * TODO: Find out why computed property `globalUser` can't be used directly
         * instead of accessing it through uiState
         */
        const globalUserState = this.uiState.getState(_constants.GLOBAL_USER_STATE_KEY);
        const globalUser = globalUserState === null || globalUserState === void 0 ? void 0 : globalUserState.user;
        const organization = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.currentOrganization;
        if (!globalUser || !organization) {
          return false;
        }
        const globalUserPartnerslug = globalUser.partnerSlug;
        const organizationSlug = organization.partnerSlug;
        return globalUserPartnerslug && organizationSlug && globalUserPartnerslug === organizationSlug;
      }
    })
  });
});