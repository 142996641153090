define("additive-account/routes/instances/instance/settings", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "ember-concurrency", "@additive-apps/ui/constants"], function (_exports, _route, _service, _object, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    _setRouteTabs: (0, _emberConcurrency.task)(function* (controller) {
      const tabs = this._getTabs();
      try {
        const intl = this.intl;
        yield this.currentUser.loadUser();
        const apps = yield this.store.query('app', {
          accessible: 1
        });
        const crm = apps.find(app => app.id && parseInt(app.id) === _constants.APP_ID_CRM);
        if (crm) {
          tabs.push({
            name: intl.t('routes.settings.tabs.roomCounts'),
            path: 'instances.instance.settings.room-counts'
          });
        }
        (0, _object.set)(controller, 'routableTabs', tabs);
      } catch (e) {
        (0, _object.set)(controller, 'routableTabs', tabs);
      }
    }),
    setupController(controller) {
      this._super(...arguments);
      this._setRouteTabs.perform(controller);
    },
    resetController(controller) {
      (0, _object.set)(controller, 'routableTabs', this._getTabs());
    },
    _getTabs() {
      const intl = this.intl;
      return [{
        name: intl.t('routes.settings.tabs.communication'),
        path: 'instances.instance.settings.communication'
      }, {
        name: intl.t('routes.settings.tabs.legals'),
        path: 'instances.instance.settings.legals'
      }, {
        name: intl.t('routes.settings.tabs.integrations'),
        path: 'instances.instance.settings.integrations'
      }, {
        name: intl.t('routes.settings.tabs.closingPeriods'),
        path: 'instances.instance.settings.closing-periods'
      }];
    }
  });
});