define("additive-account/utils/organization-utils", ["exports", "additive-account/config/environment", "ember-concurrency", "@ember/object", "@additive-apps/ui/utils/constants"], function (_exports, _environment, _emberConcurrency, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateOrganization = _exports.sluggifyWebsite = _exports.fetchSettings = _exports.fetchCorporateDesign = void 0;
  /**
   * Fetches corporate design if not already provided in store
   * @type {Task}
   * @returns {Object} corporate design
   * @function fetchCorporateDesign
   */
  const fetchCorporateDesign = _exports.fetchCorporateDesign = {};
  (0, _object.defineProperty)(fetchCorporateDesign, 'task', (0, _emberConcurrency.task)(function* (self) {
    if (!self || !self.authenticatedFetch) {
      throw new Error(`[organization-corporate-design] missing service authenticatedFetch`);
    }
    if (!self.store) {
      throw new Error(`[organization-corporate-design] missing service store`);
    }
    if (!self.organizationId) {
      throw new Error(`[organization-corporate-design] missing organization id`);
    }
    try {
      const adapter = self.store.adapterFor('corporate-design');
      const url = adapter.buildURL('corporate-design', self.organizationId);
      const response = yield self.authenticatedFetch.fetch(url);
      const data = yield response.json();
      if (!data || !data.corporateDesign) throw new Error();
      (0, _object.set)(data, 'corporateDesign.id', self.organizationId);
      yield self.store.pushPayload('corporateDesign', data);
      return self.store.peekRecord('corporateDesign', self.organizationId);
    } catch (error) {
      throw new Error(`[organization-corporate-design] fetch corporate design error ${error}`);
    }
  }));

  /**
   * Fetches settings if not already provided in store
   * @type {Task}
   * @returns {Object} settings
   * @function fetchSettings
   */
  const fetchSettings = _exports.fetchSettings = {};
  (0, _object.defineProperty)(fetchSettings, 'task', (0, _emberConcurrency.task)(function* (self) {
    if (!self || !self.authenticatedFetch) {
      throw new Error(`[organization-settings] missing service authenticatedFetch`);
    }
    if (!self.store) {
      throw new Error(`[organization-settings] missing service store`);
    }
    if (!self.organizationId) {
      throw new Error(`[organization-settings] missing organization id`);
    }
    try {
      let settings = yield self.store.peekRecord('setting', self.organizationId);
      if (!settings) {
        const response = yield self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${self.organizationId}/settings`);
        const data = yield response.json();
        if (data && data.settings) {
          (0, _object.set)(data, 'settings.id', self.organizationId);
          yield self.store.pushPayload('setting', data);
          settings = yield self.store.peekRecord('setting', self.organizationId);
        }
      } else {
        yield (0, _emberConcurrency.timeout)(_constants.REQUEST_DEBOUNCE_MS);
      }
      return settings;
    } catch (error) {
      throw new Error(`[organization-settings] fetch settings error ${error}`);
    }
  }).restartable());

  /**
   * Validates an organizations slug async
   * @returns {Integer} status
   * @method validateOrganization
   */
  const validateOrganization = _exports.validateOrganization = {};
  (0, _object.defineProperty)(validateOrganization, 'slugUniqueness', (0, _emberConcurrency.task)(function* (value, self) {
    const response = yield self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/validate?slug=${value}`);
    const {
      ok
    } = response;
    if (ok) {
      return {
        ok
      };
    }
    const {
      errors: {
        slug: [message]
      }
    } = yield response.json();
    return {
      ok,
      message
    };
  }).restartable());

  /**
   * It transforms a website-url to a valid organization-slug
   *
   * @param {String} website, the website to be sluggified
   * @returns {String} slug, the valid slug
   * @method sluggifyWebsite
   */
  const sluggifyWebsite = website => {
    let result = website || '';
    result = result.replace(/^\s+|\s+$/g, ''); // trim
    result = result.replace(/(?:https?:\/\/)?(www\.?)?/g, ''); // protocol
    result = result.replace(/(^\w+:|^)\/\//, '');
    result = result.toLowerCase();
    result = result.replace(/\s+/g, '-'); // collapse whitespace and replace by -
    result = result.replace(/\.+/g, '-'); // replace dots
    // eslint-disable-next-line
    result = result.replace(/[^a-z0-9\-]/g, ''); // remove invalid chars

    return result;
  };
  _exports.sluggifyWebsite = sluggifyWebsite;
});