define("additive-account/routes/instances/instance/email-templates/list/detail", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    store: (0, _service.inject)(),
    model(params) {
      const model = this.store.peekRecord('email-template', params.email_template_id);
      return model || this.store.findRecord('email-template', params.email_template_id);
    }
  });
});