define("additive-account/routes/instances/instance/settings/legals", ["exports", "@ember/routing/route", "@ember/object", "@ember/object/computed", "@ember/service", "additive-account/utils/organization-utils"], function (_exports, _route, _object, _computed, _service, _organizationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    store: (0, _service.inject)(),
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    setupController(controller) {
      this._super(...arguments);
      _organizationUtils.fetchSettings.task.perform(this).then(settings => {
        (0, _object.set)(controller, '_settings', settings);
        controller.fetchLegals && controller.fetchLegals.perform();
      });
    }
  });
});