define("additive-account/routes/instances/me/inbox/message", ["exports", "additive-account/config/environment", "@ember/routing/route", "@ember/service"], function (_exports, _environment, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    model(params) {
      return this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/notifications/${params.message_id}`).then(response => response.json()).then(message => {
        return message.notification;
      });
    }
  });
});