define("additive-account/routes/logout", ["exports", "@ember/routing/route", "@ember/service", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _service, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_uiFullWidthView.default, {
    router: (0, _service.inject)(),
    session: (0, _service.inject)(),
    beforeModel() {
      this._super(...arguments);

      /* If not logged-in transition to application to start the normal auth-flow */
      if (this.session.isAuthenticated === false) {
        this.router.transitionTo('application');
        return;
      }
      this.session.invalidate();
    }
  });
});