define("additive-account/models/closing-time", ["exports", "date-fns/format", "@ember/object", "@ember-data/model", "@ember/service"], function (_exports, _format, _object, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    uiLocale: (0, _service.inject)(),
    from: (0, _model.attr)('string'),
    until: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    title: (0, _object.computed)('from', 'until', 'uiLocale.{dateFnsLocale,defaultDateFormat}', {
      get() {
        if (this.from && this.until) {
          const from = (0, _format.default)(new Date(this.from), this.uiLocale.defaultDateFormat, {
            locale: this.uiLocale.dateFnsLocale
          });
          const until = (0, _format.default)(new Date(this.until), this.uiLocale.defaultDateFormat, {
            locale: this.uiLocale.dateFnsLocale
          });
          return `${from} - ${until}`;
        }
        return '';
      }
    })
  });
});