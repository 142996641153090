define("additive-account/utils/app-management", ["exports", "additive-account/config/environment", "ember-concurrency", "rsvp", "@ember/object"], function (_exports, _environment, _emberConcurrency, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBuyableApps = _exports.getBoughtApps = _exports.getApps = _exports.EXCLUDED_APP_IDS = void 0;
  // list of apps that should be excluded from the list
  const EXCLUDED_APP_IDS = _exports.EXCLUDED_APP_IDS = [];

  /**
   * Fetches all apps and plans a user can subscribe to
   *
   * @method getBoughtApps
   * @returns {Object[]}
   */
  const getBoughtApps = _exports.getBoughtApps = {};
  (0, _object.defineProperty)(getBoughtApps, 'task', (0, _emberConcurrency.task)(function* (self, slug) {
    const request = self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/apps?bought=1&organization=${slug}`);
    return yield request.then(response => response.json()).then(data => {
      if (data && data.errors) {
        return (0, _rsvp.reject)(data.errors);
      } else {
        return (0, _rsvp.resolve)(data);
      }
    });
  }).drop());

  /**
   * Fetches all apps and plans a user can purchase
   * and excludes 'HIDDEN'-Apps
   *
   * @method getBuyableApps
   * @returns {Object[]}
   */
  const getBuyableApps = _exports.getBuyableApps = {};
  (0, _object.defineProperty)(getBuyableApps, 'task', (0, _emberConcurrency.task)(function* (self, slug) {
    const request = self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/apps?buyable=1&organization=${slug}`);
    const response = yield request;
    const data = yield response.json();
    const apps = data && data.apps || [];
    const filteredApps = apps.filter(app => EXCLUDED_APP_IDS.indexOf(`${app.id}`) === -1);
    const sortedApps = filteredApps.sort((item, next) => item.id < next.id ? -1 : 1);
    return (0, _rsvp.resolve)(sortedApps);
  }).drop());

  /**
   * Fetches all apps of an organization
   *
   * @method getApps
   * @returns {Object[]}
   */
  const getApps = _exports.getApps = {};
  (0, _object.defineProperty)(getApps, 'task', (0, _emberConcurrency.task)(function* (self) {
    let apps = self.store.peekAll('app');
    if (apps.length < 1) {
      apps = yield self.store.findAll('app');
    }
    return apps;
  }).drop());
});