define("additive-account/components/app-role-selection", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // voucher and guest-intranet
  const CONTENT_DEPENDEND_APPS = [2, 8];
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The chooseable roles
     *
     * @property appRoles
     * @type {Object[]}
     */
    appRoles: null,
    /**
     * The users property name for app-roles
     *
     * @property appRoles
     * @type {string}
     * @default 'appRoles'
     */
    appRolesProperty: null,
    /**
     * the apps the current organization owns and to
     * whom a user can be assigned to.
     *
     * @property availableApps
     * @type {Object[]}
     */
    availableApps: undefined,
    /**
     * the current selected user
     * @property user
     * @type {Object}
     */
    user: null,
    /**
     * the current selected role
     * @property role
     * @type {string}
     */
    role: null,
    /**
     * the roles that are assignable
     *
     * @property globalUserRoles
     * @type {Object[]}
     */
    globalUserRoles: undefined,
    /**
     * the role to be assigned in the content when a content-dependent app's rights
     * are modified
     *
     * @private _contentRole
     * @type {Object}
     */
    _contentRole: undefined,
    /**
     * temporary saved roles while the dialog to modify a content-dependent app's rigths is shown
     *
     * @private _newRoles
     * @type {Object}
     */
    _newRoles: undefined,
    /**
     * Extends the global roles by none, which indicates that
     * a user has no rights on a specific app.
     */
    userAppRoles: (0, _object.computed)('globalUserRoles', {
      get() {
        let roles = this.globalUserRoles || [];
        // Remove role "custom"
        roles = roles.filter(role => role.value !== 'custom');
        roles.push({
          value: 'none'
        });
        return roles;
      }
    }),
    /**
     *  Commits the changes to the user roles
     *
     * @param {*} upgradeContent, whether the content app's rights should be modified
     * @returns
     */
    setAppRoles(upgradeContent) {
      const updatedRoles = this._newRoles || [];
      const user = this.user;
      let newRoles;
      if (upgradeContent) {
        // remove old content role
        newRoles = updatedRoles.filter(el => el.appId !== 4);
        const contentRole = this._contentRole;
        newRoles.push(contentRole);
      } else {
        newRoles = updatedRoles;
      }
      user.set(this.appRolesProperty, newRoles);
      (0, _object.set)(this, 'isUpdatingUserRoles', true);
      return user.save().then(() => {
        const intl = this.intl;
        this.uiToast.showToast({
          type: 'success',
          title: intl.t('global.toasts.success.changes.title'),
          description: intl.t('global.toasts.success.changes.description'),
          duration: 2000
        });
      }).finally(() => {
        (0, _object.set)(this, 'isRoleDialog', false);
        (0, _object.set)(this, 'isUpdatingUserRoles', false);
        (0, _object.set)(this, 'isContentWarningDialog', false);
      });
    },
    actions: {
      /**
       *  sets the new roles of the user when they are modified
       *
       *  if the rights of an app that is dependent on the content app is upgraded a confirm dialog
       *  is shown to give the user the possibility to upgrade the rights for the content as well
       *
       *  if the content app's rights are downgraded and another dependend app has higher rights
       *  a warning is displayed
       *
       * @param {*} id
       * @param {*} role
       * @returns
       */
      addAppRole(id, role) {
        const appId = Number.parseInt(id);
        // find app role
        const appRoles = this.user && this.user.get(this.appRolesProperty);

        // keep role changes immutable
        const newRoles = appRoles ? appRoles.filter(el => el.appId !== appId) : [];

        // only existing roles get serialized
        if (role !== 'none') {
          newRoles.push({
            appId,
            role
          });

          // if voucher or guest-intranet show a dialog to upgrade content as well
          if (CONTENT_DEPENDEND_APPS.includes(appId)) {
            const availableRoles = this.userAppRoles.map(role => role.value);

            // the role of the user in the content app
            const contentRole = newRoles.find(el => el.appId === 4);

            // whether the role in the content app is lower than the role that should be activated,
            // if the user has no role return true
            const isContentRoleLower = contentRole ? availableRoles.indexOf(role) < availableRoles.indexOf(contentRole.role) : true;
            if (isContentRoleLower) {
              this.setProperties({
                _newRoles: newRoles,
                _contentRole: {
                  appId: 4,
                  role: role
                },
                isRoleDialog: false,
                isContentWarningDialog: true
              });
              return;
            }
          }
        }
        (0, _object.set)(this, '_newRoles', newRoles);

        // if content warn that downgrade can influence other apps
        if (appId === 4) {
          const contentDependendAppsRoles = newRoles.filter(el => CONTENT_DEPENDEND_APPS.includes(el.appId));
          if (contentDependendAppsRoles.length > 0) {
            const availableRoles = this.userAppRoles.map(role => role.value);

            // if there is a content dependend app with a higher user role than the new role for the content
            // display a warning message
            for (let element of contentDependendAppsRoles) {
              if (availableRoles.indexOf(role) > availableRoles.indexOf(element.role)) {
                (0, _object.set)(this, 'isRoleDialog', false);
                const intl = this.intl;
                return this.uiDialog.showConfirm(intl.t('routes.users.dialogs.contentWarning.title'), intl.t('routes.users.dialogs.contentWarning.description', {
                  appName: intl.t(`appNames.${element.appId}.name`)
                }), () => this.setAppRoles(false), intl.t('routes.users.dialogs.contentWarning.action'));
              }
            }
          }
        }
        return this.setAppRoles(false);
      }
    }
  });
});