define("additive-account/mirage/factories/template", ["exports", "ember-cli-mirage", "faker", "additive-account/mirage/helpers/configurator", "additive-account/mirage/helpers/medium"], function (_exports, _emberCliMirage, _faker, _configurator, _medium) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.lorem.word(),
    config: () => {
      return {
        footerWidth: _faker.default.random.arrayElement(['full', 'typeArea']),
        logoPosition: _faker.default.random.arrayElement(['inside', 'outside']),
        imagePosition: _faker.default.random.arrayElement(['top', 'bottom']),
        imagePlaceholder: (0, _medium.getRandomMedium)()
      };
    },
    styles: () => {
      return {
        globals: {
          align: _faker.default.random.arrayElement(['left', 'center', 'right']),
          boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
          borderRadius: '2px'
        },
        colors: (0, _configurator.getDefaultColors)(),
        typography: (0, _configurator.getDefaultTypography)()
      };
    }
  });
});