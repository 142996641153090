define("additive-account/controllers/instances/instance/email-templates/list/detail", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    currentUser: (0, _service.inject)(),
    router: (0, _service.inject)(),
    organization: (0, _computed.alias)('currentUser.currentOrganization'),
    actions: {
      back() {
        this.router.transitionTo('instances.instance.email-templates.list');
      },
      edit() {
        this.router.transitionTo('instances.instance.email-templates.edit', this.model.id);
      }
    }
  });
});