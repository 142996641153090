define("additive-account/controllers/instances/instance/users/list", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "additive-account/utils/user-management", "@ember/utils", "additive-account/mixins/global-user"], function (_exports, _environment, _controller, _object, _service, _rsvp, _userManagement, _utils, _globalUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // the mixin is required in order to be used from the route
  var _default = _exports.default = _controller.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    /**
     * Fetch users task
     *
     * @property task
     * @type {Task}
     */
    getUsers: _userManagement.getUsers,
    /**
     * all users
     *
     * @property users
     * @type {Object[]}
     */
    users: undefined,
    /**
     *  Filter deleted users
     *
     * @property userCollection
     * @type {Object[]} userCollection
     */
    userCollection: _object.computed.filterBy('users', 'isDeleted', false),
    /**
     * whether the users have loaded
     *
     * @property isLoading
     * @type {Boolean}
     */
    isLoading: _object.computed.alias('getUsers.task.isRunning'),
    isEmpty: (0, _object.computed)('isLoading', 'userCollection.[]', {
      get() {
        return !this.isLoading && (0, _utils.isEmpty)(this.userCollection);
      }
    }),
    /**
     * Returns the last segment of the current URL.
     * @computed _currentRouteIndex
     * @return {String} The last segment of the current URL.
     */
    _currentRouteIndex: (0, _object.computed)('router.currentURL', {
      get() {
        return this.router.currentURL.split('/').pop();
      }
    }),
    /**
     * Cancels all ongoining deleteUser tasks and restores the user object.
     *
     * @function cancelDeleting
     * @param {Object} user
     */
    cancelDeleting(user) {
      _userManagement.deleteUser.task.cancelAll();
      user.rollbackAttributes();
    },
    actions: {
      openDeleteDialog(user) {
        const callback = () => {
          _userManagement.deleteUser.task.perform(user, this);
        };
        this.uiDialog.showConfirm(this.intl.t('routes.users.dialogs.delete.title'), this.intl.t('routes.users.dialogs.delete.description', {
          userName: user.fullName
        }), callback, this.intl.t('global.actions.confirm'));
      },
      /**
       * Deactivates an active user
       *
       * @function deactivateUser
       * @async
       * @param {String} userId
       */
      deactivateUser(userId) {
        this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations${this.router.currentURL}/${userId}/deactivate`, {
          method: 'post'
        }).then(response => {
          if (response.status === 204) {
            const user = this.store.peekRecord('user', userId);
            user.set('active', false);
            const intl = this.intl;
            this.uiToast.showToast({
              type: 'success',
              title: intl.t('global.toasts.success.changes.title'),
              description: intl.t('global.toasts.success.changes.description'),
              duration: 2000
            });
          } else {
            this.uiDialog.showError();
          }
        });
      },
      /**
       * Activates a decativated user
       *
       * @function activateUser
       * @async
       * @param {String} userId
       */
      activateUser(userId) {
        this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations${this.router.currentURL}/${userId}/activate`, {
          method: 'post'
        }).then(response => {
          if (response.status === 204) {
            const user = this.store.peekRecord('user', userId);
            user.set('active', true);
            const intl = this.intl;
            this.uiToast.showToast({
              type: 'success',
              title: intl.t('global.toasts.success.changes.title'),
              description: intl.t('global.toasts.success.changes.description'),
              duration: 2000
            });
          } else {
            this.uiDialog.showError();
          }
        });
      },
      resendInvitation(userId) {
        const invitationEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations${this.router.currentURL}`;
        _userManagement.resendInvitation.task.perform(invitationEndpoint, userId, this).then(status => {
          if (status === 204) {
            // todo show success toast
            return (0, _rsvp.resolve)();
          } else if (status >= 400) {
            // show toast
          }
        }).catch(() => {
          // Show error toast
        });
      }
    }
  });
});