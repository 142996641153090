define("additive-account/mirage/helpers/model", ["exports", "additive-account/mirage/helpers/server"], function (_exports, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findOrFail = void 0;
  /**
   * Tries to find the given id.
   * If not found, it will return a 404 Response
   *
   * @param {*} model The mirage model example: `schema.myModelsName`
   * @param {*} id The model id
   */
  const findOrFail = (model, id) => {
    const foundModel = model.find(id);
    return foundModel || (0, _server.notFoundResponse)();
  };
  _exports.findOrFail = findOrFail;
});