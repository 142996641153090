define("additive-account/helpers/aa-app-plan", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Displays either the default plan translation or the actual app-plan
   * @class aa-app-plan
   * @module account-helpers
   */
  var _default = _exports.default = _helper.default.extend({
    intl: (0, _service.inject)(),
    compute(_ref) {
      let [app] = _ref;
      const {
        id,
        planName
      } = app;
      const intl = this.intl;
      return planName ? intl.t(`appDetails.${id}.plans.${planName}.title`) : intl.t(`appDetails.plans.defaultPlan`);
    }
  });
});