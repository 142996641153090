define("additive-account/templates/instances/instance/email-templates/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AaEmailTemplateConfigurator @resource={{this.model}} />
  */
  {
    "id": "6GZxfIlA",
    "block": "[[[8,[39,0],null,[[\"@resource\"],[[30,0,[\"model\"]]]],null]],[],false,[\"aa-email-template-configurator\"]]",
    "moduleName": "additive-account/templates/instances/instance/email-templates/edit.hbs",
    "isStrictMode": false
  });
});