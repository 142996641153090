define("additive-account/components/ui-app", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ```hbs
   * {{ui-app}}
   * ```
   *
   * @class ui-app
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['ui-app', 'flex', 'pv2', 'relative'],
    /**
     * if the component is set to read-only mode the onRemove
     * action will be hidden.
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default false
     */
    isReadOnly: false,
    onClick() {},
    onRemove() {}
  });
});