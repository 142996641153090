define("additive-account/utils/constants-fonts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serifFontsFallbacks = _exports.serifFonts = _exports.sansSerifFontsFallbacks = _exports.sansSerifFonts = _exports.default = _exports.FONT_TYPES = void 0;
  const sansSerifFonts = _exports.sansSerifFonts = [{
    name: 'Helvetica',
    value: 'Helvetica',
    serif: false
  }, {
    name: 'Lucida Grande',
    value: 'Lucida Grande',
    serif: false
  }, {
    name: 'Geneva',
    value: 'Geneva',
    serif: false
  }, {
    name: 'Arial',
    value: 'Arial',
    serif: false
  }, {
    name: 'Verdana',
    value: 'Verdana',
    serif: false
  }, {
    name: 'Lucida Sans',
    value: 'Lucida Sans',
    serif: false
  }, {
    name: 'Lucida',
    value: 'Lucida',
    serif: false
  }, {
    name: 'Trebuchet MS',
    value: 'Trebuchet MS',
    serif: false
  }, {
    name: 'Tahoma',
    value: 'Tahoma',
    serif: false
  }, {
    name: 'Century Gothic',
    value: 'Century Gothic',
    serif: false
  }, {
    name: 'Arial Black',
    value: 'Arial Black',
    serif: false
  }];
  const serifFonts = _exports.serifFonts = [{
    name: 'Palatino',
    value: 'Palatino',
    serif: true
  }, {
    name: 'Palatino Linotype',
    value: 'Palatino Linotype',
    serif: true
  }, {
    name: 'Georgia',
    value: 'Georgia',
    serif: true
  }, {
    name: 'Times New Roman',
    value: 'Times New Roman',
    serif: true
  }, {
    name: 'Times',
    value: 'Times',
    serif: true
  }, {
    name: 'Courier',
    value: 'Courier',
    serif: true
  }];
  const sansSerifFontsFallbacks = _exports.sansSerifFontsFallbacks = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
  const serifFontsFallbacks = _exports.serifFontsFallbacks = '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif';
  const FONT_TYPES = _exports.FONT_TYPES = ['normal', 'light', 'bold'];
  var _default = _exports.default = {
    sansSerifFonts
  };
});