define("additive-account/controllers/instances/instance/users/list/user", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "additive-account/mixins/global-user", "additive-account/utils/constants", "additive-account/helpers/can-update-role"], function (_exports, _controller, _object, _service, _emberConcurrency, _globalUser, _constants, _canUpdateRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // the mixin is required in order to be consumed inside the template
  var _default = _exports.default = _controller.default.extend(_globalUser.default, {
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    user: null,
    activePartnerTab: undefined,
    currentOrganization: _object.computed.alias('currentUser.currentOrganization'),
    isLoadingUser: _object.computed.alias('getUser.isRunning'),
    /**
     * @computed {Array} globalUserRoles
     */
    globalUserRoles: (0, _object.computed)('currentUser.organizationUser.role', {
      get() {
        const role = this.currentUser && this.currentUser.organizationUser && this.currentUser.organizationUser.role;
        return _constants.USER_INVITE_MAP[role];
      }
    }),
    /**
     * Disables setting of user roles if the user has role manager or below
     * and wants to update the role of a user with same or higher role
     *
     * @computed isUpdateDisabled
     */
    isUpdateDisabled: (0, _object.computed)('currentUser.organizationUser.role', 'user.role', {
      get() {
        const currentUserRole = this.currentUser && this.currentUser.organizationUser && this.currentUser.organizationUser.role;
        if (currentUserRole === _constants.ADMIN_ROLE) {
          return false;
        }
        const userRole = this.user && this.user.role;
        return userRole && !(0, _canUpdateRole.canUpdateRole)([currentUserRole, userRole]);
      }
    }),
    /**
     * Disables setting of user roles if the user has role manager or below
     * and wants to update the partner-role of a user with same or higher role
     *
     * @computed isUpdateDisabled
     */
    isPartnerUpdateDisabled: (0, _object.computed)('currentUser.organizationUser.role', 'user.partnerRole', {
      get() {
        var _this$currentUser, _this$user;
        const currentUserRole = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.organizationUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.role;
        if (currentUserRole === _constants.ADMIN_ROLE) {
          return false;
        }
        const userRole = (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.partnerRole;
        return userRole && !(0, _canUpdateRole.canUpdateRole)([currentUserRole, userRole]);
      }
    }),
    partnerTabs: (0, _object.computed)('currentOrganization.{id,isPartner,name}', {
      get() {
        if (!this.currentOrganization || !this.currentOrganization.isPartner && this.currentOrganization.id !== 'additive-eu') {
          return [];
        }
        const tabs = [{
          title: this.currentOrganization && this.currentOrganization.name,
          name: 'partner-org'
        }, {
          title: this.intl.t('routes.users.dialogs.roleDialog.partnerSubOrgsTabName'),
          name: 'sub-org'
        }];

        // eslint-disable-next-line ember/no-side-effects
        (0, _object.set)(this, 'activePartnerTab', tabs[0]);
        return tabs;
      }
    }),
    getUser: (0, _emberConcurrency.task)(function* (userId) {
      const store = this.store;
      let user = store.peekRecord('user', userId);
      if (!user) {
        user = yield store.findRecord('user', userId);
      }
      (0, _object.set)(this, 'user', user);
    }).restartable(),
    actions: {
      backAction() {
        this.transitionToRoute('instances.instance.users');
      }
    }
  });
});