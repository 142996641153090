define("additive-account/routes/auth/login/reset", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils", "additive-account/utils/api-query-errors", "@ember/service"], function (_exports, _route, _object, _utils, _apiQueryErrors, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    intl: (0, _service.inject)(),
    queryParams: {
      errors: {
        as: 'reset-error'
      }
    },
    beforeModel(transition) {
      var _transition$to;
      this._super(...arguments);
      if (!(transition !== null && transition !== void 0 && (_transition$to = transition.to) !== null && _transition$to !== void 0 && (_transition$to = _transition$to.queryParams) !== null && _transition$to !== void 0 && _transition$to.token)) {
        this.transitionTo('auth.login');
      }
    },
    setupController(controller) {
      this._super(...arguments);
      const queryErrors = controller.errors;
      if (queryErrors && queryErrors.includes('token:reset.failed')) {
        (0, _object.set)(controller, 'isTokenError', true);
        return;
      }
      const changeset = controller.changeset;
      const token = controller.token;
      if ((0, _utils.isPresent)(token)) {
        changeset.set('token', token);
      } else {
        controller.transitionToRoute('auth.login');
      }
      (0, _object.set)(controller, 'isResetAllowed', true);
      (0, _object.set)(controller, 'isSubmitting', false);
      const apiErrors = (0, _apiQueryErrors.combineErrors)(queryErrors);
      const intl = this.intl;

      // push api-related errors to the controllers changeset
      if ((0, _utils.isPresent)(apiErrors)) {
        (0, _object.set)(controller, 'apiErrors', apiErrors);
        Object.keys(apiErrors).forEach(key => {
          // Get last error, 'cause we only show one error
          const apiErrorsByKey = apiErrors[key];
          const error = apiErrorsByKey[apiErrorsByKey.length - 1];
          changeset.pushErrors(key, intl.t(`global.errors.apiQueryErrors.${error}`));
          (0, _object.set)(controller, 'isFormTouched', true);
        });
      }
    }
  });
});