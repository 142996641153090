define("additive-account/validators/valid-css-size", ["exports", "ember-changeset-validations/utils/validation-errors"], function (_exports, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateCssSize;
  /**
   * Checks if a given value is a valid CSS size
   * The validation is limited to the units em, rem, px
   *
   * @export
   * @param {Object} options providing the context for the error messages
   * @returns {Any} true if valid, error message if invalid
   */
  function validateCssSize() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, value) => {
      const allowedUnits = ['rem', 'em', 'px'];

      // 0 does not need a unit
      if (value === '0') {
        return true;
      }

      // find first of the allowed units and remove it
      const containsUnit = allowedUnits.some(unit => {
        if (value.indexOf(unit) > 0 && value.indexOf(unit) + unit.length === value.length) {
          value = value.slice(0, value.indexOf(unit));
          return true;
        }
      });

      // if remaining string is numeric, the input is valid
      if (containsUnit && value.match(/^\d+(\.\d+)?$/)) {
        return true;
      } else {
        return (0, _validationErrors.default)(key, {
          type: 'invalid',
          value,
          context: options
        });
      }
    };
  }
});