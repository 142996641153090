define("additive-account/templates/instances/instance/users/partner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiList
    @class="list-bottom-padding"
    @isLoading={{this.isLoading}}
    @items={{this.users}}
    data-test-user-list
    as |list item|
  >
    <list.count @totalCount={{this.users.length}} />
  
    <list.item
      @state={{item.accountState}}
      @title={{if (eq item.fullName " ") "-" item.fullName}}
      @description={{item.email}}
      @isDisabled={{true}}
      data-test-user-item
      as |controls|
    >
      <controls.thumbnail>
        {{#if item.imagePath}}
          <img class="br-100" src={{item.imagePath}} alt="Benutzerbild" width="44" height="44" />
        {{else}}
          <div class="flex items-center justify-center w-100 h-100 br-100 bg-secondary--20">
            {{svg-jar "placeholder-user" class="secondary--40"}}
          </div>
        {{/if}}
      </controls.thumbnail>
    </list.item>
  </UiList>
  
  {{outlet}}
  */
  {
    "id": "mX+X0yB/",
    "block": "[[[8,[39,0],[[24,\"data-test-user-list\",\"\"]],[[\"@class\",\"@isLoading\",\"@items\"],[\"list-bottom-padding\",[30,0,[\"isLoading\"]],[30,0,[\"users\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"count\"]],null,[[\"@totalCount\"],[[30,0,[\"users\",\"length\"]]]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"item\"]],[[24,\"data-test-user-item\",\"\"]],[[\"@state\",\"@title\",\"@description\",\"@isDisabled\"],[[30,2,[\"accountState\"]],[52,[28,[37,2],[[30,2,[\"fullName\"]],\" \"],null],\"-\",[30,2,[\"fullName\"]]],[30,2,[\"email\"]],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"thumbnail\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"imagePath\"]],[[[1,\"        \"],[10,\"img\"],[14,0,\"br-100\"],[15,\"src\",[30,2,[\"imagePath\"]]],[14,\"alt\",\"Benutzerbild\"],[14,\"width\",\"44\"],[14,\"height\",\"44\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"flex items-center justify-center w-100 h-100 br-100 bg-secondary--20\"],[12],[1,\"\\n          \"],[1,[28,[35,3],[\"placeholder-user\"],[[\"class\"],[\"secondary--40\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[46,[28,[37,5],null,null],null,null,null]],[\"list\",\"item\",\"controls\"],false,[\"ui-list\",\"if\",\"eq\",\"svg-jar\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-account/templates/instances/instance/users/partner.hbs",
    "isStrictMode": false
  });
});