define("additive-account/controllers/instances/instance/users", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "@ember/object/computed", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations", "additive-account/mixins/global-user", "additive-account/utils/constants"], function (_exports, _environment, _controller, _object, _service, _rsvp, _computed, _emberChangeset, _validators, _emberChangesetValidations, _globalUser, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inviteValidation = intl => {
    const presenceOptions = {
      presence: true,
      message: intl.t('global.errors.apiQueryErrors.validation.required')
    };
    const emailOptions = {
      type: 'email',
      allowBlank: true,
      message: intl.t('global.errors.apiQueryErrors.validation.email')
    };
    const roleInclusionOptions = {
      list: ['member', 'manager', 'admin', 'viewer', 'custom'],
      message: intl.t('global.errors.apiQueryErrors.validation.roleInclusion')
    };
    return {
      email: [(0, _validators.validateFormat)(emailOptions), (0, _validators.validatePresence)(presenceOptions)],
      role: [(0, _validators.validateInclusion)(roleInclusionOptions)]
    };
  };
  var _default = _exports.default = _controller.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    /**
     * @computed {Array} globalUserRoles
     */
    globalUserRoles: (0, _object.computed)('currentUser.organizationUser.role', {
      get() {
        const role = this.currentUser && this.currentUser.organizationUser && this.currentUser.organizationUser.role;
        return _constants.USER_INVITE_MAP[role];
      }
    }),
    /**
     * Sets the duration of the toast that is displayed when deleting a user.
     *
     * @property toastDuration
     * @type {Integer}
     */
    toastDuration: 2500,
    /**
     * The name of the suborg
     *
     * @property organizationName
     * @type {String}
     */
    organizationName: (0, _computed.alias)('currentUser.currentOrganization.name'),
    /**
     * The current route is the users list and not the partner users list
     *
     * @property partnerName
     * @type {String}
     */
    isUsersRoute: (0, _computed.equal)('router.currentRouteName', 'instances.instance.users.list.index'),
    /**
     * The current route is the users list and not the partner users list
     *
     * @property partnerName
     * @type {String}
     */
    hasInvitationButton: (0, _computed.and)('isUsersRoute', 'currentUser.isManager'),
    /**
     * Depending on the user account and the organisation the user sees different tabs.
     *
     * @property routes
     * @type {Object[]}
     */
    routes: (0, _object.computed)('currentUser.{currentOrganization,isManager}', 'globalUser.{isAdditive,partnerSlug}', {
      get() {
        var _this$currentUser, _this$currentUser2, _this$globalUser, _this$globalUser2;
        const organization = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.currentOrganization;
        const isManager = (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isManager;
        const userPartnerSlug = (_this$globalUser = this.globalUser) === null || _this$globalUser === void 0 ? void 0 : _this$globalUser.partnerSlug;
        const isAdditive = (_this$globalUser2 = this.globalUser) === null || _this$globalUser2 === void 0 ? void 0 : _this$globalUser2.isAdditive;
        if (organization.id === 'additive-eu') {
          // dont show tabs inside the partner org
          return false;
        }
        const isPartnerUser = (organization === null || organization === void 0 ? void 0 : organization.partnerSlug) && userPartnerSlug && organization.partnerSlug === userPartnerSlug;

        // if child org of partner
        if (isAdditive || isPartnerUser || isManager) {
          const tabs = [{
            name: organization.name,
            path: 'instances.instance.users.list.index'
          }, {
            name: organization.partnerName || organization.partnerSlug || 'ADDITIVE',
            path: 'instances.instance.users.partner'
          }];
          return tabs;
        }
        return false;
      }
    }),
    languageOptions: (0, _object.computed)('uiLocale.locale', {
      get() {
        return _constants.APP_LANGUAGES.map(lang => ({
          name: this.intl.t(`languageSelect.${lang}`),
          value: lang
        }));
      }
    }),
    actions: {
      openInvitationDialog() {
        const intl = this.intl;
        const validation = inviteValidation(intl);
        const changeset = new _emberChangeset.default({
          email: '',
          role: '',
          appRoles: [],
          language: this.uiLocale.locale
        }, (0, _emberChangesetValidations.default)(validation), validation);
        (0, _object.set)(this, 'invitationChangeset', changeset);
        (0, _object.set)(this, 'isInvitationDialog', true);
      },
      /**
       * An invitation requires a valid email and a role the invited user
       * will have once he accepts the invitation.
       *
       * in case we invite the user as 'member', after successfull invitation
       * the current logged in user gets redirected to the users app-role mgmt.
       */
      inviteAction() {
        // use a changeset for the pwd confirmation
        if (this.isInvitingUser) {
          return;
        }
        (0, _object.set)(this, 'isInvitingUser', true);
        const changeset = this.invitationChangeset;
        return changeset.validate().then(() => {
          (0, _object.set)(this, 'isInvitationTouched', true);
          if (changeset.get('isValid')) {
            const invitationEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations${this.router.currentURL}/invite`;
            return this.authenticatedFetch.fetch(invitationEndpoint, {
              method: 'POST'
            }, {
              email: changeset.get('email'),
              role: changeset.get('role'),
              language: changeset.get('language')
            }).then(response => response.json()).then(data => {
              if (data && data.user) {
                // push new user object inside store
                this.store.pushPayload('user', data);
                if (changeset.get('role') === 'member' && data.user.id) {
                  this.transitionToRoute('instances.instance.users.list.user', data.user.id);
                  // cleanup changeset
                  (0, _object.set)(this, 'invitationChangeset', null);
                }

                /* TODO: Show confirmation toast or similar */
                (0, _object.set)(this, 'isInvitationDialog', false);
                return (0, _rsvp.resolve)(data);
              } else {
                return (0, _rsvp.reject)(data);
              }
            })
            /**
             * availbale errors:
             *  - cant_invite_yourself: if the current logged in user trys to invite himself
             *  - already_invited: if the invited user has a pending invitation
             */.catch(error => {
              if (error) {
                if (error.message === 'cant_invite_yourself') {
                  changeset.addError('email', this.intl.t('global.errors.selfInvitation'));
                } else {
                  changeset.addError('email', this.intl.t('global.errors.emailAlreadyExist'));
                }
              }
            }).finally(() => {
              (0, _object.set)(this, 'isInvitingUser', false);
              return (0, _rsvp.resolve)();
            });
          } else {
            (0, _object.set)(this, 'isInvitingUser', false);
            return (0, _rsvp.resolve)();
          }
        });
      }
    }
  });
});