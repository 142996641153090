define("additive-account/components/aa-corporate-design/fonts", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Wrapper component that displays the uploaded fonts
   *
   *  @class aa-corporate-design/fonts
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * the fonts object of the cd
     *
     * @property fonts
     * @type {Object}
     * @default null
     */
    fonts: null,
    isReadOnly: false,
    /**
     * whether the secondary font upload item should be displayed
     *
     * @computed showSecondary
     * @type {Boolean}
     */
    showSecondary: _object.computed.or('fonts.{primary,secondary}'),
    willDestroyElement() {
      this._super(...arguments);

      // clear the loaded font-faces from the dom
      if (document && document.fonts) {
        document.fonts.clear();
      }
    },
    updateFonts() {},
    actions: {
      /**
       * updates the font object
       *
       * @action updateProperties
       */
      updateProperties(value, key) {
        const fonts = Object.assign({}, this.fonts, {
          [key]: value
        });
        this.updateFonts(fonts);
      }
    }
  });
});