define("additive-account/components/upgrade-plan", ["exports", "@ember/component", "@ember/object", "@ember/service", "@additive-apps/ui/constants", "@additive-apps/utils/utils/plans", "additive-account/utils/constants"], function (_exports, _component, _object, _service, _constants, _plans, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    /**
     * all available plans to upgrade to
     *
     * @property plans
     * @type {Object[]}
     * @default undefined
     */
    plans: undefined,
    /**
     * the app that should be upgraded
     *
     * @property app
     * @type {Object}
     * @default null
     */
    app: null,
    /**
     *  the index of the selected tab
     *
     * @property activeTabIndex
     * @type {Integer}
     * @default 0
     */
    activeTabIndex: 0,
    /**
     *  the animation for the transition between the tabs
     *
     * @property animation
     * @type {String}
     * @default null
     */
    animation: null,
    /**
     *  the active Tab
     *
     * @property activeTab
     * @type {Object}
     * @default undefined
     */
    activeTab: (0, _object.computed)('cards.[]', 'activeTabIndex', {
      get() {
        return this.cards[this.activeTabIndex];
      }
    }),
    /**
     *  all tabs to be displayed
     *
     * @property cards
     * @type {Object[]}
     * @default undefined
     */
    cards: (0, _object.computed)('app', 'plans.[]', 'activeTab', 'activePlan', 'activeTabIndex', {
      get() {
        const intl = this.intl;
        const plans = this.plans;
        const app = this.app;
        const activePlan = this.activePlan;

        // filter app-plans keeping only yearly plans for partner-, only monthly plans for saas-customers
        // and only plans that are better than the current one. Partner customers with an active "standard"
        // plan are given the option to upgrade to professional and enterprise yearly plans.
        // It also should not be possible to upgrade from the MC starter plan to the MC professional plan.
        const upgradablePlans = plans.filter(plan => {
          if (parseInt(app.id) === _constants.APP_ID_MARKETING_CLOUD) {
            const activePlanName = activePlan.id.split('_')[0];
            const planName = plan.id.split('_')[0];
            if (activePlanName === _plans.PLAN_STARTER && planName === _plans.PLAN_PROFESSIONAL) {
              return false;
            }
          }
          if (activePlan.id === 'standard') {
            return _constants2.PARTNER_PLANS.slice(2).includes(plan.id);
          } else {
            return _constants2.PARTNER_PLANS.includes(plan.id) && _constants2.PARTNER_PLANS.indexOf(plan.id) > _constants2.PARTNER_PLANS.indexOf(activePlan.id);
          }
        });
        return upgradablePlans.map((plan, index) => {
          // extract features from intl and split them into an array
          const featuresString = intl.t(`appDetails.${app.id}.plans.${plan.id}.features`, {
            htmlSafe: true
          });
          // we dont need the first "feature" because it just contains the price
          let features;
          if (featuresString.string) {
            features = featuresString.string.split('<br>');
          } else {
            features = [];
          }
          return {
            name: intl.t(`appDetails.${app.id}.plans.${plan.id}.title`),
            planId: plan.id,
            amount: plan.amount,
            features: features,
            isActive: index === this.activeTabIndex,
            interval: plan.interval
          };
        });
      }
    }),
    actions: {
      /**
       *  triggered when tabs are switched
       *
       * @function openTab
       * @param {*} index
       */
      openTab(index) {
        const tabIndex = this.activeTabIndex;
        if (tabIndex > index) {
          (0, _object.set)(this, 'animation', 'toRight');
        } else {
          (0, _object.set)(this, 'animation', 'toLeft');
        }
        (0, _object.set)(this, 'activeTabIndex', index);
      }
    }
  });
});