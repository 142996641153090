define("additive-account/mirage/helpers/configurator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDefaultTypography = _exports.getDefaultColors = void 0;
  const getDefaultColors = () => {
    return {
      main: {
        sync: true,
        color: '#3D3D3D',
        contrastColor: '#FFFFFF',
        syncedColor: '#575757',
        syncedContrastColor: '#FFFFFF'
      },
      accent: {
        sync: true,
        color: '#967E6E',
        contrastColor: '#ffffff',
        syncedColor: '#967E6E',
        syncedContrastColor: '#ffffff'
      },
      ambient: {
        sync: true,
        color: '#FFFFFF',
        contrastColor: '#3D3D3D',
        syncedColor: '#FFFFFF',
        syncedContrastColor: '#575757'
      },
      background: {
        color: '#FFFFFF',
        contrastColor: '#3D3D3D',
        sync: true,
        syncColorKey: 'main'
      },
      footer: {
        color: '#FFFFFF',
        contrastColor: '#3D3D3D',
        sync: true,
        syncColorKey: 'ambient'
      }
    };
  };
  _exports.getDefaultColors = getDefaultColors;
  const getDefaultTypography = () => {
    return {
      h1: {
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        fontSize: '40px',
        lineHeight: '48px',
        color: 'main',
        serif: false,
        sync: true,
        syncedFontFamily: 'Helvetica',
        syncedFontSize: '40px',
        syncedLineHeight: '48px',
        syncedColor: 'accent',
        syncedSerif: false
      },
      h2: {
        fontFamily: 'Verdana',
        fontSize: '32px',
        lineHeight: '40px',
        color: 'main',
        serif: false,
        sync: true,
        syncedFontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        syncedFontSize: '32px',
        syncedLineHeight: '40px',
        syncedColor: 'main',
        syncedSerif: false
      },
      p: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '18px',
        lineHeight: '24px',
        color: 'accent',
        serif: true,
        sync: true,
        syncedFontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        syncedFontSize: '18px',
        syncedLineHeight: '24px',
        syncedColor: 'main',
        syncedSerif: false
      },
      label: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: 'main',
        serif: true,
        sync: true,
        syncedFontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        syncedFontSize: '14px',
        syncedLineHeight: '20px',
        syncedColor: 'main',
        syncedSerif: false
      },
      button: {
        fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
        fontSize: '14px',
        lineHeight: '20px',
        color: 'accent',
        serif: true,
        sync: true,
        syncedFontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        syncedFontSize: '14px',
        syncedLineHeight: '20px',
        syncedColor: 'accent',
        syncedSerif: false
      }
    };
  };
  _exports.getDefaultTypography = getDefaultTypography;
});