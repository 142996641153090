define("additive-account/templates/instances/me/inbox/message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiModal
    @size="md"
    @modalClassNames="h-auto"
    @title={{if this.model this.model.data.title (t "routes.messages.message.errorTitle")}}
    @onClose={{action "back"}}
    as |dialog|
  >
    <dialog.header />
    <dialog.content as |content|>
      <content.body>
        {{#each this.messageContent as |line index|}}
          <div class="{{if index 'pt2'}} {{if line.isAction 'flex'}}">
            {{#if line.isAction}}
              <UiButton
                @class="db"
                @href={{get line.text (dec line.text.length)}}
                @target="_blank"
                @label={{get line.text "0"}}
                data-test-action-anchor
              />
            {{else}}
              {{line.text}}
            {{/if}}
          </div>
        {{/each}}
      </content.body>
    </dialog.content>
  </UiModal>
  */
  {
    "id": "MIKPPrDt",
    "block": "[[[8,[39,0],null,[[\"@size\",\"@modalClassNames\",\"@title\",\"@onClose\"],[\"md\",\"h-auto\",[52,[30,0,[\"model\"]],[30,0,[\"model\",\"data\",\"title\"]],[28,[37,2],[\"routes.messages.message.errorTitle\"],null]],[28,[37,3],[[30,0],\"back\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"messageContent\"]]],null]],null],null,[[[1,\"        \"],[10,0],[15,0,[29,[[52,[30,4],\"pt2\"],\" \",[52,[30,3,[\"isAction\"]],\"flex\"]]]],[12],[1,\"\\n\"],[41,[30,3,[\"isAction\"]],[[[1,\"            \"],[8,[39,6],[[24,\"data-test-action-anchor\",\"\"]],[[\"@class\",\"@href\",\"@target\",\"@label\"],[\"db\",[28,[37,7],[[30,3,[\"text\"]],[28,[37,8],[[30,3,[\"text\",\"length\"]]],null]],null],\"_blank\",[28,[37,7],[[30,3,[\"text\"]],\"0\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,3,[\"text\"]]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"dialog\",\"content\",\"line\",\"index\"],false,[\"ui-modal\",\"if\",\"t\",\"action\",\"each\",\"-track-array\",\"ui-button\",\"get\",\"dec\"]]",
    "moduleName": "additive-account/templates/instances/me/inbox/message.hbs",
    "isStrictMode": false
  });
});