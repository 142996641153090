define("additive-account/controllers/instances/instance/settings/communication", ["exports", "additive-account/config/environment", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency", "@additive-apps/auth/utils/roles-map", "additive-account/utils/constants", "additive-account/utils/organization-utils"], function (_exports, _environment, _controller, _tracking, _object, _service, _emberConcurrency, _rolesMap, _constants, _organizationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstancesInstanceSettingsCommunication = _exports.default = (_dec = (0, _emberConcurrency.task)(function* (languages) {
    try {
      const response = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/settings`, {
        method: 'PUT'
      }, {
        contentLanguages: languages
      });
      if (response && response.ok) {
        const json = yield response.json();
        this.settings.contentLanguages = json.settings.contentLanguages;
        const intl = this.intl;
        this.uiToast.showToast({
          type: 'success',
          title: intl.t('global.toasts.success.savedChanges'),
          duration: 2000
        });
      } else {
        // TODO: Update when new modal-service exists
        this.uiDialog.showError();
      }
    } catch (error) {
      throw new Error(`[organization-languages] Could not update languages ${error}`);
    }
  }).drop(), _dec2 = (0, _emberConcurrency.task)(function* () {
    this.isSalutationSettingsError = false;
    const url = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}`;
    try {
      const requestOptions = {
        headers: Object.assign({}, _constants.ACCEPT_HEADER, {
          'Accept-Language': this.language
        }),
        method: 'GET'
      };
      const response = yield this.authenticatedFetch.fetch(`${url}/settings/salutations`, requestOptions);
      if (!response || !response.ok) {
        throw new Error('[SETTINGS] salutation settings', response);
      }
      const json = yield response.json();
      if (!json || !json.salutations) {
        throw new Error('[SETTINGS] salutation settings');
      }
      const salutationSettings = Object.assign({}, this.salutationSettings);
      salutationSettings[this.language] = json.salutations;
      this.salutationSettings = salutationSettings;
    } catch (error) {
      this.isSalutationSettingsError = true;
    }
  }), _class = class InstancesInstanceSettingsCommunication extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      /**
       * the fetched settings of the organization
       *
       * @property settings
       * @type {Object}
       * @default undefined
       */
      _initializerDefineProperty(this, "settings", _descriptor7, this);
      /**
       * footer container id for the fab button
       *
       * @property footerContainerId
       * @type {String}
       * @default 'settings-fab-button-container'
       */
      _defineProperty(this, "footerContainerId", 'settings-fab-button-container');
      /**
       * the task to fetch the settings
       *
       * @property fetchSettingsTask
       * @type {Task}
       * @default undefined
       */
      _defineProperty(this, "fetchSettingsTask", _organizationUtils.fetchSettings.task);
      /**
       * the fetched salutation settings
       *
       * @property salutationSettings
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "salutationSettings", _descriptor8, this);
      /**
       * the language that should be deleted
       *
       * @property toDeleteLanguage
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "toDeleteLanguage", _descriptor9, this);
      /**
       * whether the delete language dialog is open
       *
       * @property isDeleteDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDeleteDialog", _descriptor10, this);
      /**
       * whether the add language dialog is open
       *
       * @property isAddLangDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isAddLangDialog", _descriptor11, this);
      /**
       * whether an error occurred while fetching the salutation settings
       *
       * @property salutationSettings
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSalutationSettingsError", _descriptor12, this);
      _initializerDefineProperty(this, "fabButtonContainer", _descriptor13, this);
      /**
       *  performs a request with an updated language array to remove or add languages
       *
       * @function updateLanguagesTask
       */
      _initializerDefineProperty(this, "updateLanguagesTask", _descriptor14, this);
      /**
       * fetches the salutation settings
       *
       * @function fetchSalutationSettings
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchSalutationSettings", _descriptor15, this);
    }
    /**
     * the current language of the app
     *
     * @computed language
     * @type {String}
     */
    get language() {
      var _this$uiAppSettings;
      return (_this$uiAppSettings = this.uiAppSettings) === null || _this$uiAppSettings === void 0 ? void 0 : _this$uiAppSettings.currentLocale;
    }
    get isAdmin() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.isAdmin;
    }
    get organizationId() {
      var _this$currentUser2;
      return (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.currentOrganization) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.id;
    }
    get contentLanguages() {
      var _this$settings;
      return (_this$settings = this.settings) === null || _this$settings === void 0 ? void 0 : _this$settings.contentLanguages;
    }
    get defaultLanguage() {
      var _this$settings2;
      return (_this$settings2 = this.settings) === null || _this$settings2 === void 0 ? void 0 : _this$settings2.defaultLanguage;
    }
    get isDisabled() {
      var _this$currentUser3;
      return !(0, _rolesMap.hasAccess)('manager', (_this$currentUser3 = this.currentUser) === null || _this$currentUser3 === void 0 || (_this$currentUser3 = _this$currentUser3.user) === null || _this$currentUser3 === void 0 ? void 0 : _this$currentUser3.role);
    }

    /**
     * the languages that have been added to the content languages
     *
     * @property addedLanguages
     * @type {String[]}
     * @default undefined
     */
    get addedLanguages() {
      const languages = this.contentLanguages;
      if (languages) {
        return languages.filter(lang => lang !== this.defaultLanguage);
      }
      return [];
    }

    /**
     * languages that can still be added
     *
     * @property addableLanguages
     * @type {String[]}
     * @default undefined
     */
    get addableLanguages() {
      const contentLanguages = this.contentLanguages;
      if (contentLanguages) {
        return _constants.AVAILABLE_LANGUAGES.filter(lang => !contentLanguages.includes(lang));
      }
      return [];
    }
    async setup() {
      this.fetchSalutationSettings.perform();
      const settings = await this.fetchSettingsTask.perform(this);
      this.settings = settings;
      this.fabButtonContainer = document.getElementById(this.footerContainerId);
    }
    async addLanguage(lang) {
      const languages = this.contentLanguages;
      const updatedLanguages = languages.concat(lang);
      await this.updateLanguagesTask.perform(updatedLanguages);
      const addableLanguages = this.addableLanguages;
      // close dialog when no more languages can be added
      if (addableLanguages.length === 0) {
        this.isAddLangDialog = false;
      }
    }
    async removeLanguage(lang) {
      const languages = this.contentLanguages;
      const defaultLanguage = this.defaultLanguage;

      // dont remove default language
      if (languages.includes(lang) && defaultLanguage !== lang) {
        const updatedLanguages = languages.filter(language => language !== lang);
        await this.updateLanguagesTask.perform(updatedLanguages);
        this.toDeleteLanguage = null;
        this.isDeleteDialog = false;
      }
    }
    openDeleteConfirm(lang) {
      this.toDeleteLanguage = lang;
      this.isDeleteDialog = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "settings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "salutationSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "toDeleteLanguage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDeleteDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isAddLangDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isSalutationSettingsError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fabButtonContainer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "updateLanguagesTask", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "fetchSalutationSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addLanguage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeLanguage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDeleteConfirm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDeleteConfirm"), _class.prototype), _class);
});