define("additive-account/mirage/endpoints/audit-log-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.auditLogsRoute = void 0;
  const auditLogsRoute = _exports.auditLogsRoute = function auditLogsRoute(server) {
    server.get('/api/organizations/*/audit-logs', () => {
      return {
        labels: ['Wer', 'Was', 'Wann'],
        data: [[{
          format: 'custom',
          customKey: 'user',
          value: 'Julian Ratschiller',
          link: 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL3VzZXJzLzRmMWM1NzVkLTk0YWUtNDZjNC1hOTg3LWM0MDEwYzAyYWM4NS9hdmF0YXItNTEyeDUxMj90cz0xNjgxOTE2MzEz&ts=1681916313',
          userLink: 'https://account.additive-apps.eu/testhotel-post-com/users'
        }, {
          format: 'custom',
          customKey: 'change',
          value: 'Bewerbung aktualisiert',
          link: 'https://marketing-cloud.additive-apps.eu/testhotel-post-com/campaigns/ad/content-ads/4430',
          location: 'Kampagne > usp > ca-dach-de'
        }, {
          customKey: 'time',
          format: 'custom',
          value: '2023-09-25T11:14:36.127Z'
        }], [{
          format: 'custom',
          customKey: 'user',
          value: 'Clemens Frotschnig',
          link: 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL3VzZXJzL2IyZDdjM2NiLWE2ZTItNGUzZi1iNzc2LTY1MjRkMmJkN2IxNy9hdmF0YXItNTEyeDUxMj90cz0xNjgzMTg2NzUw&ts=1683186750',
          userLink: 'https://account.additive-apps.eu/testhotel-post-com/users'
        }, {
          format: 'custom',
          customKey: 'change',
          value: 'Bewerbung aktualisiert',
          link: 'https://marketing-cloud.additive-apps.eu/testhotel-post-com/campaigns/ad/content-ads/7598',
          location: 'Kampagne > content_reach_goal_17.8 > test'
        }, {
          customKey: 'time',
          format: 'custom',
          value: '2023-09-14T12:55:27.265Z'
        }], [{
          format: 'custom',
          customKey: 'user',
          value: null,
          link: null,
          userLink: 'https://account.additive-apps.eu/testhotel-post-com/users'
        }, {
          format: 'custom',
          customKey: 'change',
          value: 'Organisation aktualisiert',
          link: 'https://marketing-cloud.additive-apps.eu/testhotel-post-com/settings',
          location: 'Organisation > Testhotel Post > facebook-pixel'
        }, {
          customKey: 'time',
          format: 'custom',
          value: '2023-09-11T10:01:32.850Z'
        }]],
        meta: {
          currentPage: 1,
          from: 0,
          lastPage: 2,
          perPage: 40,
          to: 72,
          total: 72
        }
      };
    });
  };
});