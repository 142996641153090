define("additive-account/serializers/default", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * I was afraid to use application serializer.
   * Maybe sometimes we can add this as application serializer.
   * Unfortunatley we have to test everything when we do that, so using this is safer.
   */
  class DefaultSerializer extends _rest.default {
    serializeIntoHash(data, type, record, options) {
      Object.assign(data, this.serialize(record, options));
    }
  }
  _exports.default = DefaultSerializer;
});