define("additive-account/components/ui-tabs-header", ["exports", "@additive-apps/ui/components/ui-tabs-header"], function (_exports, _uiTabsHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiTabsHeader.default;
    }
  });
});