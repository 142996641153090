define("additive-account/components/simple-nav", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    currentIndex: 1,
    actions: {
      updateIndex(index) {
        (0, _object.set)(this, 'currentIndex', index);
      }
    }
  });
});