define("additive-account/utils/constants", ["exports", "@additive-apps/auth/utils/roles-map"], function (_exports, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOnboarding = _exports.USER_INVITE_MAP = _exports.SALUTATION_PLACEHOLDER = _exports.SAAS_PLANS = _exports.ROLE_UPDATE_MAP = _exports.PARTNER_PLANS = _exports.MANAGER_ROLE = _exports.IMAGE_MIME_TYPES = _exports.GLOBAL_USER_STATE_KEY = _exports.GLOBAL_USER_ROLES = _exports.FONT_FILE_ENDINGS = _exports.AVAILABLE_LANGUAGES = _exports.APP_LANGUAGES = _exports.ADMIN_ROLE = void 0;
  /**
   * Returns all app-onboardings
   *
   * @param {} intl
   */
  const getOnboarding = intl => {
    return {
      1: [{
        title: intl.t('onboarding.accountCreation.1.title'),
        description: intl.t('onboarding.accountCreation.1.description'),
        isFirstStep: true,
        isFloatingApps: true
      }, {
        title: intl.t('onboarding.accountCreation.2.title'),
        description: intl.t('onboarding.accountCreation.2.description'),
        illustration: 'devices-dark',
        theme: 'dark'
      }, {
        title: intl.t('onboarding.accountCreation.3.title'),
        description: intl.t('onboarding.accountCreation.3.description'),
        illustration: 'synergy-white',
        isLastStep: true
      }]
    };
  };
  _exports.getOnboarding = getOnboarding;
  const ADMIN_ROLE = _exports.ADMIN_ROLE = 'admin';
  const MANAGER_ROLE = _exports.MANAGER_ROLE = 'manager';
  const GLOBAL_USER_STATE_KEY = _exports.GLOBAL_USER_STATE_KEY = 'global-user';
  const GLOBAL_USER_ROLES = _exports.GLOBAL_USER_ROLES = [{
    value: 'admin'
  }, {
    value: 'manager'
  }, {
    value: 'member'
  }, {
    value: 'viewer'
  }, {
    value: 'custom'
  }];
  const USER_INVITE_MAP = _exports.USER_INVITE_MAP = {
    owner: [{
      value: 'admin'
    }, {
      value: 'manager'
    }, {
      value: 'member'
    }, {
      value: 'viewer'
    }, {
      value: 'custom'
    }],
    admin: [{
      value: 'admin'
    }, {
      value: 'manager'
    }, {
      value: 'member'
    }, {
      value: 'viewer'
    }, {
      value: 'custom'
    }],
    manager: [{
      value: 'manager'
    }, {
      value: 'member'
    }, {
      value: 'viewer'
    }, {
      value: 'custom'
    }]
  };
  const ROLE_UPDATE_MAP = _exports.ROLE_UPDATE_MAP = {
    owner: [_rolesMap.ROLE_ADMIN, _rolesMap.ROLE_MANAGER, _rolesMap.ROLE_MEMBER, _rolesMap.ROLE_VIEWER, _rolesMap.ROLE_CUSTOM],
    admin: [_rolesMap.ROLE_MANAGER, _rolesMap.ROLE_MEMBER, _rolesMap.ROLE_VIEWER, _rolesMap.ROLE_CUSTOM],
    manager: [_rolesMap.ROLE_MEMBER, _rolesMap.ROLE_VIEWER, _rolesMap.ROLE_CUSTOM],
    member: [],
    viewer: [],
    custom: []
  };
  const SAAS_PLANS = _exports.SAAS_PLANS = ['starter_monthly', 'professional_monthly', 'enterprise_monthly'];
  const PARTNER_PLANS = _exports.PARTNER_PLANS = ['standard', 'starter_yearly', 'professional_yearly', 'enterprise_yearly'];
  const AVAILABLE_LANGUAGES = _exports.AVAILABLE_LANGUAGES = ['de', 'it', 'en', 'fr', 'nl', 'pl'];
  const IMAGE_MIME_TYPES = _exports.IMAGE_MIME_TYPES = ['image/jpeg', 'image/png'];
  const FONT_FILE_ENDINGS = _exports.FONT_FILE_ENDINGS = ['woff', 'woff2', 'ttf', 'otf'];
  const APP_LANGUAGES = _exports.APP_LANGUAGES = ['de', 'en'];
  const SALUTATION_PLACEHOLDER = _exports.SALUTATION_PLACEHOLDER = {
    '{#NACHNAME#}': 'familyName',
    '{#VORNAME#}': 'givenName'
  };
});