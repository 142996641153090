define("additive-account/controllers/confirm", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/service", "fetch", "ember-concurrency"], function (_exports, _environment, _controller, _object, _service, _fetch, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Possible query-params:
   * @param email_verification_code: Signup verification token
   * @param app_id: The id of the app a user is interested in
   * @param plan_name: the plan a user is interested in, is used in conjunction with the app_id to
   *  preselect an app when accessing the create route.
   * @param token: inivite-token, in case a user gets invited we check if the provided token
   *  is valid, if yes and the user does not exist we redirect him to the signup-form, if he
   *  already exists we ask him to accept the invite
   */
  var _default = _exports.default = _controller.default.extend({
    intl: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    authenticatedFetch: (0, _service.inject)(),
    session: (0, _service.inject)(),
    queryParams: ['signup_email', 'email_verification_code', 'app_id', 'plan_name', 'token'],
    signup_email: null,
    app_id: null,
    plan_name: null,
    email_verification_code: null,
    token: null,
    // used to populate the view depending on given qPs
    viewContent: null,
    /**
     * Validate email_verification-code
     * While task is running we should display a message
     *
     * @method POST: /verification/<email_verification_code>
     * @param verificationCode: verification token
     * @returns {204}, token is valid
     * @returns {wrong_email_verification_code}, provided verification code is invalid
     * @return {user_already_verified}, the token has already been used
     */
    confirmUser: (0, _emberConcurrency.task)(function* (verificationCode) {
      const url = `${_environment.default.APP.apiBaseHost}/auth/verification/${verificationCode}`;
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      };
      const response = yield (0, _fetch.default)(url, options);

      /**
       * Possible error cases:
       * - the provided verification-code doesn't exist or is not valid
       * - the verification-code has already been used.
       *
       * in both cases we pretend the verification was succesfull and redirect to me.
       * We redirect to me, so we can force an api login-redirect
       */
      if (!response.ok) {
        // reset query-params, show "go to login" button
        this.transitionToRoute('instances.me');
      } else {
        // if the email has been verified successfully we redirect to create with query-param
        const app_id = this.app_id,
          plan_name = this.plan_name;
        this.transitionToRoute('instances.create', {
          queryParams: {
            app_id,
            plan_name
          }
        });
      }
    }).drop(),
    /**
     * Resend invitation-email
     *
     * For privacy reason the API returns a 204 response on successfull resend and if the
     * provided email does not exist.
     *
     * @method POST /verification/resend
     * @returns {204}, invite token has been resend
     * @returns {204}, provided email does not exist
     * @returns {user_already_verified}, the user has already been verified
     */
    resendConfirmationEmail: (0, _emberConcurrency.task)(function* () {
      const verificationEmail = this.emails;
      const url = `${_environment.default.APP.apiBaseHost}/auth/verification/resend`;
      const options = {
        method: 'POST',
        body: JSON.stringify({
          email: verificationEmail
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      };
      const intl = this.intl;
      const response = yield (0, _fetch.default)(url, options);
      if (!response.ok) {
        // user_already_verified
        (0, _object.set)(this, 'viewContent', {
          illustration: 'error-dark',
          title: intl.t('confirm.alreadyVerified.title'),
          description: intl.t('confirm.alreadyVerified.description'),
          buttonRoute: 'auth.login',
          actionLabel: intl.t('confirm.alreadyVerified.actionLabel')
        });
      } else {
        // a non existing email and a successful resend have the same response -> show success message
        (0, _object.set)(this, 'viewContent', {
          illustration: 'invite-white',
          title: intl.t('confirm.confirmationError.title'),
          description: intl.t('confirm.confirmationError.description'),
          buttonRoute: 'auth.login',
          actionLabel: intl.t('confirm.confirmationError.actionLabel')
        });
      }
    }).drop(),
    /**
     * Checks a given invite-token existence.
     *
     * @method POST /invites/<token>
     * @param token, the token to be verified
     */
    checkInvitationToken: (0, _emberConcurrency.task)(function* (token) {
      const url = `${_environment.default.APP.apiBaseHost}/api/invites/${token}`;
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      };
      const intl = this.intl;
      const response = yield (0, _fetch.default)(url, options);
      if (!response.ok) {
        // error (invitation token could not be found)
        // the message we show the user should not say that the token was invalid
        (0, _object.set)(this, 'viewContent', {
          illustration: 'error-dark',
          title: intl.t('confirm.inviteError.title'),
          description: intl.t('confirm.inviteError.description', {
            htmlSafe: true
          }),
          buttonRoute: 'auth.login',
          actionLabel: intl.t('confirm.inviteError.actionLabel')
        });
      } else {
        const {
          newUser,
          email,
          inviterFullName,
          organizationName,
          language
        } = yield response.json();
        if (this.session.isAuthenticated) {
          const url = this.uiPaths.pathsByRouteName('instances.me').api().url;
          const userResponse = yield this.authenticatedFetch.fetch(url);
          if (userResponse.ok) {
            const userJson = yield userResponse.json();
            if (userJson.user.email !== email) {
              (0, _object.set)(this, 'viewContent', {
                illustration: 'error-dark',
                title: intl.t('confirm.alreadyLoggedIn.title'),
                description: intl.t('confirm.alreadyLoggedIn.description'),
                actionLabel: intl.t('confirm.alreadyLoggedIn.actionLabel'),
                isPendingInvite: false,
                isWrongUser: true
              });
              return;
            }
          }
        }
        yield language && this.uiLocale.setLocale(language);
        if (newUser) {
          this.transitionToRoute('auth.signup', {
            queryParams: {
              token
            }
          });
        } else {
          // If the user is already logged in, ask if he wants to accept the invite and redirect to org
          // in case he is not logged in redirect to org, so the authorize call forces a login and redirects
          // the user with the provided token to the org detail.
          (0, _object.set)(this, 'viewContent', {
            illustration: 'invite-white',
            title: intl.t('confirm.pendingInvite.title'),
            description: intl.t('confirm.pendingInvite.description', {
              inviter: inviterFullName,
              organization: organizationName,
              htmlSafe: true
            }),
            actionLabel: intl.t('confirm.pendingInvite.actionLabel'),
            isPendingInvite: true
          });
        }
      }
    }),
    actions: {
      goToLogin() {
        this.transitionToRoute('auth.login');
      },
      acceptInvitation() {
        const token = this.token;
        this.transitionToRoute('instances.me', {
          queryParams: {
            token
          }
        });
      },
      resendConfirmationEmail() {
        return this.resendConfirmationEmail.perform();
      },
      logout() {
        this.session.invalidate();
      }
    }
  });
});