define("additive-account/controllers/instances/instance/settings/room-counts", ["exports", "@ember/controller", "@ember/object", "ember-concurrency", "@ember/service", "@ember/utils", "@ember/object/computed"], function (_exports, _controller, _object, _emberConcurrency, _service, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    /**
     * whether the loading placeholder is shown
     *
     * @computed showLoading
     */
    showLoading: (0, _computed.and)('_isLoading', 'isResponseEmpty'),
    /**
     * whether the roomcounts object is empty
     *
     * @computed isResponseEmpty
     */
    isResponseEmpty: (0, _computed.empty)('roomCounts'),
    /**
     * the current org-id
     *
     * @computed organizationId
     */
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * whether the fetch task is running
     *
     * @computed _isLoading
     */
    _isLoading: (0, _computed.alias)('fetchRoomCounts.isRunning'),
    _isManager: (0, _computed.alias)('currentUser.isManager'),
    _isMember: (0, _computed.not)('currentUser.isManager'),
    fabButtonContainer: (0, _object.computed)('_isLoading', {
      get() {
        return document.getElementById('settings-fab-button-container');
      }
    }),
    /**
     * the total room count
     *
     * @computed totalRoomCount
     */
    totalRoomCount: (0, _object.computed)('roomCounts.{active.[],future.[],past.[]}', {
      get() {
        if (this.roomCounts) {
          const {
            active,
            future,
            past
          } = this.roomCounts;
          return active.length + future.length + past.length;
        }
        return 0;
      }
    }),
    /**
     * whether all room-count sublists are empty
     *
     * @computed _isEmpty
     */
    _isEmpty: (0, _object.computed)('roomCounts.{active.[],future.[],past.[]}', '_isLoading', 'isError', {
      get() {
        if (this.roomCounts && !this._isLoading && !this.isError) {
          const {
            active,
            future,
            past
          } = this.roomCounts;
          return (0, _utils.isEmpty)(active) && (0, _utils.isEmpty)(future) && (0, _utils.isEmpty)(past);
        }
        return false;
      }
    }),
    /**
     * fetches all available room counts
     *
     * @function fetchRoomCounts
     * @type {Task}
     */
    fetchRoomCounts: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.set)(this, 'isError', false);
        const adapter = this.store.adapterFor('room-count');
        const url = adapter.buildURL('room-count');

        // room counts need to be fetched using fetch since it returns prefiltered arrays
        const response = yield this.authenticatedFetch.fetch(url);
        if (!response || !response.ok) throw new Error('[ROOM-SETTINGS] fetch error');
        const {
          roomCounts
        } = yield response.json();

        // push records into store in order to use model actions
        Object.keys(roomCounts).forEach(key => {
          roomCounts[key] = roomCounts[key].map(roomCount => {
            this.store.pushPayload({
              roomCount
            });
            return this.store.peekRecord('room-count', roomCount.id);
          });
        });
        (0, _object.set)(this, 'roomCounts', roomCounts);
      } catch (e) {
        (0, _object.set)(this, 'isError', true);
      }
    }),
    /**
     * deletes a given room count and reloads the list
     *
     * @function deleteRoomType
     * @type {Task}
     * @param {String} id
     */
    deleteRoomType: (0, _emberConcurrency.task)(function* (roomCount) {
      try {
        yield roomCount.destroyRecord();
        this.uiToast.showToast({
          title: this.intl.t('global.toasts.delete.success'),
          description: this.intl.t('global.toasts.success.changes.description'),
          type: 'success'
        });
        this.fetchRoomCounts.perform();
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('global.toasts.delete.error'),
          description: this.intl.t('global.toasts.error.description'),
          type: 'error'
        });
      }
    }),
    actions: {
      delete(roomCount) {
        const {
          intl
        } = this;
        this.uiDialog.showConfirm(intl.t('routes.settings.roomCounts.deleteDialog.title'), intl.t('routes.settings.roomCounts.deleteDialog.description'), () => this.deleteRoomType.perform(roomCount), intl.t('global.actions.delete'), true, true);
      },
      openDetail(roomCount) {
        (0, _object.setProperties)(this, {
          activeRoomCount: roomCount,
          isDetailDialog: true
        });
      }
    }
  });
});