define("additive-account/models/corporate-design", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    colors: (0, _model.attr)(),
    typography: (0, _model.attr)(),
    fonts: (0, _model.attr)(),
    logoUrl: (0, _model.attr)('string'),
    alternativeLogoUrl: (0, _model.attr)('string'),
    signetUrl: (0, _model.attr)('string')
  });
});