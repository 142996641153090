define("additive-account/controllers/instances/instance/corporate-design/index", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object/computed", "@ember/service", "ember-concurrency", "rsvp"], function (_exports, _environment, _controller, _computed, _service, _emberConcurrency, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * Current user is manager
     *
     * @property isManager
     * @type {Boolean}
     * @private
     */
    isManager: (0, _computed.alias)('currentUser.isManager'),
    saveChangeset: (0, _emberConcurrency.task)(function* (changeset) {
      try {
        yield changeset.validate();
        if (changeset.get('isValid')) {
          let tasks = [];
          yield changeset.save();
          const updateTask = this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/corporate-design/colors`, {
            method: 'PUT'
          }, {
            colors: this.colors
          });
          tasks.push(updateTask);
          tasks.push((0, _emberConcurrency.timeout)(250));
          const [response] = yield (0, _emberConcurrency.all)(tasks);
          if (!response || !response.ok) {
            this.uiDialog.showError();
            return (0, _rsvp.resolve)();
          }
          const {
            intl
          } = this;
          this.uiToast.showToast({
            type: 'success',
            title: intl.t('global.toasts.success.changes.title'),
            description: intl.t('global.toasts.success.changes.description'),
            duration: 2000
          });
        }
      } catch (error) {
        throw new Error(`[organization-corporate-design] Could not update color ${error}`);
      }
    }).drop()
  });
});