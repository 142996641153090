define("additive-account/validations/auth", ["exports", "ember-changeset-validations/validators", "additive-account/validators/specific-value"], function (_exports, _validators, _specificValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signupValidation = _exports.resetPasswordValidation = _exports.loginValidation = _exports.emailValidation = _exports.default = _exports.changePasswordValidation = void 0;
  const loginValidation = intl => {
    return {
      email: (0, _validators.validateFormat)({
        type: 'email',
        message: intl.t('global.errors.apiQueryErrors.validation.email')
      }),
      password: (0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.apiQueryErrors.validation.required')
      })
    };
  };
  _exports.loginValidation = loginValidation;
  const emailValidation = intl => {
    return {
      email: (0, _validators.validateFormat)({
        type: 'email',
        message: intl.t('global.errors.apiQueryErrors.validation.email')
      })
    };
  };
  _exports.emailValidation = emailValidation;
  const resetPasswordValidation = intl => {
    const emailOptions = {
      type: 'email',
      message: intl.t('global.errors.apiQueryErrors.validation.email')
    };
    const minLengthOptions = {
      min: 8,
      message: intl.t('global.errors.apiQueryErrors.validation.minLengthCount', {
        count: 8
      })
    };
    const passwordConfirmationMessage = intl.t('global.errors.apiQueryErrors.validation.passwordConfirmation');
    return {
      email: (0, _validators.validateFormat)(emailOptions),
      password: (0, _validators.validateLength)(minLengthOptions),
      passwordConfirmation: (0, _validators.validateConfirmation)({
        on: 'password',
        message: passwordConfirmationMessage
      }),
      token: (0, _validators.validatePresence)(true)
    };
  };
  _exports.resetPasswordValidation = resetPasswordValidation;
  const signupValidation = intl => {
    const presenceOptions = {
      presence: true,
      message: intl.t('global.errors.apiQueryErrors.validation.required')
    };
    const emailOptions = {
      type: 'email',
      message: intl.t('global.errors.apiQueryErrors.validation.email')
    };
    const minLengthOptions = {
      min: 8,
      message: intl.t('global.errors.apiQueryErrors.validation.minLengthCount', {
        count: 8
      })
    };
    const passwordConfirmationMessage = intl.t('global.errors.apiQueryErrors.validation.passwordConfirmation');
    return {
      email: (0, _validators.validateFormat)(emailOptions),
      givenName: (0, _validators.validatePresence)(presenceOptions),
      familyName: (0, _validators.validatePresence)(presenceOptions),
      password: (0, _validators.validateLength)(minLengthOptions),
      passwordConfirmation: (0, _validators.validateConfirmation)({
        on: 'password',
        message: passwordConfirmationMessage
      }),
      isPrivacyAccepted: (0, _specificValue.default)({
        shouldBe: true
      })
    };
  };
  _exports.signupValidation = signupValidation;
  const changePasswordValidation = intl => {
    const minLengthMessage = intl.t('global.errors.apiQueryErrors.validation.minLengthCount', {
      count: 8
    });
    const passwordConfirmationMessage = intl.t('global.errors.apiQueryErrors.validation.passwordConfirmation');
    return {
      password: (0, _validators.validateLength)({
        min: 8,
        message: minLengthMessage
      }),
      passwordConfirmation: (0, _validators.validateConfirmation)({
        on: 'password',
        message: passwordConfirmationMessage
      })
    };
  };
  _exports.changePasswordValidation = changePasswordValidation;
  var _default = _exports.default = loginValidation;
});