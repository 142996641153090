define("additive-account/adapters/application", ["exports", "additive-account/config/environment", "@ember/object", "@ember/service", "@ember/string", "@additive-apps/auth/adapters/application"], function (_exports, _environment, _object, _service, _string, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    uiAppSettings: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    host: `${_environment.default.APP.apiBaseHost}`,
    currentLocale: _object.computed.alias('currentUser.user.language'),
    headers: (0, _object.computed)('currentLocale', 'session.data.authenticated.access_token', {
      get() {
        const headers = {
          Accept: 'application/vnd.additive+json; version=2',
          'Accept-Language': this.currentLocale || 'de',
          'Content-Type': 'application/json'
        };
        return Object.assign({}, this._super('headers'), headers);
      }
    }),
    namespace: (0, _object.computed)('currentUser.currentOrganization.id', {
      get() {
        const currentOrganization = this.currentUser && this.currentUser.currentOrganization;
        if (currentOrganization && currentOrganization.id) {
          return `api/organizations/${currentOrganization.id}`;
        } else {
          return 'api';
        }
      }
    }),
    pathForType() {
      return (0, _string.dasherize)(this._super(...arguments));
    }
  });
});