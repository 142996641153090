define("additive-account/models/email-template", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    config: (0, _model.attr)(),
    name: (0, _model.attr)('string'),
    styles: (0, _model.attr)()
  });
});