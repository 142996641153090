define("additive-account/components/color-indicator", ["exports", "@ember/component", "@glimmer/component", "@ember/string", "ember-arg-types", "@glimmer/tracking", "prop-types", "@ember/template-factory"], function (_exports, _component, _component2, _string, _emberArgTypes, _tracking, _propTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="color-indicator flex items-center dib mr05 br-100 shadow-1 {{@class}} {{@classNames}}"
    style={{this._backgroundColorStyle}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "20LVQd+5",
    "block": "[[[11,0],[16,0,[29,[\"color-indicator flex items-center dib mr05 br-100 shadow-1 \",[30,1],\" \",[30,2]]]],[16,5,[30,0,[\"_backgroundColorStyle\"]]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "additive-account/components/color-indicator.hbs",
    "isStrictMode": false
  });

  /**
   * Component which displays a given color as dot
   *   * colorCode
   *
   * ```hbs
   * {{color-indicator colorCode='#000000'}}
   * ```
   *
   * @class color-indicator
   */
  let ColorIndicator = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _class = class ColorIndicator extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the code of the color
       *
       * @property colorCode
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "colorCode", _descriptor, this);
    }
    /**
     * computed styles of the color dot
     *
     * @computed _backgroundColorStyle
     * @type {String}
     * @private
     */
    get _backgroundColorStyle() {
      return this.colorCode ? (0, _string.htmlSafe)(`background-color: ${this.colorCode};`) : '';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "colorCode", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColorIndicator);
});