define("additive-account/controllers/instances/instance/settings/closing-periods", ["exports", "@ember/controller", "@ember/object", "ember-concurrency", "@ember/service"], function (_exports, _controller, _object, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    /**
     * the active closing-period in the detail dialog
     *
     * @property activeClosingPeriod
     * @type {Model}
     * @default null
     */
    activeClosingPeriod: false,
    /**
     * whether the detail dialog is open
     *
     * @property isDetailDialog
     * @type {Boolean}
     * @default null
     */
    isDetailDialog: false,
    isLoading: _object.computed.alias('fetchClosingPeriods.isRunning'),
    organizationId: _object.computed.alias('currentUser.currentOrganization.id'),
    isManager: _object.computed.alias('currentUser.isManager'),
    isMember: _object.computed.not('currentUser.isManager'),
    fabButtonContainer: (0, _object.computed)('isLoading', {
      get() {
        return document.getElementById('settings-fab-button-container');
      }
    }),
    /**
     * fetches all available closing periods
     *
     * @function fetchClosingPeriods
     * @type {Task}
     */
    fetchClosingPeriods: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.set)(this, 'isError', false);
        const closingPeriods = yield this.store.findAll('closing-time');
        (0, _object.set)(this, 'closingPeriods', closingPeriods);
      } catch (e) {
        (0, _object.set)(this, 'isError', true);
      }
    }),
    /**
     * deletes a given closing period and reloads the list
     *
     * @function deleteClosingPeriod
     * @type {Task}
     * @param {String} id
     */
    deleteClosingPeriod: (0, _emberConcurrency.task)(function* (closingPeriod) {
      try {
        yield closingPeriod.destroyRecord();
        this.uiToast.showToast({
          title: this.intl.t('global.toasts.delete.success'),
          description: this.intl.t('global.toasts.success.changes.description'),
          type: 'success'
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('global.toasts.delete.error'),
          description: this.intl.t('global.toasts.error.description'),
          type: 'error'
        });
      }
    }),
    actions: {
      delete(closingPeriod) {
        const {
          intl
        } = this;
        this.uiDialog.showConfirm(intl.t('routes.settings.closingPeriods.deleteDialog.title'), intl.t('routes.settings.closingPeriods.deleteDialog.description'), () => this.deleteClosingPeriod.perform(closingPeriod), intl.t('global.actions.delete'), true, true);
      },
      openDetail(closingPeriod) {
        (0, _object.setProperties)(this, {
          activeClosingPeriod: closingPeriod,
          isDetailDialog: true
        });
      }
    }
  });
});