define("additive-account/components/aa-corporate-design/logo/logo-upload", ["exports", "@ember/component", "additive-account/config/environment", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "prop-types", "@ember/template-factory"], function (_exports, _component, _environment, _component2, _object, _service, _tracking, _emberArgTypes, _propTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mt3 {{@classNames}}" {{did-insert this.onInsertElement}} ...attributes>
    <div class="font-sm mb1">{{this.label}}</div>
    <AaFileUpload
      @class="aa-corporate-design__image-upload relative bg-white br1"
      @accept=".jpg,.jpeg,.png"
      @isSmall={{this.isSmall}}
      @onFileChange={{this.uploadLogo}}
      @placeholderTitle={{t
        "components.aa-corporate-design.logo.actions.uploadImage.title"
        htmlSafe=true
      }}
      @showDeleteButton={{this._showDelete}}
      @isDisabled={{not this.currentUser.isManager}}
      @showLoading={{this._isUploadingLogo}}
      @responsePropertyName={{this.responsePropertyName}}
      @uploadUrl={{this._uploadUrl}}
      @minDimensions={{this.minImageDimensions}}
      @type={{this.type}}
      @url={{this._logoUrl}}
      as |upload|
    >
      <upload.input />
    </AaFileUpload>
  </div>
  */
  {
    "id": "1zbi1EO4",
    "block": "[[[11,0],[16,0,[29,[\"mt3 \",[30,1]]]],[17,2],[4,[38,0],[[30,0,[\"onInsertElement\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-sm mb1\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@class\",\"@accept\",\"@isSmall\",\"@onFileChange\",\"@placeholderTitle\",\"@showDeleteButton\",\"@isDisabled\",\"@showLoading\",\"@responsePropertyName\",\"@uploadUrl\",\"@minDimensions\",\"@type\",\"@url\"],[\"aa-corporate-design__image-upload relative bg-white br1\",\".jpg,.jpeg,.png\",[30,0,[\"isSmall\"]],[30,0,[\"uploadLogo\"]],[28,[37,2],[\"components.aa-corporate-design.logo.actions.uploadImage.title\"],[[\"htmlSafe\"],[true]]],[30,0,[\"_showDelete\"]],[28,[37,3],[[30,0,[\"currentUser\",\"isManager\"]]],null],[30,0,[\"_isUploadingLogo\"]],[30,0,[\"responsePropertyName\"]],[30,0,[\"_uploadUrl\"]],[30,0,[\"minImageDimensions\"]],[30,0,[\"type\"]],[30,0,[\"_logoUrl\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"input\"]],null,null,null],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"],[13]],[\"@classNames\",\"&attrs\",\"upload\"],false,[\"did-insert\",\"aa-file-upload\",\"t\",\"not\"]]",
    "moduleName": "additive-account/components/aa-corporate-design/logo/logo-upload.hbs",
    "isStrictMode": false
  });

  /**
   * Component which handles the upload of a logo
   *
   * ```hbs
   * {{aa-corporate-design/logo/logo-upload
   *   label
   *   type
   *   responsePropertyName
   *   logoUrl
   * }}
   * ```
   *
   * @class logo-upload
   */
  let AaCorporateDesignLogoLogoUpload = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.array), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AaCorporateDesignLogoLogoUpload extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      /**
       * the label of the logo
       *
       * @argument label
       * @type {String}
       * @default undefined
       */
      _initializerDefineProperty(this, "label", _descriptor2, this);
      /**
       * whether the file upload wrapper is small
       *
       * @argument isSmall
       * @type {Boolean}
       * @default undefined
       */
      _initializerDefineProperty(this, "isSmall", _descriptor3, this);
      /**
       * the logo type
       *
       * @argument type
       * @type {String}
       * @default undefined
       */
      _initializerDefineProperty(this, "type", _descriptor4, this);
      /**
       * an array indicating the minimum width and height of the image
       *
       * @argument minImageDimensions
       * @type {Array}
       * @default [440, 480]
       */
      _initializerDefineProperty(this, "minImageDimensions", _descriptor5, this);
      /**
       * the fetched logo url
       *
       * @argument logoUrl
       * @type {String}
       * @default undefined
       */
      _initializerDefineProperty(this, "logoUrl", _descriptor6, this);
      /**
       * the property name of the request result
       *
       * @argument responsePropertyName
       * @type {String}
       * @default undefined
       */
      _initializerDefineProperty(this, "responsePropertyName", _descriptor7, this);
      /**
       * the immutable copy of logo url
       *
       * @property _logoUrl
       * @type {String}
       * @default undefined
       * @private
       */
      _initializerDefineProperty(this, "_logoUrl", _descriptor8, this);
      /**
       * whether the logo is currently being uploaded
       *
       * @property _isUploadingLogo
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isUploadingLogo", _descriptor9, this);
    }
    get organizationId() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }

    /**
     * computed inverse of _isUploadingLogo
     *
     * @computed _isNotUploadingLogo
     * @type {Boolean}
     * @private
     */
    get _isNotUploadingLogo() {
      return !this._isUploadingLogo;
    }

    /**
     * whether delete button is visible or not
     *
     * @computed showDelete
     * @type {Boolean}
     * @private
     */
    get _showDelete() {
      return this.currentUser.isManager && this.logoUrl && this._isNotUploadingLogo;
    }

    /**
     * computed logo upload url
     *
     * @computed uploadUrl
     * @type {String}
     * @private
     */
    get _uploadUrl() {
      return `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/corporate-design/logo`;
    }

    /**
     * callback that is executed after logo changed
     *
     * @function onLogoChanged
     */
    onLogoChanged() {}
    onInsertElement() {
      this._logoUrl = this.logoUrl;
    }
    uploadLogo(imageUrl) {
      let isUploading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const logoHasChanged = this.logoUrl !== imageUrl;
      this._logoUrl = imageUrl;
      this._isUploadingLogo = isUploading;

      // new logo was uploaded
      if (logoHasChanged && !isUploading) {
        this.onLogoChanged(imageUrl, this.responsePropertyName);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSmall", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "minImageDimensions", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [440, 480];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "logoUrl", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "responsePropertyName", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_logoUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_isUploadingLogo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onLogoChanged", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onLogoChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadLogo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadLogo"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaCorporateDesignLogoLogoUpload);
});