define("additive-account/templates/components/aa-corporate-design/fonts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <div class="aa-corporate-design__font-grid mv2">
    <AaCorporateDesign::Fonts::Item
      @font={{this.fonts.primary}}
      @onFontDelete={{this.reloadTypography}}
      @key="primary"
      @onFontSave={{action "updateProperties"}}
      @isReadOnly={{this.isReadOnly}}
    />
    {{#if this.showSecondary}}
      <AaCorporateDesign::Fonts::Item
        @font={{this.fonts.secondary}}
        @onFontDelete={{this.reloadTypography}}
        @key="secondary"
        @onFontSave={{action "updateProperties"}}
        @isReadOnly={{this.isReadOnly}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "8S4lzB55",
    "block": "[[[10,0],[14,0,\"aa-corporate-design__font-grid mv2\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@font\",\"@onFontDelete\",\"@key\",\"@onFontSave\",\"@isReadOnly\"],[[30,0,[\"fonts\",\"primary\"]],[30,0,[\"reloadTypography\"]],\"primary\",[28,[37,1],[[30,0],\"updateProperties\"],null],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"showSecondary\"]],[[[1,\"    \"],[8,[39,0],null,[[\"@font\",\"@onFontDelete\",\"@key\",\"@onFontSave\",\"@isReadOnly\"],[[30,0,[\"fonts\",\"secondary\"]],[30,0,[\"reloadTypography\"]],\"secondary\",[28,[37,1],[[30,0],\"updateProperties\"],null],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"aa-corporate-design/fonts/item\",\"action\",\"if\"]]",
    "moduleName": "additive-account/templates/components/aa-corporate-design/fonts.hbs",
    "isStrictMode": false
  });
});