define("additive-account/mirage/endpoints/auth-routes", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.authRoutes = void 0;
  const redirectRoute = '/auth/callback';
  const authRoutes = _exports.authRoutes = function authRoutes(server) {
    const formEncodedToJson = encoded => {
      let result = {};
      encoded.split('&').forEach(part => {
        let item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    };
    server.post('/oauth/authorize', (db, request) => {
      const params = request.queryParams;
      if (params.username === 'user@email.com' && params.password === 'very_secret') {
        const body = {
          access_token: 'very_secret_token',
          token_type: 'bearer'
        };
        return new _emberCliMirage.default.Response(200, body, body);
      } else {
        const body = {
          errors: 'Email or password invalid'
        };
        return new _emberCliMirage.default.Response(401, {}, body);
      }
    });
    server.get('/oauth/authorize', () => {
      return new _emberCliMirage.default.Response(302);
    });

    // fakes the api redirect when a user tries to login
    server.post('/auth/login', () => {
      window.visit(redirectRoute + '?code=12345');
      window.visit('/create');
      return new _emberCliMirage.default.Response(201, {}, {});
    });
    server.post('/oauth/token', (db, request) => {
      const requestBody = formEncodedToJson(request.requestBody);
      if (requestBody.client_id === '1' && requestBody.client_secret === 'jNNSLIEfJgJ20mUjKmDHmFFbCqPA9hg38MstN9tk' && requestBody.code === '12345' && requestBody.grant_type === 'authorization_code') {
        const body = {
          access_token: 'very_secret_access_token',
          expires_in: 31536000,
          refresh_token: 'very_secret_refresh_token',
          token_type: 'Bearer'
        };
        return new _emberCliMirage.default.Response(201, {}, body);
      }
      const body = {
        errors: 'API error'
      };
      return new _emberCliMirage.default.Response(401, {}, body);
    });
    server.post('/auth/signup', (db, request) => {
      const params = JSON.parse(request.requestBody);
      if (params.email === 'user@email.com' && params.password === 'very_secret') {
        /**
         * TODO: not quite sure the user should have a role when signinUp
         * Needs to be reavaluated after API has the whole authentication mechanism set up
         */
        const body = {
          user: {
            email: 'user@email.com',
            fullname: 'Dummy User',
            profile_image: 'https://robohash.org/dummy',
            roles: ['ROLE_USER']
          }
        };
        return new _emberCliMirage.default.Response(201, {
          location: '/create' // will redirect to /create
        }, body);
      } else if (params.email === 'admin@email.com' && params.password === 'very_secret') {
        const body = {
          user: {
            email: 'admin@email.com',
            fullname: 'Dummy User',
            profile_image: '',
            roles: ['ROLE_ADMIN']
          }
        };
        return new _emberCliMirage.default.Response(201, {}, body);
      } else if (params.email === 'taken@email.com') {
        const body = {
          errors: 'User already exists'
        };
        return new _emberCliMirage.default.Response(422, {}, body);
      } else {
        const body = {
          errors: 'API error'
        };
        return new _emberCliMirage.default.Response(401, {}, body);
      }
    });
    server.post('/auth/password/email', (db, request) => {
      const params = JSON.parse(request.requestBody);
      if (params.email === 'reset@email.com') {
        /**
         * TODO: not quite sure the user should have a role when signinUp
         * Needs to be reavaluated after API has the whole authentication mechanism set up
         */
        const body = {
          status: 'ok'
        };
        return new _emberCliMirage.default.Response(200, {}, body);
      } else if (params.email === 'new@email.com') {
        // TODO: Add regexp for mails
        const body = {
          errors: "email doesn't exist"
        };
        return new _emberCliMirage.default.Response(422, {}, body);
      } else {
        const body = {
          errors: 'api error'
        };
        return new _emberCliMirage.default.Response(422, {}, body);
      }
    });
    server.post('/auth/password/reset', (db, request) => {
      const params = formEncodedToJson(request.requestBody);
      if (!params || !params.token) {
        const body = {
          errors: 'Token invalid'
        };
        return new _emberCliMirage.default.Response(401, {}, body);
      }
      if (params.token !== '123') {
        const body = {
          errors: 'Invalid token'
        };
        return new _emberCliMirage.default.Response(403, {}, body);
      } else if (params.email === 'reset@email.com') {
        /**
         * TODO: not quite sure the user should have a role when signinUp
         * Needs to be reavaluated after API has the whole authentication mechanism set up
         */
        const body = {
          status: 'ok'
        };
        return new _emberCliMirage.default.Response(200, {}, body);
      } else {
        const body = {
          errors: "email doesn't exist"
        };
        return new _emberCliMirage.default.Response(422, {}, body);
      }
    });
    server.post('/broadcasting/auth', () => {
      const body = {
        auth: 'such_secret'
      };
      return new _emberCliMirage.default.Response(200, {}, body);
    });
  };
});