define("additive-account/controllers/application", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@additive-apps/auth/utils/roles-map", "additive-account/mixins/global-user"], function (_exports, _controller, _object, _service, _rolesMap, _globalUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const instanceRoutes = [{
    path: 'instances.instance.organization',
    intlKey: 'global.myOrganizations',
    key: 'home',
    svg: 'home'
  }, {
    path: 'instances.instance.users',
    intlKey: 'routes.users.title',
    key: 'users',
    svg: 'people',
    requiredRole: 'member'
  }, {
    path: 'instances.instance.apps',
    intlKey: 'global.attributes.apps',
    key: 'apps',
    svg: 'apps',
    requiredRole: 'member'
  },
  /*{
    TODO: DISABLED BILLINGS TILL ABLE TO REUSE
    path: 'instances.instance.billings',
    intlKey: 'global.attributes.billings',
    key: 'billings',
    svg: 'cart',
    requiredRole: 'admin'
  },*/
  {
    path: 'instances.instance.email-templates.list',
    intlKey: 'global.attributes.emailTemplates',
    key: 'emailTemplates',
    svg: 'mail'
  }, {
    path: 'instances.instance.corporate-design',
    intlKey: 'global.attributes.corporateDesign',
    key: 'corporateDesign',
    svg: 'style'
  }, {
    path: 'instances.instance.auditlog',
    intlKey: 'global.attributes.auditlog',
    svg: 'audit-log',
    requiredRole: 'member'
  }, {
    path: 'instances.instance.settings',
    intlKey: 'global.attributes.settings',
    key: 'settings',
    svg: 'settings'
  }];
  let ApplicationController = _exports.default = (_class = class ApplicationController extends _controller.default.extend(_globalUser.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiState", _descriptor6, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor7, this);
      _defineProperty(this, "indexRoutes", [{
        path: 'instances.me.index',
        intlKey: 'global.myProfile',
        svg: 'apps'
      }, {
        path: 'instances.me.inbox',
        intlKey: 'global.messages',
        svg: 'message'
      }]);
    }
    get currentOrganization() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.currentOrganization;
    }
    get organizationUser() {
      var _this$currentUser2;
      return (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.user;
    }
    get organizationId() {
      var _this$currentOrganiza;
      return (_this$currentOrganiza = this.currentOrganization) === null || _this$currentOrganiza === void 0 ? void 0 : _this$currentOrganiza.id;
    }
    get showNavdrawer() {
      var _this$session;
      return ((_this$session = this.session) === null || _this$session === void 0 ? void 0 : _this$session.isAuthenticated) && !['instances.create', 'confirm'].includes(this.router.currentRouteName) && !this.router.currentRouteName.startsWith('auth');
    }
    get isPartnerAdmin() {
      var _this$globalUser;
      return ((_this$globalUser = this.globalUser) === null || _this$globalUser === void 0 ? void 0 : _this$globalUser.partnerRole) === _rolesMap.ROLE_ADMIN;
    }
    get isPartnerManager() {
      var _this$globalUser2;
      return ((_this$globalUser2 = this.globalUser) === null || _this$globalUser2 === void 0 ? void 0 : _this$globalUser2.partnerRole) === _rolesMap.ROLE_MANAGER;
    }
    get isAdditive() {
      var _this$globalUser3;
      return (_this$globalUser3 = this.globalUser) === null || _this$globalUser3 === void 0 ? void 0 : _this$globalUser3.isAdditive;
    }
    get showCreateAction() {
      return this.isPartnerAdmin || this.isPartnerManager || this.isAdditive;
    }
    get userImagePath() {
      var _this$globalUser4;
      return ((_this$globalUser4 = this.globalUser) === null || _this$globalUser4 === void 0 ? void 0 : _this$globalUser4.imagePath) || 'images/placeholder/placeholder-user.svg';
    }
    get helpUrl() {
      var _this$uiLocale;
      const locale = ((_this$uiLocale = this.uiLocale) === null || _this$uiLocale === void 0 ? void 0 : _this$uiLocale.locale) || 'de';
      return `https://knowledge.additive.eu/${locale}/knowledge`;
    }
    get applicationRoutes() {
      const organizationUser = this.organizationUser;
      const isSupport = organizationUser && organizationUser.isSupport;
      const isSuperAdmin = organizationUser && organizationUser.isSuperAdmin;
      if (isSupport || isSuperAdmin) {
        return instanceRoutes;
      }
      const isPartnerOrg = this.currentOrganization && this.currentOrganization.partnerSlug;

      /**
       * inside a partners suborganization the apps-entry is only visible for
       * the partner and the billings entry is hidden for both, the user and
       * the partner
       */
      return instanceRoutes.filter(route => {
        if (isPartnerOrg) {
          // in case it is a suborganisation we always hide the billings entry
          // and show the apps only to member of the partner org that manages this org
          if (route.key === 'billings') {
            return false;
          }
        }
        return route.requiredRole ? organizationUser && (0, _rolesMap.hasAccess)(route.requiredRole, organizationUser.role) : true;
      });
    }
    get isMeRoute() {
      var _this$router;
      return (_this$router = this.router) === null || _this$router === void 0 || (_this$router = _this$router.currentRouteName) === null || _this$router === void 0 ? void 0 : _this$router.startsWith('instances.me');
    }

    /**
     * Handles the transition to Instance routes, apps and users are removed from the store
     * and the new organization of the user is set.
     * If the user tries to navigate to the organisation he is already he is not redirected.
     *
     * @param {Object} organization
     */
    transitionToOrganizationRoute(organization) {
      const currentOrg = this.currentOrganization;
      const route = this.router && this.router.currentRouteName;
      if ((currentOrg && currentOrg.id) !== organization.id || route.startsWith('instances.me')) {
        this.currentUser.currentOrganization = organization;
        this.transitionToRoute('instances.instance', organization);
        const store = this.store;
        store.unloadAll('user');
        store.unloadAll('app');
        store.unloadAll('setting');
        store.unloadAll('corporate-design');
        store.unloadAll('room-count');
        store.unloadAll('closing-time');
        store.unloadAll('email-template');

        // reset orgUser
        this.currentUser.user = null;
        this.currentUser.loadUser();
      }
    }
    createNewOrganization() {
      this.transitionToRoute('instances.create');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToOrganizationRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToOrganizationRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createNewOrganization", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createNewOrganization"), _class.prototype), _class);
});