define("additive-account/components/simple-nav/trigger", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _emberArgTypes, _propTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="simple-nav__trigger
      {{@class}}
      {{@classNames}}
      {{if this.isActive 'simple-nav__trigger--active'}}
      {{if this.isDisabled 'simple-nav__trigger--disabled'}}"
    {{on "click" this.onClick}}
    role="button"
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "dGViHG3I",
    "block": "[[[11,0],[16,0,[29,[\"simple-nav__trigger\\n    \",[30,1],\"\\n    \",[30,2],\"\\n    \",[52,[30,0,[\"isActive\"]],\"simple-nav__trigger--active\"],\"\\n    \",[52,[30,0,[\"isDisabled\"]],\"simple-nav__trigger--disabled\"]]]],[24,\"role\",\"button\"],[17,3],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "additive-account/components/simple-nav/trigger.hbs",
    "isStrictMode": false
  });
  let SimpleNavTrigger = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.number), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class SimpleNavTrigger extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentIndex", _descriptor, this);
      _initializerDefineProperty(this, "navIndex", _descriptor2, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor3, this);
      _initializerDefineProperty(this, "shouldValidate", _descriptor4, this);
      _initializerDefineProperty(this, "updateIndex", _descriptor5, this);
      _initializerDefineProperty(this, "validate", _descriptor6, this);
    }
    get isActive() {
      return this.currentIndex === this.navIndex;
    }
    nextStep() {
      const navIndex = this.navIndex;
      if (this.currentIndex !== navIndex) {
        this.updateIndex(navIndex);
      }
    }
    onClick() {
      if (this.isDisabled === true) {
        return false;
      }
      if (this.shouldValidate) {
        /*
         * ember-changeset has empty validations when initialized,
         * if it doesn't get validate it will always be valid.
         *
         * the validation promise resolves only if the changeset is valid
         */
        return this.validate().then(() => this.nextStep())
        /* Do nothing on catch, otherwise it will throw an uncatched error */.catch(() => {});
      } else {
        this.nextStep();
      }
      return false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navIndex", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "shouldValidate", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "updateIndex", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "validate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SimpleNavTrigger);
});