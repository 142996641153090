define("additive-account/routes/instances/instance/billings", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "additive-account/utils/billing-management", "@additive-apps/auth/mixins/access-guard", "@additive-apps/ui/utils/country-utils"], function (_exports, _route, _service, _object, _billingManagement, _accessGuard, _countryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_accessGuard.default, {
    currentUser: (0, _service.inject)(),
    authenticatedFetch: (0, _service.inject)(),
    // access control settings
    requiredRole: 'admin',
    routeIfUnauthorized: 'instances.instance',
    currentOrganization: _object.computed.alias('currentUser.currentOrganization'),
    organizationId: _object.computed.alias('currentOrganization.id'),
    beforeModel() {
      const currentOrganization = this.currentOrganization;
      const currentUser = this.currentUser;
      if (currentOrganization.partnerSlug && (!currentUser.user || !currentUser.user.isSuperAdmin)) {
        const routeIfUnauthorized = this.routeIfUnauthorized;
        this.transitionTo(routeIfUnauthorized);
      } else {
        const routeIfUnauthorized = this.routeIfUnauthorized;
        this.transitionTo(routeIfUnauthorized);
        //Replace the above 2 lines for this line to reactivate billings: return this._super(...arguments);
      }
    },
    model() {
      (0, _object.set)(this, 'billing', {
        legalName: '',
        addressCountry: '',
        addressLocality: '',
        addressPostalCode: '',
        addressStreet: '',
        addressRegion: '',
        vatId: ''
      });
      (0, _object.set)(this, 'subscription', {
        cardLastFour: '',
        cardExpMonth: '',
        cardExpYear: '',
        cardName: ''
      });
      return {
        billing: this.billing,
        subscription: this.subscription,
        invoices: this.invoices
      };
    },
    setupController(controller, model) {
      this._super(...arguments);
      const billingTask = _billingManagement.getBillingData.task.perform(this, this.organizationId);
      (0, _object.set)(controller, 'billingTask', billingTask);
      billingTask.then(response => {
        if (response && response.billing) {
          (0, _object.set)(model, 'billing', response.billing);
        }
      });
      _billingManagement.getSubscriptionData.task.perform(this, this.organizationId).then(response => {
        if (response && response.subscription) {
          (0, _object.set)(model, 'subscription', response.subscription);
        }
      });
      _billingManagement.getInvoiceData.task.perform(this, this.organizationId).then(response => {
        if (response && response.invoices) {
          (0, _object.set)(model, 'invoices', response.invoices);
        }
      });
      _countryUtils.fetchCountries.task.perform(controller).then(countries => (0, _object.set)(controller, 'countries', countries));
    }
  });
});