define("additive-account/routes/instances/instance", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "ember-concurrency", "@additive-apps/auth/mixins/auth-route-mixin", "additive-account/mixins/global-user"], function (_exports, _route, _service, _object, _emberConcurrency, _authRouteMixin, _globalUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_authRouteMixin.default, _globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    model(params) {
      return this.setupOrg.perform(params.instance_id);
    },
    setupController(controller) {
      var _this$globalUser;
      this._super(...arguments);
      if ((_this$globalUser = this.globalUser) !== null && _this$globalUser !== void 0 && _this$globalUser.new) (0, _object.set)(controller, 'onboarding', '1');
    },
    /**
     * Fetches organization to fill up currentUser service
     * and its settings to fill up uiAppSettings service.
     */
    setupOrg: (0, _emberConcurrency.task)(function* (instanceId) {
      const tasks = [];
      const adapter = this.store.adapterFor('organization');
      const baseUrl = adapter.urlForFindRecord(instanceId, 'organization');
      const fetchOrgSettings = this.authenticatedFetch.fetch(`${baseUrl}/settings`);
      const fetchOrg = this.store.findRecord('organization', instanceId);
      tasks.push(fetchOrg);
      tasks.push(fetchOrgSettings);
      tasks.push((0, _emberConcurrency.timeout)(250));
      const [organization, orgSettingsResponse] = yield (0, _emberConcurrency.all)(tasks);
      (0, _object.set)(this, 'currentUser.currentOrganization', organization);
      yield this.currentUser.loadUser();
      yield this.uiLocale.setLocale(this.currentUser.user.language || 'de');
      if (!orgSettingsResponse.ok) {
        /* TODO: error handling when request fails, but what? is essential */
        return organization;
      }
      const orgSettings = yield orgSettingsResponse.json();
      if (orgSettings && orgSettings.settings) {
        const {
          settings
        } = orgSettings;
        (0, _object.setProperties)(this.uiAppSettings, {
          'languages.contentLanguages': settings.contentLanguages,
          'languages.defaultLanguage': settings.defaultLanguage,
          currentLocale: settings.defaultLanguage
        });
      }
      return organization;
    })
  });
});