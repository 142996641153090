define("additive-account/controllers/instances/instance/organization", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/service", "@ember/object", "additive-account/mixins/global-user", "@additive-apps/ui/constants"], function (_exports, _environment, _controller, _service, _object, _globalUser, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6; // the mixin is required in order to be used from the route
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstancesInstanceOrganization = _exports.default = (_class = class InstancesInstanceOrganization extends _controller.default.extend(_globalUser.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      /**
       * the accessible apps for the current organization
       * these are set by the setupController hook in the route
       *
       * @property accessibleApps
       * @type {Array}
       * @default []
       */
      _defineProperty(this, "accessibleApps", []);
      /**
       * the available countries
       * these are set by the setupController hook in the route
       *
       * @property countries
       * @type {Array}
       * @default []
       */
      _defineProperty(this, "countries", []);
      /**
       * whether the org logo is currently being uploaded
       *
       * @property isUploadingImage
       * @type {Boolean}
       * @default false
       */
      _defineProperty(this, "isUploadingImage", false);
      /**
       * whether the org header is currently being uploaded
       *
       * @property isUploadingHeaderImage
       * @type {Boolean}
       * @default false
       */
      _defineProperty(this, "isUploadingHeaderImage", false);
      _defineProperty(this, "headerImageDimensions", [1600, 1200]);
      _defineProperty(this, "imageDimensions", [512, 512]);
    }
    get hasEmptyHeaderImage() {
      var _this$model;
      return !((_this$model = this.model) !== null && _this$model !== void 0 && _this$model.imageHeaderPath);
    }
    get hasEmptyImage() {
      var _this$model2;
      return !((_this$model2 = this.model) !== null && _this$model2 !== void 0 && _this$model2.imagePath);
    }
    get isDeleteHeaderImageDisabled() {
      return this.hasEmptyHeaderImage || this.isUploadingHeaderImage;
    }
    get isDeleteImageDisabled() {
      return this.hasEmptyImage || this.isUploadingImage;
    }
    get hasAdminAccess() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.isAdmin;
    }
    get isAdditiveOrPartner() {
      var _this$globalUser, _this$globalUser2;
      return ((_this$globalUser = this.globalUser) === null || _this$globalUser === void 0 ? void 0 : _this$globalUser.isAdditive) || ((_this$globalUser2 = this.globalUser) === null || _this$globalUser2 === void 0 ? void 0 : _this$globalUser2.isPartner);
    }
    get hasLegacyCmsUrl() {
      var _this$accessibleApps;
      if (!this.hasAdminAccess || !this.isAdditiveOrPartner) {
        return false;
      }
      return (_this$accessibleApps = this.accessibleApps) === null || _this$accessibleApps === void 0 ? void 0 : _this$accessibleApps.find(app => app.id === _constants.APP_ID_CMS);
    }
    get isViewer() {
      var _this$currentUser2;
      return (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isViewer;
    }
    get isOrganizationRoute() {
      return this.router.isActive('instances.instance.organization.index');
    }
    get uploadUrl() {
      return `${_environment.default.APP.apiBaseHost}/api/organizations/${this.model.id}/image`;
    }
    uploadLogo(imageUrl, isUploading) {
      this.model.imagePath = imageUrl;
      this.isUploadingImage = isUploading;
    }
    uploadBackgroundImage(imageUrl, isUploading) {
      this.model.imageHeaderPath = imageUrl;
      this.isUploadingHeaderImage = isUploading;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadLogo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadLogo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadBackgroundImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadBackgroundImage"), _class.prototype), _class);
});