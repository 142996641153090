define("additive-account/utils/billing-management", ["exports", "additive-account/config/environment", "ember-concurrency", "rsvp", "@ember/object"], function (_exports, _environment, _emberConcurrency, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSubscriptionData = _exports.getInvoiceData = _exports.getBillingData = void 0;
  /**
   * Fetches billing data of an organization
   *
   * @method getBillingData
   * @returns {Object[]}
   */
  const getBillingData = _exports.getBillingData = {};
  (0, _object.defineProperty)(getBillingData, 'task', (0, _emberConcurrency.task)(function* (self, organizationId) {
    const endpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${organizationId}/billing`;
    const request = self.authenticatedFetch.fetch(endpoint);
    const response = yield request;
    if (response && response.status === 204) {
      return (0, _rsvp.resolve)();
    } else {
      const data = yield response.json();
      if (data && data.errors) {
        return (0, _rsvp.reject)(data.errors);
      } else {
        return (0, _rsvp.resolve)(data);
      }
    }
  }).drop());

  /**
   * Fetches subscription data of an organization
   *
   * @method getSubscriptionData
   * @returns {Object[]}
   */
  const getSubscriptionData = _exports.getSubscriptionData = {};
  (0, _object.defineProperty)(getSubscriptionData, 'task', (0, _emberConcurrency.task)(function* (self, organizationId) {
    const endpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${organizationId}/subscription`;
    const request = self.authenticatedFetch.fetch(endpoint);
    const response = yield request;
    if (response && response.status === 204) {
      return (0, _rsvp.resolve)();
    } else {
      const data = yield response.json();
      if (data && data.errors) {
        return (0, _rsvp.reject)(data.errors);
      } else {
        return (0, _rsvp.resolve)(data);
      }
    }
  }).drop());

  /**
   * Fetches invoice data of an organization
   *
   * @method getInvoiceData
   * @returns {Object[]}
   */
  const getInvoiceData = _exports.getInvoiceData = {};
  (0, _object.defineProperty)(getInvoiceData, 'task', (0, _emberConcurrency.task)(function* (self, organizationId) {
    const isOwner = yield (0, _emberConcurrency.waitForProperty)(self, 'currentUser.isOwner', isOwner => isOwner != undefined);
    if (isOwner) {
      const endpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${organizationId}/invoices`;
      const request = self.authenticatedFetch.fetch(endpoint);
      const response = yield request;
      const data = yield response.json();
      if (data && data.errors) {
        return (0, _rsvp.reject)(data.errors);
      }
      return (0, _rsvp.resolve)(data);
    }
  }).drop());
});