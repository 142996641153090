define("additive-account/templates/auth", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="auth-views-wrapper flex flex-column w-100 min-vh-100">
    <div class="auth-views bg-primary flex-grow-1">
      {{outlet}}
    </div>
    <footer class="main-footer bg-primary">
      <div class="footer__links font-sm primary">
        <a href="https://www.additive-apps.eu/privacy" target="_blank" rel="noopener noreferrer">
          {{t "global.attributes.privacy"}}
        </a>
        <a href="https://www.additive-apps.eu/cookies.html" target="_blank" rel="noopener noreferrer">
          {{t "global.attributes.cookies"}}
        </a>
        <a href="https://www.additive.eu/agb" target="_blank" rel="noopener noreferrer">
          {{t "global.attributes.generalTerms"}}
        </a>
        <a
          href="https://www.additive-apps.eu/impressum.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{t "global.attributes.imprint"}}
        </a>
      </div>
    </footer>
  </div>
  */
  {
    "id": "knj/j53t",
    "block": "[[[10,0],[14,0,\"auth-views-wrapper flex flex-column w-100 min-vh-100\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"auth-views bg-primary flex-grow-1\"],[12],[1,\"\\n    \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"footer\"],[14,0,\"main-footer bg-primary\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"footer__links font-sm primary\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"https://www.additive-apps.eu/privacy\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"global.attributes.privacy\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,3],[14,6,\"https://www.additive-apps.eu/cookies.html\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"global.attributes.cookies\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,3],[14,6,\"https://www.additive.eu/agb\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"global.attributes.generalTerms\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,3],[14,6,\"https://www.additive-apps.eu/impressum.html\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"global.attributes.imprint\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"t\"]]",
    "moduleName": "additive-account/templates/auth.hbs",
    "isStrictMode": false
  });
});