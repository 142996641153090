define("additive-account/adapters/closing-time", ["exports", "additive-account/adapters/application", "@ember/object", "@ember/service"], function (_exports, _application, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    currentUser: (0, _service.inject)(),
    namespace: (0, _object.computed)('currentUser.currentOrganization.id', {
      get() {
        const namespace = this._super(...arguments);
        return `${namespace}/settings`;
      }
    })
  });
});