define("additive-account/templates/components/aa-user/detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AaOrganization::InfoItem
    @icon="user"
    @label={{t "global.attributes.name"}}
    @value={{this.user.fullName}}
  />
  
  <AaOrganization::InfoItem
    @icon="at"
    @label={{t "global.attributes.email"}}
    @value={{this.user.email}}
  />
  
  <AaOrganization::InfoItem
    @icon="language"
    @label={{t "global.attributes.language"}}
    @value={{t (concat "languageSelect." this.user.language)}}
  />
  */
  {
    "id": "9uU6JHR4",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@label\",\"@value\"],[\"user\",[28,[37,1],[\"global.attributes.name\"],null],[30,0,[\"user\",\"fullName\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@label\",\"@value\"],[\"at\",[28,[37,1],[\"global.attributes.email\"],null],[30,0,[\"user\",\"email\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@label\",\"@value\"],[\"language\",[28,[37,1],[\"global.attributes.language\"],null],[28,[37,1],[[28,[37,2],[\"languageSelect.\",[30,0,[\"user\",\"language\"]]],null]],null]]],null]],[],false,[\"aa-organization/info-item\",\"t\",\"concat\"]]",
    "moduleName": "additive-account/templates/components/aa-user/detail.hbs",
    "isStrictMode": false
  });
});