define("additive-account/validations/corporate-design", ["exports", "ember-changeset-validations/validators", "additive-account/validators/valid-color", "additive-account/validators/valid-css-size"], function (_exports, _validators, _validColor, _validCssSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typographyValidations = _exports.fontValidations = _exports.fontTypeValidations = _exports.defaultValidations = void 0;
  const defaultValidations = intl => {
    return {
      color: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      }), (0, _validColor.default)({
        presence: true,
        message: intl.t('global.errors.invalidFormat')
      })],
      contrastColor: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      }), (0, _validColor.default)({
        presence: true,
        message: intl.t('global.errors.invalidFormat')
      })]
    };
  };
  _exports.defaultValidations = defaultValidations;
  const typographyValidations = intl => {
    return {
      fontFamily: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      })],
      fontSize: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      }), (0, _validCssSize.default)({
        presence: true,
        message: intl.t('global.errors.invalidFormat')
      })],
      letterSpacing: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      })],
      lineHeight: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      }), (0, _validCssSize.default)({
        presence: true,
        message: intl.t('global.errors.invalidFormat')
      })],
      color: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      })]
    };
  };
  _exports.typographyValidations = typographyValidations;
  const fontValidations = intl => {
    return {
      serif: (0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }),
      fontFamily: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      })]
    };
  };
  _exports.fontValidations = fontValidations;
  const fontTypeValidations = intl => {
    return {
      fontFamily: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      }), (0, _validators.validateFormat)({
        type: 'string',
        message: intl.t('global.errors.invalidFormat')
      })],
      weight: [(0, _validators.validatePresence)({
        presence: true,
        message: intl.t('global.errors.required')
      })]
    };
  };
  _exports.fontTypeValidations = fontTypeValidations;
});