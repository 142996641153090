define("additive-account/templates/components/upgrade-plan", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  {{#if this.isOpen}}
    <UiModal
      @title={{t "routes.apps.dialogs.upgradePlan.title"}}
      @subtitle={{t (concat "appNames." this.app.id ".presentationName")}}
      @onClose={{action (mut this.isOpen) false}}
      @size="md"
      as |dialog|
    >
      <dialog.header />
      <dialog.content as |content|>
        <content.body @classNames="flex-grow-1">
          <UiRoutableTabs @tabRoutes={{this.cards}} as |tabs|>
            <tabs.header @class="justify-start" as |header|>
              {{#each this.cards as |plan index|}}
                <header.tab
                  @class="ui-tab-item__link--underlined"
                  @tabName={{plan.name}}
                  @isActive={{plan.isActive}}
                  @onClick={{action "openTab" index}}
                />
              {{/each}}
            </tabs.header>
  
            <tabs.content @class="flex flex-column">
              <AnimatedContainer>
                <UpgradePlanCard
                  @app={{this.app}}
                  @plan={{this.activeTab}}
                  @activePlan={{this.activePlan}}
                  @upgrade={{this.upgrade}}
                />
              </AnimatedContainer>
            </tabs.content>
          </UiRoutableTabs>
        </content.body>
      </dialog.content>
    </UiModal>
  {{/if}}
  */
  {
    "id": "uW6yF8wO",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@subtitle\",\"@onClose\",\"@size\"],[[28,[37,2],[\"routes.apps.dialogs.upgradePlan.title\"],null],[28,[37,2],[[28,[37,3],[\"appNames.\",[30,0,[\"app\",\"id\"]],\".presentationName\"],null]],null],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"isOpen\"]]],null],false],null],\"md\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"body\"]],null,[[\"@classNames\"],[\"flex-grow-1\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@tabRoutes\"],[[30,0,[\"cards\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"header\"]],null,[[\"@class\"],[\"justify-start\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"cards\"]]],null]],null],null,[[[1,\"              \"],[8,[30,4,[\"tab\"]],null,[[\"@class\",\"@tabName\",\"@isActive\",\"@onClick\"],[\"ui-tab-item__link--underlined\",[30,5,[\"name\"]],[30,5,[\"isActive\"]],[28,[37,4],[[30,0],\"openTab\",[30,6]],null]]],null],[1,\"\\n\"]],[5,6]],null],[1,\"          \"]],[4]]]]],[1,\"\\n\\n          \"],[8,[30,3,[\"content\"]],null,[[\"@class\"],[\"flex flex-column\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],null,null,[[\"default\"],[[[[1,\"\\n              \"],[8,[39,10],null,[[\"@app\",\"@plan\",\"@activePlan\",\"@upgrade\"],[[30,0,[\"app\"]],[30,0,[\"activeTab\"]],[30,0,[\"activePlan\"]],[30,0,[\"upgrade\"]]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"dialog\",\"content\",\"tabs\",\"header\",\"plan\",\"index\"],false,[\"if\",\"ui-modal\",\"t\",\"concat\",\"action\",\"mut\",\"ui-routable-tabs\",\"each\",\"-track-array\",\"animated-container\",\"upgrade-plan-card\"]]",
    "moduleName": "additive-account/templates/components/upgrade-plan.hbs",
    "isStrictMode": false
  });
});