define("additive-account/templates/instances/instance/email-templates/list/detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiContentViews @title={{this.model.title}} as |view|>
    <view.navbar @onClose={{action "back"}} />
  
    <view.intro @title={{this.model.name}} @size="xl" />
  
    <view.content @stretch={{true}} @class="bt b--secondary-10 pt3 mv4">
      <AaEmailTemplatePreview @organization={{this.organization}} />
    </view.content>
  
    <view.footer>
      <UiButton
        @class="absolute bottom-3 right-3"
        @icon="edit"
        @isFabButton={{true}}
        @onClick={{action "edit"}}
      />
    </view.footer>
  </UiContentViews>
  */
  {
    "id": "qi5/aT4B",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[30,0,[\"model\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,[[\"@onClose\"],[[28,[37,1],[[30,0],\"back\"],null]]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@title\",\"@size\"],[[30,0,[\"model\",\"name\"]],\"xl\"]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@stretch\",\"@class\"],[true,\"bt b--secondary-10 pt3 mv4\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@organization\"],[[30,0,[\"organization\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@class\",\"@icon\",\"@isFabButton\",\"@onClick\"],[\"absolute bottom-3 right-3\",\"edit\",true,[28,[37,1],[[30,0],\"edit\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\"],false,[\"ui-content-views\",\"action\",\"aa-email-template-preview\",\"ui-button\"]]",
    "moduleName": "additive-account/templates/instances/instance/email-templates/list/detail.hbs",
    "isStrictMode": false
  });
});