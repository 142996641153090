define("additive-account/helpers/user-role", ["exports", "@ember/component/helper", "@additive-apps/auth/utils/roles-map"], function (_exports, _helper, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getRoleByAppId = getRoleByAppId;
  /**
   * Return a users role for a specific app
   *
   * @return {String}
   */
  function getRoleByAppId(params) {
    const [id, appIds, role] = params;
    if (role === _rolesMap.ROLE_CUSTOM) {
      let userRole = 'none';
      (appIds || []).find(app => {
        // the appId inside appRoles needs to be casted to string
        if (app && app.appId && app.appId.toString() === id) {
          userRole = app.role;
        }
      });
      return userRole;
    }
    return params;
  }
  var _default = _exports.default = (0, _helper.helper)(getRoleByAppId);
});