define("additive-account/components/aa-settings/room-counts/detail", ["exports", "@ember/component", "ember-concurrency", "@ember/service", "@ember/object", "ember-changeset", "ember-changeset-validations", "additive-account/validations/settings"], function (_exports, _component, _emberConcurrency, _service, _object, _emberChangeset, _emberChangesetValidations, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Edit and Create dialog for room count settings
   *
   * @module aa-settings/room-counts
   * @class detail
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    store: (0, _service.inject)(),
    /**
     * the changeset for the roomCount model
     *
     * @property changeset
     * @type {Changeset}
     * @default null
     */
    changeset: null,
    /**
     * the roomCount object
     *
     * @property roomCount
     * @type {Model}
     * @default null
     */
    roomCount: null,
    /**
     * the task to re-fetch the models after saving
     *
     * @property fetchRoomCounts
     * @type {Task}
     * @default undefined
     */
    fetchRoomCounts: undefined,
    /**
     * whether the discard changes dialog is open
     *
     * @property isDiscardChangesDialog
     * @type {Boolean}
     * @default false
     */
    isDiscardChangesDialog: false,
    init() {
      this._super(...arguments);
      let roomCount = this.roomCount;
      if (!roomCount) {
        roomCount = this.store.createRecord('room-count');
      }
      const validation = (0, _settings.roomCountValidation)(this.intl);
      const changeset = new _emberChangeset.default(roomCount, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.setProperties)(this, {
        changeset,
        roomCount
      });
    },
    /**
     * saves room count
     *
     * @function save
     * @type {Task}
     */
    save: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.set)(this, 'isTouched', true);
        yield this.changeset.validate();
        if (this.changeset.get('isInvalid')) {
          return;
        }
        yield this.changeset.save();
        this.uiToast.showToast({
          title: this.intl.t('global.toasts.success.changes.title'),
          description: this.intl.t('global.toasts.success.changes.description'),
          type: 'success'
        });
        this.fetchRoomCounts.perform();
        this.onClose();
      } catch (e) {
        // display error if period overlaps with another closing period
        if (e.errors && e.errors[0] && e.errors[0].detail === 'already_exists') {
          this.changeset.pushErrors('startsAt', this.intl.t('routes.settings.roomCounts.existsError'));
        } else {
          this.uiToast.showToast({
            title: this.intl.t('global.toasts.delete.error'),
            description: this.intl.t('global.toasts.error.description'),
            type: 'error'
          });
        }
      }
    }),
    onClose() {},
    actions: {
      close() {
        if (this.changeset.get('isPristine')) {
          return this.onClose();
        }
        (0, _object.set)(this, 'isDiscardChangesDialog', true);
      },
      discardChanges() {
        this.changeset && this.changeset.rollback();
        if (this.roomCount.isNew) {
          this.roomCount.destroyRecord();
        }
        this.onClose();
      }
    }
  });
});