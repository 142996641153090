define("additive-account/utils/user-management", ["exports", "additive-account/config/environment", "ember-concurrency", "fetch", "rsvp", "@ember/object"], function (_exports, _environment, _emberConcurrency, _fetch, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resendInvitation = _exports.getUsers = _exports.getPartnerUsers = _exports.getInvitationData = _exports.deleteUser = _exports.confirmInvitation = void 0;
  /**
   * Check whetever the provided token is a valid invitation token.
   *
   * @method getInvitationData
   * @param token, String the invitation-token string
   * @returns
   */
  const getInvitationData = _exports.getInvitationData = {};
  (0, _object.defineProperty)(getInvitationData, 'task', (0, _emberConcurrency.task)(function* (token) {
    return yield (0, _fetch.default)(`${_environment.default.APP.apiBaseHost}/api/invites/${token}`, {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }).then(response => {
      if (response && response.ok) {
        return response.json();
      }
      return (0, _rsvp.reject)(response.status);
    });
  }));

  /**
   * Confirms the invitation of the current logged in user with the provided token.
   *
   * @method confirmInvitation
   * @param token, String the invitation-token string
   */
  const confirmInvitation = _exports.confirmInvitation = {};
  (0, _object.defineProperty)(confirmInvitation, 'task', (0, _emberConcurrency.task)(function* (token, self) {
    const request = self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/invites/${token}/accept`, {
      method: 'post'
    });
    return yield request.then(_ref => {
      let {
        status
      } = _ref;
      return (0, _rsvp.resolve)(status);
    }).catch(_ref2 => {
      let {
        status
      } = _ref2;
      return (0, _rsvp.reject)(status);
    });
  }));

  /**
   * resends an invitation to a user that has not accepted his invitation yet
   *
   * @method resendInvitation
   *
   * @param invitationEndpoint, String the basepath for the invitation-endpoint
   * @param id, String the id of the user
   */
  const resendInvitation = _exports.resendInvitation = {};
  (0, _object.defineProperty)(resendInvitation, 'task', (0, _emberConcurrency.task)(function* (invitationEndpoint, id, self) {
    const request = self.authenticatedFetch.fetch(`${invitationEndpoint}/${id}/resend-invite`, {
      method: 'post'
    });
    return yield request.then(_ref3 => {
      let {
        status
      } = _ref3;
      return (0, _rsvp.resolve)(status);
    }).catch(_ref4 => {
      let {
        status
      } = _ref4;
      return (0, _rsvp.reject)(status);
    });
  }));

  /**
   * Sets the duration of the toast that is displayed when deleting a user.
   *
   * @function deleteUser
   * @async
   * @param {Object} user
   */
  const deleteUser = _exports.deleteUser = {};
  (0, _object.defineProperty)(deleteUser, 'task', (0, _emberConcurrency.task)(function* (user, self) {
    const callback = () => {
      self.cancelDeleting(user);
    };
    yield user.deleteRecord();
    self.uiToast.showToast({
      type: 'success',
      title: self.intl.t('routes.users.toasts.delete.title'),
      description: self.intl.t('routes.users.toasts.delete.description', {
        userName: user.fullName
      }),
      actionLabel: self.intl.t('global.actions.undo'),
      onClick: callback,
      duration: 2000
    });
    yield (0, _emberConcurrency.timeout)(2000);
    yield user.save();

    /* https://github.com/emberjs/data/issues/5014 */
    user.unloadRecord();
  }).drop());

  /**
   * Get all partner users
   *
   * @method getPartnerUsers
   * @returns
   */
  const getPartnerUsers = _exports.getPartnerUsers = {};
  (0, _object.defineProperty)(getPartnerUsers, 'task', (0, _emberConcurrency.task)(function* (self) {
    const orgId = self.currentUser && self.currentUser.currentOrganization && self.currentUser.currentOrganization.id;
    return yield self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${orgId}/partner-users`).then(response => {
      if (response && response.ok) {
        return response.json();
      }
      return (0, _rsvp.reject)(response.status);
    });
  }));

  /**
   * Get all users
   *
   * @method getUsers
   * @param controller
   * @returns
   */
  const getUsers = _exports.getUsers = {};
  (0, _object.defineProperty)(getUsers, 'task', (0, _emberConcurrency.task)(function* (self, controller) {
    return yield controller.store.findAll('user');
  }));
});