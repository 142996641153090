define("additive-account/templates/instances/instance/corporate-design", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiContentViews @title={{t "routes.corporateDesign.title"}} @theme="white" as |view|>
    <view.navbar />
  
    <view.intro
      @class="corporate-design-view__intro"
      @title={{t "routes.corporateDesign.title"}}
      @description={{t "routes.corporateDesign.description"}}
      @size="xl"
      @isMultiline={{true}}
    />
  
    <view.content @classNames="pt3">
      <UiRoutableTabs @tabRoutes={{this.routableTabs}} as |tabs|>
        <tabs.header @isUnderlined={{true}} as |header|>
          {{#each this.routableTabs as |route|}}
            <header.tab @tabName={{route.name}} @routePath={{route.path}} />
          {{/each}}
        </tabs.header>
        <tabs.content @class="flex flex-column">
          {{outlet}}
        </tabs.content>
      </UiRoutableTabs>
    </view.content>
  
    <view.footer @classNames="views__footer w-100" />
  </UiContentViews>
  */
  {
    "id": "7OJB9+EG",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"routes.corporateDesign.title\"],null],\"white\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@class\",\"@title\",\"@description\",\"@size\",\"@isMultiline\"],[\"corporate-design-view__intro\",[28,[37,1],[\"routes.corporateDesign.title\"],null],[28,[37,1],[\"routes.corporateDesign.description\"],null],\"xl\",true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"pt3\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@tabRoutes\"],[[30,0,[\"routableTabs\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"header\"]],null,[[\"@isUnderlined\"],[true]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"routableTabs\"]]],null]],null],null,[[[1,\"          \"],[8,[30,3,[\"tab\"]],null,[[\"@tabName\",\"@routePath\"],[[30,4,[\"name\"]],[30,4,[\"path\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"      \"]],[3]]]]],[1,\"\\n      \"],[8,[30,2,[\"content\"]],null,[[\"@class\"],[\"flex flex-column\"]],[[\"default\"],[[[[1,\"\\n        \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,[[\"@classNames\"],[\"views__footer w-100\"]],null],[1,\"\\n\"]],[1]]]]]],[\"view\",\"tabs\",\"header\",\"route\"],false,[\"ui-content-views\",\"t\",\"ui-routable-tabs\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-account/templates/instances/instance/corporate-design.hbs",
    "isStrictMode": false
  });
});