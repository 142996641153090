define("additive-account/components/aa-corporate-design/typography", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    fonts: null,
    typography: null,
    colors: null,
    isReadOnly: false,
    onSave() {},
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'typographyTypes', ['h1', 'h2', 'h3', 'p', 'small', 'button', 'label']);
    }
  });
});