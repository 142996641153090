define("additive-account/routes/instances/instance/users", ["exports", "@ember/routing/route", "@additive-apps/auth/mixins/access-guard"], function (_exports, _route, _accessGuard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_accessGuard.default, {
    routeIfUnauthorized: 'instances.instance',
    requiredRole: 'member'
  });
});