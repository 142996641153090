define("additive-account/controllers/auth/login", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/service", "@ember/object", "fetch", "ember-concurrency", "ember-changeset", "additive-account/validations/auth", "ember-changeset-validations"], function (_exports, _environment, _controller, _service, _object, _fetch, _emberConcurrency, _emberChangeset, _auth, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    session: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    queryParams: ['errors'],
    errors: null,
    loginChangeset: null,
    resetPasswordChangeset: null,
    loginFormSubmitUrl: `${_environment.default.APP.apiBaseHost}/auth/login`,
    resetPasswordDialog() {
      (0, _object.setProperties)(this, {
        isResetPasswordSend: false,
        isForgotPasswordDialog: false,
        isResetting: false,
        resetPasswordChangeset: null
      });
    },
    /**
     * Requests an email in order to reset a given emails password.
     *
     * The request behaves as successfull in either case, success or not.
     * Reason for this is to avoid show unknown user if the entered email
     * exists or not.
     */
    resetPassword: (0, _emberConcurrency.task)(function* () {
      try {
        const changeset = this.resetPasswordChangeset;
        yield changeset.validate();

        // show form errors
        (0, _object.set)(this, 'isResetPasswordTouched', true);
        if (changeset.isInvalid) {
          return;
        }
        const response = yield (0, _fetch.default)(`${_environment.default.APP.apiBaseHost}/auth/password/email`, {
          method: 'POST',
          body: JSON.stringify({
            email: changeset.get('email')
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
          }
        });
        if (!response.ok) {
          if (response.status === 422) {
            changeset.addError('email', this.intl.t('global.errors.apiQueryErrors.validation.email'));
            return;
          }
          throw new Error();
        }

        // show success message inside dialog
        (0, _object.set)(this, 'isResetPasswordSend', true);
      } catch (error) {
        this.uiDialog.showError();
      }
    }).drop(),
    actions: {
      openForgotPasswordDialog() {
        const validation = (0, _auth.emailValidation)(this.intl);
        const changeset = new _emberChangeset.default({
          email: ''
        }, (0, _emberChangesetValidations.default)(validation), validation);
        (0, _object.set)(this, 'resetPasswordChangeset', changeset);
        (0, _object.set)(this, 'isForgotPasswordDialog', true);
      },
      resetPasswordDialog() {
        this.resetPasswordDialog();
      }
    }
  });
});