define("additive-account/routes/instances/instance/users/list/user", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model(params) {
      (0, _object.set)(this, 'userId', params.user_id);
    },
    setupController(controller) {
      this._super(...arguments);
      const userId = this.userId;
      controller.getUser.perform(userId);
    }
  });
});