define("additive-account/controllers/auth/login/reset", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "ember-changeset", "additive-account/validations/auth", "ember-changeset-validations"], function (_exports, _environment, _controller, _object, _emberChangeset, _auth, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    queryParams: ['token', 'reset-errors', 'update_password'],
    errors: null,
    token: null,
    update_password: null,
    init() {
      this._super(...arguments);
      this.changeset = new _emberChangeset.default({
        email: '',
        password: '',
        passwordConfirmation: '',
        token: ''
      }, (0, _emberChangesetValidations.default)(_auth.resetPasswordValidation), _auth.resetPasswordValidation);
      (0, _object.set)(this, 'resetFormSubmitUrl', `${_environment.default.APP.apiBaseHost}/auth/password/reset`);
    },
    actions: {
      sendReset() {
        return this.changeset.validate().then(() => {
          if (this.changeset.isValid) {
            (0, _object.set)(this, 'isSubmitting', true);
            // We have to simulate a submit-button click to get onSubmit events fired properly
            // currently only used in tests, but to be correct, we should do it so to fire onSubmit events
            document.getElementById('reset-form-submit').click();
          }
          (0, _object.set)(this, 'isFormTouched', true);
        });
      },
      closePasswordReset() {
        this.transitionToRoute('auth.login', {
          queryParams: {
            errors: null
          }
        });
      }
    }
  });
});