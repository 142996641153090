define("additive-account/mirage/scenarios/partner-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * User is partner admin.
   * Organization is an sub-org of the partner-org
   * 2 existing org-apps:
   *    - Voucher activated with plan 'starter_yearly'
   *    - Swall activated with plan 'starter_yearly'
   *
   */
  function _default(server) {
    server.get('/api/me', () => {
      return {
        user: {
          id: '619855fe-a4c2-453a-8a17-8fcc963a46a4',
          givenName: 'Partner',
          familyName: 'Admin',
          fullName: 'Partner Admin',
          email: 'partner.admin@additive.eu',
          emailVerified: true,
          organizationCount: 2,
          isConfirmedAuth: false,
          isSupport: false,
          isSuperAdmin: false,
          partnerSlug: 'partner-org',
          partnerRole: 'admin'
        }
      };
    });
    server.get('/api/organizations/1/me', () => {
      return {
        user: {
          id: '619855fe-a4c2-453a-8a17-8fcc963a46a4',
          email: 'partner.admin@additive.eu',
          fullName: 'Partner Admin',
          isSupport: false,
          isSuperAdmin: false,
          role: 'admin',
          appRoles: []
        }
      };
    });
    server.get('/api/organizations/1', () => {
      return {
        organization: {
          id: 1,
          name: 'Partner sub-Org',
          email: 'info@sub-partner.org',
          website: 'http://www.sub-partner.org',
          partnerSlug: 'partner-org'
        }
      };
    });
    server.get('/api/organizations/1/apps', () => {
      return {
        apps: [{
          id: 2,
          name: 'voucher',
          planName: 'starter_yearly',
          active: true
        }, {
          id: 3,
          name: 'social-wall',
          planName: 'starter_yearly',
          active: true
        }]
      };
    });
  }
});