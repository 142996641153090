define("additive-account/mirage/factories/setting-salutation", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const subValueDefaults = () => {
    const languages = ['de', 'en', 'it', 'fr', 'nl'];
    return {
      availableLanguages: languages,
      language: _faker.default.random.arrayElement(languages)
    };
  };
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    prefer: () => _faker.default.random.arrayElement(['fallback', 'family', 'formal', 'informal']),
    fallback: () => Object.assign(subValueDefaults(), {
      value: _faker.default.lorem.words()
    }),
    family: () => Object.assign(subValueDefaults(), {
      value: _faker.default.lorem.words()
    }),
    informal: () => Object.assign(subValueDefaults(), {
      f: _faker.default.lorem.words(),
      fm: _faker.default.lorem.words()
    }),
    formal: () => Object.assign(subValueDefaults(), {
      f: _faker.default.lorem.words(),
      fm: _faker.default.lorem.words()
    })
  });
});