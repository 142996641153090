define("additive-account/templates/components/ui-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash input=(component "ui-input" class="w-100") select=(component "ui-select" class="w-100"))
    this.changeset
  }}
  */
  {
    "id": "FnQQnv7L",
    "block": "[[[18,1,[[28,[37,1],null,[[\"input\",\"select\"],[[50,\"ui-input\",0,null,[[\"class\"],[\"w-100\"]]],[50,\"ui-select\",0,null,[[\"class\"],[\"w-100\"]]]]]],[30,0,[\"changeset\"]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "additive-account/templates/components/ui-form.hbs",
    "isStrictMode": false
  });
});