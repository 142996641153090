define("additive-account/components/aa-settings/closing-periods/detail", ["exports", "@ember/component", "ember-concurrency", "@ember/service", "@ember/object", "ember-changeset", "ember-changeset-validations", "additive-account/validations/settings"], function (_exports, _component, _emberConcurrency, _service, _object, _emberChangeset, _emberChangesetValidations, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Edit and Create dialog for closing period settings
   *
   * @module aa-settings/closing-periods
   * @class detail
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    store: (0, _service.inject)(),
    /**
     * the changeset for the closingPeriod model
     *
     * @property changeset
     * @type {Changeset}
     * @default null
     */
    changeset: null,
    /**
     * the closingPeriod object
     *
     * @property closingPeriod
     * @type {Model}
     * @default null
     */
    closingPeriod: null,
    /**
     * the task to re-fetch the models after saving
     *
     * @property fetchclosingPeriods
     * @type {Task}
     * @default undefined
     */
    fetchClosingPeriods: undefined,
    /**
     * whether the discard changes dialog is open
     *
     * @property isDiscardChangesDialog
     * @type {Boolean}
     * @default false
     */
    isDiscardChangesDialog: false,
    /**
     * the initial selected daterange
     *
     * @property _dateRange
     * @type {Object}
     * @default null
     * @private
     */
    _dateRange: null,
    init() {
      this._super(...arguments);
      let closingPeriod = this.closingPeriod;
      if (!closingPeriod) {
        closingPeriod = this.store.createRecord('closing-time');
      } else {
        const startDate = new Date(closingPeriod.from);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(closingPeriod.until);
        endDate.setHours(0, 0, 0, 0);
        (0, _object.set)(this, '_dateRange', {
          start: startDate,
          end: endDate
        });
      }
      const validation = (0, _settings.closingPeriodValidation)(this.intl);
      const changeset = new _emberChangeset.default(closingPeriod, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.setProperties)(this, {
        changeset,
        closingPeriod
      });
    },
    /**
     * saves room count
     *
     * @function save
     * @type {Task}
     */
    save: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.set)(this, 'isTouched', true);
        yield this.changeset.validate();
        if (this.changeset.get('isInvalid')) {
          return;
        }
        yield this.changeset.save();
        this.uiToast.showToast({
          title: this.intl.t('global.toasts.success.changes.title'),
          description: this.intl.t('global.toasts.success.changes.description'),
          type: 'success'
        });
        this.fetchClosingPeriods.perform();
        this.onClose();
      } catch (e) {
        // display error if period overlaps with another closing period
        if (e.errors && e.errors[0] && e.errors[0].detail === 'overlaps_another') {
          this.changeset.pushErrors('until', this.intl.t('routes.settings.closingPeriods.overlapError'));
        } else {
          this.uiToast.showToast({
            title: this.intl.t('global.toasts.delete.error'),
            description: this.intl.t('global.toasts.error.description'),
            type: 'error'
          });
        }
      }
    }),
    onClose() {},
    actions: {
      close() {
        if (this.changeset.get('isPristine')) {
          if (this.closingPeriod.isNew) {
            this.closingPeriod.destroyRecord();
          }
          return this.onClose();
        }
        (0, _object.set)(this, 'isDiscardChangesDialog', true);
      },
      discardChanges() {
        this.changeset && this.changeset.rollback();
        if (this.closingPeriod.isNew) {
          this.closingPeriod.destroyRecord();
        }
        this.onClose();
      },
      onDateRangeChange(start, end) {
        if (start && end) {
          const {
            changeset
          } = this;
          changeset.set('from', start);
          changeset.set('until', end);
        }
      }
    }
  });
});