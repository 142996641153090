define("additive-account/templates/components/simple-nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  {{yield
    (hash
      trigger=(component
        "simple-nav/trigger" currentIndex=this.currentIndex updateIndex=(action "updateIndex")
      )
      content=(component "simple-nav/content" currentIndex=this.currentIndex)
    )
  }}
  */
  {
    "id": "laDO9K8p",
    "block": "[[[18,1,[[28,[37,1],null,[[\"trigger\",\"content\"],[[50,\"simple-nav/trigger\",0,null,[[\"currentIndex\",\"updateIndex\"],[[30,0,[\"currentIndex\"]],[28,[37,3],[[30,0],\"updateIndex\"],null]]]],[50,\"simple-nav/content\",0,null,[[\"currentIndex\"],[[30,0,[\"currentIndex\"]]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "additive-account/templates/components/simple-nav.hbs",
    "isStrictMode": false
  });
});