define("additive-account/routes/instances/instance/corporate-design/index", ["exports", "@ember/routing/route", "ember-changeset", "ember-changeset-validations", "additive-account/validations/corporate-design", "@ember/object", "@ember/object/computed", "@ember/service", "additive-account/utils/organization-utils"], function (_exports, _route, _emberChangeset, _emberChangesetValidations, _corporateDesign, _object, _computed, _service, _organizationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    resetController(controller) {
      (0, _object.set)(controller, 'colors', null);
    },
    setupController(controller) {
      this._super(...arguments);
      const fetchCorporateDesignTask = _organizationUtils.fetchCorporateDesign.task;
      (0, _object.set)(controller, 'fetchCorporateDesignTask', fetchCorporateDesignTask);
      fetchCorporateDesignTask.perform(this).then(corporateDesign => {
        const colors = corporateDesign.colors;
        (0, _object.set)(controller, 'colors', colors);
        const validations = (0, _corporateDesign.defaultValidations)(this.intl);
        const changesetMain = new _emberChangeset.default(colors.main, (0, _emberChangesetValidations.default)(validations), validations);
        (0, _object.set)(controller, 'changesetMain', changesetMain);
        const changesetAccent = new _emberChangeset.default(colors.accent, (0, _emberChangesetValidations.default)(validations), validations);
        (0, _object.set)(controller, 'changesetAccent', changesetAccent);
        const changesetAmbient = new _emberChangeset.default(colors.ambient, (0, _emberChangesetValidations.default)(validations), validations);
        (0, _object.set)(controller, 'changesetAmbient', changesetAmbient);
      });
    }
  });
});