define("additive-account/helpers/format-date-month", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDateMonth = formatDateMonth;
  /**
   * Helper to format a date into the format "month-name YYYY". The month name is returned
   * in the language defined as the locale param.
   *
   * @export
   * @param {Date} date
   * @param {String} locale
   * @returns {String}
   */
  function formatDateMonth(_ref) {
    let [date, locale] = _ref;
    const objDate = new Date(date);
    const month = objDate.toLocaleString(locale, {
      month: 'long',
      year: 'numeric'
    });
    return month;
  }
  var _default = _exports.default = (0, _helper.helper)(formatDateMonth);
});