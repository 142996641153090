define("additive-account/routes/auth/signup", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "additive-account/validations/auth"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    beforeModel(transition) {
      var _transition$to;
      this._super(...arguments);

      // Redirect to login page if there is no token
      if (!(transition !== null && transition !== void 0 && (_transition$to = transition.to) !== null && _transition$to !== void 0 && (_transition$to = _transition$to.queryParams) !== null && _transition$to !== void 0 && _transition$to.token)) {
        this.router.transitionTo('auth.login');
      }
    },
    setupController(controller) {
      this._super(...arguments);
      const {
        token
      } = controller;
      // Check if the token is set
      if (!token) {
        return;
      }
      (0, _object.set)(controller, 'isInvite', true);
      controller.checkInvite.perform(token).catch((/* error */
      ) => {
        // TODO handle different, possible error-cases
        // 1. token_already_accepted status: 400
        // 2. Invalid token 404
        // 3. unexpected api-errors ()
        // reset invalid token
        (0, _object.set)(controller, 'token', null);
      });
      const validation = (0, _auth.signupValidation)(this.intl);
      const changeset = new _emberChangeset.default({
        email: '',
        givenName: '',
        familyName: '',
        password: '',
        passwordConfirmation: '',
        isPrivacyAccepted: false
      }, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.set)(controller, 'changeset', changeset);
    },
    resetController(controller, isExiting, transition) {
      if (isExiting && transition && transition.targetName === 'auth.login.index') {
        const changeset = controller.changeset;
        changeset && changeset.rollback();
      }
    }
  });
});