define("additive-account/routes/instances/me/index", ["exports", "@ember/routing/route", "@ember/object", "additive-account/utils/user-management", "@ember/service", "rsvp"], function (_exports, _route, _object, _userManagement, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    // fetch used inside task
    authenticatedFetch: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    // unload all apps when user is on me route
    beforeModel() {
      this.store.unloadAll('apps');
    },
    setupController(controller, model) {
      this._super(controller, model);
      const {
        token
      } = controller;
      if (token) {
        _userManagement.confirmInvitation.task.perform(`${token}`, this).then(status => {
          if (status === 204) {
            this.uiToast.showToast({
              type: 'success',
              title: this.intl.t('global.toasts.success.invite.title'),
              duration: 2000
            });
            (0, _object.set)(controller, 'token', null);
            return (0, _rsvp.resolve)();
          } else if (status >= 400) {
            // expected error codes are 400 and 404 for wrong or used token
            // TODO: Use dialog-service and remove dialog from template
            (0, _object.set)(controller, 'isInvalidTokenDialog', true);
          }
        })
        // 401: unauthorized
        .catch(() => (0, _object.set)(controller, 'isInvalidTokenDialog', true));
      }
    }
  });
});