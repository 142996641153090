define("additive-account/mirage/endpoints/templates/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.list = void 0;
  const list = _exports.list = function list(server) {
    server.get('/api/organizations/*/templates', schema => {
      return schema.templates.all();
    });
  };
});