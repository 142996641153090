define("additive-account/mirage/endpoints/templates/index", ["exports", "additive-account/mirage/endpoints/templates/list", "additive-account/mirage/endpoints/templates/detail"], function (_exports, _list, _detail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "detail", {
    enumerable: true,
    get: function get() {
      return _detail.detail;
    }
  });
  Object.defineProperty(_exports, "list", {
    enumerable: true,
    get: function get() {
      return _list.list;
    }
  });
});