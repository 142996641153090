define("additive-account/components/aa-language-switch", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "additive-account/utils/constants", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _constants, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiSelect
    @class="ui-lang-select"
    @options={{this.options}}
    @selected={{this._selectedLocale}}
    @onChange={{this.onChange}}
    @customOffset={{4}}
    @theme="dark"
    data-test-language-switch
    as |option language|
  >
    <option.item @classNames="ui-lang-option" @value={{language.value}}>
      {{language.name}}
    </option.item>
  </UiSelect>
  */
  {
    "id": "GfE8lOGg",
    "block": "[[[8,[39,0],[[24,\"data-test-language-switch\",\"\"]],[[\"@class\",\"@options\",\"@selected\",\"@onChange\",\"@customOffset\",\"@theme\"],[\"ui-lang-select\",[30,0,[\"options\"]],[30,0,[\"_selectedLocale\"]],[30,0,[\"onChange\"]],4,\"dark\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@classNames\",\"@value\"],[\"ui-lang-option\",[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"name\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]]],[\"option\",\"language\"],false,[\"ui-select\"]]",
    "moduleName": "additive-account/components/aa-language-switch.hbs",
    "isStrictMode": false
  });

  /**
   * Language switch for unauthenticated Routes
   *
   * @class AaLanguageSwitchComponent
   */
  let AaLanguageSwitchComponent = _exports.default = (_class = class AaLanguageSwitchComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor2, this);
    }
    /**
     * The language options, for the select.
     *
     * The computed key `locale` must be set in order to re-render the options in the new locale
     * after changing the locale.
     *
     * @computed options
     */
    get options() {
      return _constants.APP_LANGUAGES.map(lang => ({
        name: this.intl.t(`components.aaLanguageSwitch.${lang}`, {
          locale: this.uiLocale.locale
        }),
        value: lang
      }));
    }
    get _selectedLocale() {
      return this.intl.t(`components.aaLanguageSwitch.${this.uiLocale.locale}`);
    }

    /**
     * sets the selected locale
     *
     * @function onChange
     * @param {String} locale
     */
    onChange(locale) {
      if (window && window.localStorage) {
        localStorage.setItem('locale', locale);
      }
      this.uiLocale.setLocale(locale);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaLanguageSwitchComponent);
});