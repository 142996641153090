define("additive-account/helpers/ui-to-string", ["exports", "@additive-apps/ui/helpers/ui-to-string"], function (_exports, _uiToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiToString.default;
    }
  });
  Object.defineProperty(_exports, "uiToString", {
    enumerable: true,
    get: function get() {
      return _uiToString.uiToString;
    }
  });
});