define("additive-account/components/model-form", ["exports", "@ember/component", "@ember/object", "ember-changeset", "ember-changeset-validations"], function (_exports, _component, _object, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    isDiscardChangesDialog: false,
    onConfirm() {},
    onClose() {},
    init() {
      this._super(...arguments);
      const model = this.model;
      const validation = this.validation;
      const changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.set)(this, 'changeset', changeset);
    },
    willDestroyElement() {
      this._super(...arguments);
      // clean up changeset to avoid memleaks
      (0, _object.set)(this, 'changeset', null);
    },
    actions: {
      onConfirm(changeset) {
        return changeset.validate().then(() => {
          if (changeset.get('isValid')) {
            return this.onConfirm(changeset);
          } else {
            return;
          }
        });
      },
      onClose() {
        const changeset = this.changeset;

        // close the profile view if no changes have been made
        if (changeset.get('isPristine')) {
          this.onClose();
        } else {
          (0, _object.set)(this, 'isDiscardChangesDialog', true);
        }
      },
      confirmDiscard() {
        (0, _object.set)(this, 'isDiscardChangesDialog', false);
        this.onClose();
      }
    }
  });
});