define("additive-account/controllers/instances/instance/billings", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "@additive-apps/ui/utils/dom-util", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations", "ember-concurrency"], function (_exports, _environment, _controller, _object, _utils, _service, _domUtil, _emberChangeset, _validators, _emberChangesetValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    stripe: (0, _service.inject)('stripev3'),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * Defines the header of the table containing the invoices.
     *
     * @type {Object[]}
     * @default undefined
     */
    invoiceTableOptions: undefined,
    organizationId: _object.computed.alias('currentUser.currentOrganization.id'),
    billing: _object.computed.alias('model.billing'),
    invoices: _object.computed.alias('model.invoices'),
    subscription: _object.computed.alias('model.subscription'),
    hasInvoices: _object.computed.notEmpty('model.invoices'),
    isLoading: _object.computed.alias('billingTask.isRunning'),
    isSubscriptionProcessing: _object.computed.alias('updateSubscriptionData.isRunning'),
    isUpdatingBillingData: _object.computed.alias('updateBillingData.isRunning'),
    locale: _object.computed.alias('intl.locale'),
    // check if no billing property is empty
    hasBillingData: (0, _object.computed)('billing.{legalName,addressCountry,addressLocality,addressPostalCode,addressStreet,addressRegion,vatId}', {
      get() {
        const properties = this.billing;
        let hasEmptyProperties = false;
        Object.keys(properties).forEach(key => {
          if ((0, _utils.isEmpty)(properties[key])) {
            hasEmptyProperties = true;
          }
        });
        return !hasEmptyProperties;
      }
    }).readOnly(),
    // check if no subscription property is empty
    hasSubscriptionData: (0, _object.computed)('subscription.{card_last_four,card_exp_month,card_exp_year,card_name}', {
      get() {
        const properties = this.subscription;
        let hasEmptyProperties = false;
        Object.keys(properties).forEach(key => {
          if ((0, _utils.isEmpty)(properties[key])) {
            hasEmptyProperties = true;
          }
        });
        return !hasEmptyProperties;
      }
    }).readOnly(),
    init() {
      this._super(...arguments);
      const intl = this.intl;
      const presenceOptions = {
        presence: true,
        message: intl.t('global.errors.apiQueryErrors.validation.required')
      };
      const billingValidation = {
        legalName: (0, _validators.validatePresence)(presenceOptions),
        addressStreet: (0, _validators.validatePresence)(presenceOptions),
        addressPostalCode: (0, _validators.validatePresence)(presenceOptions),
        addressLocality: (0, _validators.validatePresence)(presenceOptions),
        addressRegion: (0, _validators.validatePresence)(presenceOptions),
        addressCountry: (0, _validators.validatePresence)(presenceOptions),
        vatId: (0, _validators.validatePresence)(presenceOptions)
      };
      (0, _object.set)(this, 'billingValidation', billingValidation);
      const subscriptionValidation = {
        cardName: (0, _validators.validatePresence)(presenceOptions)
      };
      (0, _object.set)(this, 'subscriptionValidation', subscriptionValidation);
      (0, _object.set)(this, 'invoiceTableOptions', [{
        name: intl.t('billings.invoiceData.date')
      }, {
        name: intl.t('billings.invoiceData.amount'),
        minWidth: '80px'
      }, {
        name: intl.t('billings.invoiceData.state')
      }]);
    },
    // handle billing data submit
    updateBillingData: (0, _emberConcurrency.task)(function* () {
      const billingEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/billing`;
      const changeset = this.billingChangeset;
      yield changeset.validate();
      if (changeset.get('isValid')) {
        const intl = this.intl;
        // show loading icon
        const request = this.authenticatedFetch.fetch(billingEndpoint, {
          method: 'PUT'
        }, {
          legalName: changeset.get('legalName'),
          addressStreet: changeset.get('addressStreet'),
          addressPostalCode: changeset.get('addressPostalCode'),
          addressLocality: changeset.get('addressLocality'),
          addressRegion: changeset.get('addressRegion'),
          addressCountry: changeset.get('addressCountry'),
          vatId: changeset.get('vatId')
        });
        const response = yield request;
        const {
          status
        } = response;
        if (status === 200) {
          changeset.save();
          (0, _object.set)(this, 'billingChangeset', null);
          (0, _object.set)(this, 'isBillingDataDialog', false);
          if (!this.isBillingEdit) {
            this.send('openSubscriptionDialog');
          }
          // show toast on success
          this.uiToast.showToast({
            type: 'success',
            title: intl.t('global.toasts.success.changes.title'),
            description: intl.t('global.toasts.success.changes.description'),
            duration: 2000
          });
        } else if (status === 422) {
          const json = yield response.json();
          // if api returns invalid vat id error show error at vat id input
          if ((0, _utils.isPresent)(json.errors) && (0, _utils.isPresent)(json.errors.vatId)) {
            const errorMessage = intl.t('global.errors.invalidVatId');
            changeset.addError('vatId', errorMessage);
          }
        } else {
          // show unexpected error dialog on all other errors
          (0, _object.set)(this, 'billingChangeset', null);
          (0, _object.set)(this, 'isBillingDataDialog', false);
          (0, _object.set)(this, 'isUnexpectedBillingError', true);
        }
      }
      (0, _object.set)(this, 'isBillingFormTouched', true);
    }),
    // handle subscription data submit
    updateSubscriptionData: (0, _emberConcurrency.task)(function* () {
      const changeset = this.subscriptionChangeset;
      const subscribeEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/subscribe`;
      yield changeset.validate();
      if (changeset.get('isValid')) {
        this.send('clearStripeErrors');
        // show loading icon
        if ((0, _utils.isPresent)(this.stripeCardNumber)) {
          const intl = this.intl;

          /**
           * send credit card data and retrieve token from stripe
           * only one stripe element needs to be provided, e.g. the card number
           * the other stripe elements, i.e. the expiry and cvc are then found automatically
           */
          const result = yield this.stripe.createToken(this.stripeCardNumber, {
            name: changeset.get('cardName')
          });
          if ((0, _utils.isPresent)(result.token)) {
            // send stripe token to api
            const request = this.authenticatedFetch.fetch(subscribeEndpoint, {
              method: 'POST'
            }, {
              token: result.token.id
            });
            const response = yield request;
            const {
              status
            } = response;
            if (status !== 200) {
              const json = yield response.json();
              const message = json.message;
              if (message) {
                switch (message) {
                  case 'card_declined':
                    (0, _object.set)(this, 'stripeCardNumberError', intl.t('global.errors.creditCardUnsupported'));
                    document.getElementById('stripe-card-number').classList += ' StripeElement--invalid';
                    break;
                  case 'incorrect_cvc':
                    (0, _object.set)(this, 'stripeCardCvcError', intl.t('global.errors.incorrectCvc'));
                    document.getElementById('stripe-card-cvc').classList += ' StripeElement--invalid';
                    break;
                  case 'processing_error':
                    this.uiDialog.showAlert(intl.t('global.errors.creditCardProcessingError.title'), intl.t('global.errors.creditCardProcessingError.text'));
                    break;
                  default:
                    (0, _object.set)(this, 'isUnexpectedBillingError', true);
                }
              } else {
                (0, _object.set)(this, 'isUnexpectedBillingError', true);
              }
            } else {
              const json = yield response.json();
              (0, _object.set)(this, 'subscription', json.subscription);
              (0, _object.set)(this, 'isSubscriptionDataDialog', false);

              // show success toast
              this.uiToast.showToast({
                type: 'success',
                title: intl.t('global.toasts.success.changes.title'),
                description: intl.t('global.toasts.success.changes.description'),
                duration: 2000
              });
            }
          } else {
            // stripe error handling
            if (result.error.type == 'card_error' && result.error.code == 'card_declined') {
              // if credit card is not supported show error at credit card number input
              const errorMessage = intl.t('global.errors.creditCardUnsupported');
              (0, _object.set)(this, 'stripeCardNumberError', errorMessage);
              document.getElementById('stripe-card-number').classList += ' StripeElement--invalid';
            } else if (result.error.type != 'validation_error') {
              // on all other stripe errors show unexpected error message
              (0, _object.set)(this, 'isUnexpectedBillingError', true);
            }
          }
        }
      }
      (0, _object.set)(this, 'isSubscriptionFormTouched', true);
    }),
    actions: {
      onCloseBillingData() {
        const changeset = this.billingChangeset;

        // close billing data dialog if no changes have been made
        if (changeset.get('isPristine')) {
          (0, _object.set)(this, 'isBillingDataDialog', false);
          (0, _object.set)(this, 'billingChangeset', null);
        } else {
          const intl = this.intl;
          const callback = () => {
            this.send('confirmDiscardBillingData');
          };

          // show discard changes dialog
          this.uiDialog.showConfirm(intl.t('global.dialogs.discardChanges.title'), intl.t('global.dialogs.discardChanges.description'), callback);
        }
      },
      confirmDiscardBillingData() {
        (0, _object.set)(this, 'billingChangeset', null);
        (0, _object.set)(this, 'isBillingDataDialog', false);
      },
      openBillingDialog(isEdit) {
        const billing = this.billing;
        const billingValidation = this.billingValidation;
        const changeset = new _emberChangeset.default(billing, (0, _emberChangesetValidations.default)(billingValidation), billingValidation);
        (0, _object.set)(this, 'billingChangeset', changeset);
        (0, _object.set)(this, 'isBillingDataDialog', true);
        (0, _object.set)(this, 'isBillingEdit', isEdit);
      },
      clearStripeErrors() {
        (0, _object.set)(this, 'stripeCardNumberError', '');
        (0, _object.set)(this, 'stripeCardExpiryError', '');
        (0, _object.set)(this, 'stripeCardCvcError', '');
      },
      onCloseSubscriptionData() {
        const intl = this.intl;
        if (this.isSubscriptionProcessing) {
          // show warning dialog if subscription data is being processed
          this.uiDialog.showConfirm(intl.t('global.dialogs.subscriptionProcessing.title'), intl.t('global.dialogs.subscriptionProcessing.description'), () => {});
        } else {
          const changeset = this.subscriptionChangeset;
          /**
           * show discard changes dialog if changes were made
           * otherwise close subscription dialog
           */
          if (changeset.get('isDirty') || this.isStripeCardNumberFilled || this.isStripeCardExpiryFilled || this.isStripeCardCvcFilled) {
            const callback = () => {
              this.send('confirmDiscardSubscriptionData');
            };

            // show discard changes dialog
            this.uiDialog.showConfirm(intl.t('global.dialogs.discardChanges.title'), intl.t('global.dialogs.discardChanges.description'), callback);
          } else {
            this.send('clearStripeErrors');
            (0, _object.set)(this, 'isSubscriptionDataDialog', false);
          }
        }
      },
      confirmDiscardSubscriptionData() {
        (0, _object.set)(this, 'subscriptionChangeset', null);
        (0, _object.set)(this, 'isSubscriptionDataDialog', false);
        this.send('clearStripeErrors');
      },
      openSubscriptionDialog() {
        const subscription = this.subscription;
        const subscriptionValidation = this.subscriptionValidation;
        const changeset = new _emberChangeset.default(subscription, (0, _emberChangesetValidations.default)(subscriptionValidation), subscriptionValidation);
        (0, _object.set)(this, 'subscriptionChangeset', changeset);
        (0, _object.set)(this, 'isSubscriptionDataDialog', true);
        (0, _object.set)(this, 'isStripeCardNumberFilled', false);
        (0, _object.set)(this, 'isStripeCardExpiryFilled', false);
        (0, _object.set)(this, 'isStripeCardCvcFilled', false);
        (0, _object.set)(this, 'isStripeLoading', true);
        this.stripe.load().then(() => {
          (0, _object.set)(this, 'isStripeLoading', false);
          /**
           * wait for the subscription dialog to appear
           * elements in the dialog are referenced by id and therefore need to exist before continuing
           */
          (0, _domUtil.nextTick)().then(() => {
            if (!this.isDestroyed) {
              const locale = this.locale && this.locale[0] || 'de';
              const elements = this.stripe.elements({
                locale
              });
              const stripeInputStyle = {
                base: {
                  lineHeight: '40px'
                }
              };

              // setup card number input field
              const cardNumber = elements.create('cardNumber', {
                style: stripeInputStyle
              });
              cardNumber.mount('#stripe-card-number');
              cardNumber.addEventListener('change', event => {
                (0, _object.set)(this, 'isStripeCardNumberFilled', !event.empty);
                (0, _object.set)(this, 'stripeCardNumberError', event.error ? event.error.message : '');
              });
              (0, _object.set)(this, 'stripeCardNumber', cardNumber);

              // setup card expiry input field
              const cardExpiry = elements.create('cardExpiry', {
                style: stripeInputStyle
              });
              cardExpiry.mount('#stripe-card-expiry');
              cardExpiry.addEventListener('change', event => {
                (0, _object.set)(this, 'isStripeCardExpiryFilled', !event.empty);
                (0, _object.set)(this, 'stripeCardExpiryError', event.error ? event.error.message : '');
              });
              (0, _object.set)(this, 'stripeCardExpiry', cardExpiry);

              // setup card cvc input field
              const cardCvc = elements.create('cardCvc', {
                style: stripeInputStyle
              });
              cardCvc.mount('#stripe-card-cvc');
              cardCvc.addEventListener('change', event => {
                (0, _object.set)(this, 'isStripeCardCvcFilled', !event.empty);
                (0, _object.set)(this, 'stripeCardCvcError', event.error ? event.error.message : '');
              });
              (0, _object.set)(this, 'stripeCardCvc', cardCvc);
            }
          });
        });
      }
    }
  });
});