define("additive-account/controllers/instances/instance/settings/legals", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "rsvp", "additive-account/validations/organization"], function (_exports, _environment, _controller, _array, _object, _computed, _service, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _rsvp, _organization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    store: (0, _service.inject)(),
    /**
     * the changeset of the legal resource
     *
     * @property _changeset
     * @type {Object}
     * @default undefined
     */
    _changeset: undefined,
    /**
     * object containing the legal links of the organization
     *
     * @property _legalResource
     * @type {Object}
     * @default undefined
     */
    _legalResource: undefined,
    /**
     * the fetched legals of the organization
     *
     * @property _legals
     * @type {Object}
     * @default undefined
     */
    _legals: undefined,
    /**
     * the fetched settings of the organization
     *
     * @property _settings
     * @type {Object}
     * @default undefined
     */
    _settings: undefined,
    availableLanguages: (0, _computed.alias)('_settings.contentLanguages'),
    completedLanguages: (0, _computed.alias)('_legals.completedLanguages'),
    currentLanguage: (0, _computed.or)('_legals.language', '_settings.defaultLanguage'),
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    isReadOnly: (0, _computed.not)('currentUser.isAdmin'),
    isLoading: (0, _computed.or)('fetchLanguages.isRunning', 'fetchLegals.isRunning'),
    /**
     * fetches the legals of the organization in a given locale
     *
     * @type {Task}
     * @function fetchLegals
     * @returns {Void}
     */
    fetchLegals: (0, _emberConcurrency.task)(function* (locale) {
      const url = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/legals`;
      try {
        const response = yield this.authenticatedFetch.fetch(url, {
          headers: {
            'Accept-Language': locale || this.currentLanguage || 'de'
          }
        });
        if (!response || !response.ok) {
          return (0, _rsvp.reject)();
        }

        // destructure response
        const data = yield response.json();
        if (!data || !data.legal) {
          return (0, _rsvp.reject)();
        }
        (0, _object.set)(this, '_legals', data.legal);
        const {
          disclaimerLink,
          privacyLink,
          termsLink
        } = data.legal;
        const legalResource = {
          disclaimerLink,
          privacyLink,
          termsLink
        };
        (0, _object.set)(this, '_legalResource', legalResource);
        const legalsValidation = (0, _organization.organizationLegals)(this.intl);
        const changeset = new _emberChangeset.default(legalResource, (0, _emberChangesetValidations.default)(legalsValidation), legalsValidation);
        (0, _object.set)(this, '_changeset', changeset);
      } catch (error) {
        throw new Error(`[organization-legals] fetch legals error ${error}`);
      }
    }).drop(),
    /**
     * Updates the legals
     *
     * @type {Task}
     * @function updateLegals
     */
    updateLegals: (0, _emberConcurrency.task)(function* (changeset) {
      const url = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/legals`;
      try {
        yield changeset.validate();
        if (changeset.get('isValid')) {
          let tasks = [];
          const disclaimerLink = changeset.get('disclaimerLink');
          const privacyLink = changeset.get('privacyLink');
          const termsLink = changeset.get('termsLink');
          const legalsPayload = {
            disclaimerLink,
            privacyLink,
            termsLink
          };
          const request = yield this.authenticatedFetch.fetch(url, {
            headers: {
              'Accept-Language': this.currentLanguage || 'de'
            },
            method: 'PUT',
            body: JSON.stringify(legalsPayload)
          });
          tasks.push(request);
          tasks.push((0, _emberConcurrency.timeout)(250));
          const [response] = yield (0, _emberConcurrency.all)(tasks);
          if (!response || !response.ok) {
            this.uiDialog.showError();
            return (0, _rsvp.reject)();
          }
          const json = yield response.json();
          if (json && json.legal && (0, _array.isArray)(json.legal.completedLanguages)) {
            (0, _object.set)(this._legals, 'completedLanguages', json.legal.completedLanguages);
          }
          yield changeset.save();
          this.uiToast.showToast({
            title: this.intl.t('global.toasts.success.changes.title'),
            description: this.intl.t('global.toasts.success.changes.description'),
            type: 'success'
          });
        }
      } catch (error) {
        throw new ErrorEvent('[SETTINGS] update legals', error);
      }
    }).drop(),
    actions: {
      changeLanguage(locale) {
        this.fetchLegals.perform(locale);
      },
      onClose(changeset) {
        if (changeset && changeset.isDirty) {
          this.uiDialog.showDiscardChangesConfirm(() => {
            changeset.rollback();
          });
          return (0, _rsvp.reject)();
        }
        return (0, _rsvp.resolve)();
      }
    }
  });
});