define("additive-account/routes/instances/instance/corporate-design/typography", ["exports", "@ember/object", "@ember/routing/route"], function (_exports, _object, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    resetController(controller) {
      (0, _object.set)(controller, 'colors', null);
    },
    setupController(controller) {
      this._super(...arguments);
      controller.fetchCD.perform();
    }
  });
});