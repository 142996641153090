define("additive-account/mirage/endpoints/organization-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.organizationRoutes = void 0;
  const organizationRoutes = _exports.organizationRoutes = function organizationRoutes(server) {
    server.get('/api/organizations', (schema, request) => {
      // If search-query exists return only one
      if (request.queryParams && request.queryParams.q) {
        return {
          organizations: [{
            id: 1,
            name: 'First',
            email: 'first@g.mail',
            website: 'http://www.first-comes-first.com',
            isPartner: false
          }]
        };
      }
      return {
        organizations: [{
          id: 1,
          name: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          isPartner: false
        }, {
          id: 2,
          name: 'Second',
          email: 'second@my.mail',
          website: 'http://www.google.de',
          isPartner: false
        }]
      };
    });
    server.post('/api/organizations', () => {
      return {
        organization: {
          id: 1,
          name: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          isPartner: false
        }
      };
    });
    server.get('/api/organizations/:id', () => {
      return {
        organization: {
          id: 1,
          name: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          isPartner: false
        }
      };
    });
    server.get('/api/organizations/:id/users', () => {
      return {
        users: [{
          id: 1,
          givenName: 'First',
          familyName: 'First',
          email: 'first@g.mail',
          inviteAccepted: true,
          active: true
        }, {
          id: 2,
          givenName: 'Second',
          familyName: 'Second',
          email: 'second@my.mail'
        }]
      };
    });
    server.get('/api/organizations/:id/subscription', () => {
      return {};
    });
    server.get('/api/organizations/:id/billing', () => {
      return {};
    });
    server.put('/api/organizations/:id/billing', () => {
      return {
        status: '200'
      };
    });
    server.get('/api/organizations/:id/invoices', () => {
      return {
        invoices: [{
          downloadUrl: 'https://pay.stripe.com/invoice/invst_y8t7XOnd4s3PzkRoIsUfaRT5o4/pdf',
          date: '2018-08-10',
          amount: '26,84 €',
          paid: false,
          closed: true,
          legalName: 'Test OHG'
        }]
      };
    });
    server.delete('/api/organizations/:id/users/:user_id', () => {
      return {
        status: '204'
      };
    });
    server.get('/api/organizations/:id/corporate-design', () => {
      return {
        corporateDesign: {
          typography: {},
          colors: {},
          logoUrl: null,
          alternativeLogoUrl: null
        }
      };
    });
    server.get('/api/organizations/:id/layout', () => {
      return {
        layout: {
          buttonBackgroundColor: '#007C32',
          buttonColor: '#ffffff',
          fontColor: '#978575'
        }
      };
    });
    server.put('/api/organizations/:id/layout', () => {
      return {
        status: '200'
      };
    });
    server.get('/api/organizations/:id/settings', () => {
      return {
        settings: {
          contentLanguages: ['de', 'it'],
          defaultLanguage: 'de'
        }
      };
    });
    server.get('/api/organizations/:id/email', () => {
      return '<html></html>';
    });
  };
});