define("additive-account/components/aa-settings/room-counts/list", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    /**
     * the room count list items
     *
     * @property items
     * @type {Array}
     * @default undefined
     */
    items: undefined,
    /**
     * the key of the room counts object
     *
     * @property key
     * @type {String}
     * @default null
     */
    key: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default false
     */
    isReadOnly: false,
    /**
     * the state of the list items
     *
     * @property state
     * @type {String}
     * @default null
     */
    state: null,
    /**
     * whether the list is loading
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     * whether there has been an error during fetching
     *
     * @property isError
     * @type {Boolean}
     * @default false
     */
    isError: false,
    /**
     * the task used to reload the roomCounts
     *
     * @property fetchRoomCounts
     * @type {Task}
     * @default undefined
     */
    fetchRoomCounts: undefined,
    /**
     * the list is only shown if it is loading, or there
     * are items in this list
     *
     * @computed _showList
     * @private
     */
    _showList: (0, _computed.or)('isLoading', 'items', 'isError'),
    onDelete() {},
    onClick() {}
  });
});