define("additive-account/validations/user", ["exports", "ember-changeset-validations/validators", "additive-account/utils/constants"], function (_exports, _validators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userValidation = void 0;
  const userValidation = intl => {
    const presenceMessage = intl.t('global.errors.apiQueryErrors.validation.required');
    const emailMessage = intl.t('global.errors.apiQueryErrors.validation.email');
    return {
      email: (0, _validators.validateFormat)({
        type: 'email',
        message: emailMessage
      }),
      givenName: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      familyName: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      language: [(0, _validators.validateInclusion)({
        list: _constants.APP_LANGUAGES
      }), (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })]
    };
  };
  _exports.userValidation = userValidation;
});