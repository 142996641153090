define("additive-account/components/aa-corporate-design/fonts/item/preview", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template", "ember-arg-types", "prop-types", "additive-account/utils/constants-fonts", "@ember/template-factory"], function (_exports, _component, _component2, _object, _template, _emberArgTypes, _propTypes, _constantsFonts, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="header absolute top-0 right-0 left-0 relative">
    <div class="absolute top-0 right-0 left-0 flex justify-between white pa2">
      <span class="font-regular font-xs">
        {{@font.fontFamily}}
      </span>
      {{#unless @isReadOnly}}
        <UiIconButton @icon="delete" @class="color-inherit" @onClick={{this._onDelete}} />
      {{/unless}}
    </div>
  </div>
  <div
    class="flex flex-grow-1 items-center justify-center font-preview pa05 white"
    style={{this.previewStyle}}
  >
    Aa
  </div>
  <div class="footer absolute bottom-0 left-0 right-0 flex items-end secondary pa2">
    {{svg-jar "text" width="16" height="16"}}
  </div>
  */
  {
    "id": "IhcKKtGO",
    "block": "[[[10,0],[14,0,\"header absolute top-0 right-0 left-0 relative\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"absolute top-0 right-0 left-0 flex justify-between white pa2\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"font-regular font-xs\"],[12],[1,\"\\n      \"],[1,[30,1,[\"fontFamily\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,2]],[[[1,\"      \"],[8,[39,1],null,[[\"@icon\",\"@class\",\"@onClick\"],[\"delete\",\"color-inherit\",[30,0,[\"_onDelete\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"flex flex-grow-1 items-center justify-center font-preview pa05 white\"],[15,5,[30,0,[\"previewStyle\"]]],[12],[1,\"\\n  Aa\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"footer absolute bottom-0 left-0 right-0 flex items-end secondary pa2\"],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"text\"],[[\"width\",\"height\"],[\"16\",\"16\"]]]],[1,\"\\n\"],[13]],[\"@font\",\"@isReadOnly\"],false,[\"unless\",\"ui-icon-button\",\"svg-jar\"]]",
    "moduleName": "additive-account/components/aa-corporate-design/fonts/item/preview.hbs",
    "isStrictMode": false
  });

  /**
   * Shows the preview of the font
   *
   * @class aa-corporate-design/fonts/item/preview
   */
  let AaCorporateDesignFontsItemPreview = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AaCorporateDesignFontsItemPreview extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "changeset", _descriptor, this);
      _initializerDefineProperty(this, "onDelete", _descriptor2, this);
    }
    get previewStyle() {
      const {
        changeset
      } = this;
      if (changeset) {
        const font = changeset.get('normal') || changeset.get('light') || changeset.get('bold');
        const fallback = changeset.get('serif') ? _constantsFonts.serifFontsFallbacks : _constantsFonts.sansSerifFontsFallbacks;
        if (font) {
          return (0, _template.htmlSafe)(`
            font-family: '${font.fontFamily}-${font.weight}', ${fallback};
            font-weight: ${font.weight};
          `);
        }
      }
      return (0, _template.htmlSafe)('');
    }
    _onDelete(event) {
      event.stopPropagation();
      this.onDelete();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onDelete", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onDelete"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaCorporateDesignFontsItemPreview);
});