define("additive-account/routes/auth/login", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/unauthenticated-route-mixin", "@ember/object", "@ember/utils", "@ember/service", "additive-account/utils/api-query-errors", "@ember/string", "ember-changeset", "additive-account/validations/auth", "ember-changeset-validations"], function (_exports, _route, _unauthenticatedRouteMixin, _object, _utils, _service, _apiQueryErrors, _string, _emberChangeset, _auth, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_unauthenticatedRouteMixin.default, {
    intl: (0, _service.inject)(),
    beforeModel(transition) {
      if (transition.targetName === 'auth.login.reset') {
        return;
      }
      return this._super(...arguments);
    },
    setupController(controller, model) {
      this._super(controller, model);
      const intl = this.intl;
      const validation = (0, _auth.loginValidation)(intl);
      const changeset = new _emberChangeset.default({
        email: '',
        password: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
      changeset.validate();
      (0, _object.set)(controller, 'loginChangeset', changeset);
      controller.resetPasswordDialog();
      const queryErrors = controller.errors;
      const apiErrors = (0, _apiQueryErrors.combineErrors)(queryErrors);
      if ((0, _utils.isPresent)(apiErrors)) {
        (0, _object.set)(controller, 'apiErrors', apiErrors);
        Object.keys(apiErrors).forEach(key => {
          // Get last error, 'cause we only show one error
          const apiErrorsByKey = apiErrors[key];
          const keyCamelized = (0, _string.camelize)(key);
          const error = apiErrorsByKey[apiErrorsByKey.length - 1];
          changeset.pushErrors(keyCamelized, intl.t(`global.errors.apiQueryErrors.${error}`));
          (0, _object.set)(controller, 'isFormTouched', true);
        });
      }
    },
    resetController(controller, isExiting, transition) {
      if (isExiting && transition && transition.targetName === 'auth.signup') {
        const changeset = controller.loginChangeset;
        changeset && changeset.rollback();
      }
    }
  });
});