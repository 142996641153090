define("additive-account/controllers/instances/instance/settings/integrations", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/service", "@ember/object", "ember-concurrency", "@glimmer/tracking"], function (_exports, _environment, _controller, _service, _object, _emberConcurrency, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Integrations = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    const tasks = [];
    const integrationEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organizationId}/settings/pms`;
    try {
      const fetchTask = this.authenticatedFetch.fetch(integrationEndpoint, {
        method: 'GET'
      }, null, [404, 422]);
      tasks.push(fetchTask);
      tasks.push((0, _emberConcurrency.timeout)(250));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!response || !response.ok) {
        if (response.status === 404) {
          this.hasDefinedIntegrations = false;
          return;
        }
        throw new Error(`[settings-integration] Could not load integration.`);
      }
      const {
        pms
      } = yield response.json();
      this.pms = pms;
      this.hasDefinedIntegrations = true;
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toasts.error.title'),
        description: this.intl.t('global.toasts.error.description')
      });
    }
  }), _class = class Integrations extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      /**
       * whether the add dialog is open
       *
       * @property isAddIntegrationDialog
       * @type {Boolean}
       * @default false
       */
      _defineProperty(this, "isAddIntegrationDialog", false);
      /**
       * whether the organization has defined integrations
       *
       * @property hasDefinedIntegrations
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hasDefinedIntegrations", _descriptor5, this);
      /**
       * The defined integration
       *
       * @property pms
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "pms", _descriptor6, this);
      _initializerDefineProperty(this, "_fetch", _descriptor7, this);
    }
    /**
     * the currently active organization
     *
     * @computed organization
     */
    get organizationId() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }

    /**
     * whether the current user is manager
     *
     * @computed isManager
     */
    get isManager() {
      var _this$currentUser2;
      return (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isManager;
    }
    setPms(pms) {
      this.pms = pms;
      this.hasDefinedIntegrations = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasDefinedIntegrations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setPms", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setPms"), _class.prototype), _class);
});