define("additive-account/mirage/endpoints/cors-interaction-routes", ["exports", "ember-cli-mirage", "additive-account/config/environment"], function (_exports, _emberCliMirage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.externalRoutes = void 0;
  const externalRoutes = _exports.externalRoutes = function externalRoutes(server) {
    server.post(_environment.default.APP.imageUploadServiceHost, function () {
      const body = {
        signedUrl: 'https://signed-image-upload-url.com',
        bucketPath: '/upload-bucket-path/image.png'
      };
      return new _emberCliMirage.default.Response(200, {}, body);
    });
    server.put('https://signed-image-upload-url.com', function () {
      return new _emberCliMirage.default.Response(200, {}, {});
    });
    server.post(_environment.default.APP.apiBaseHost + '/api/me/image', function () {
      const body = {
        imageUrl: 'https://image-upload-url.com/imagePath/image.png'
      };
      return new _emberCliMirage.default.Response(200, {}, body);
    });
    server.post(_environment.default.APP.apiBaseHost + '/api/organizations/1/image', function () {
      const body = {
        imageUrl: 'https://image-upload-url.com/imagePath/image.png'
      };
      return new _emberCliMirage.default.Response(200, {}, body);
    });
  };
});