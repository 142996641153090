define("additive-account/templates/components/user-role-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiSelect
    @class="w-100"
    @label={{t "global.roles.title"}}
    @placeholder={{t "global.roles.choose"}}
    @options={{this.globalUserRoles}}
    @selected={{if this.user.role (t (concat "global.roles." this.user.role ".title"))}}
    @onChange={{action (mut this.user.role)}}
    @errorMessage={{get this.user.error.role "validation"}}
    @isTouched={{this.isTouched}} as |option role|
  >
    <option.item @value={{role.value}}>
      {{t (concat "global.roles." role.value ".title")}}
    </option.item>
  </UiSelect>
  */
  {
    "id": "GepnFwe1",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@label\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\",\"@errorMessage\",\"@isTouched\"],[\"w-100\",[28,[37,1],[\"global.roles.title\"],null],[28,[37,1],[\"global.roles.choose\"],null],[30,0,[\"globalUserRoles\"]],[52,[30,0,[\"user\",\"role\"]],[28,[37,1],[[28,[37,3],[\"global.roles.\",[30,0,[\"user\",\"role\"]],\".title\"],null]],null]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"user\",\"role\"]]],null]],null],[28,[37,6],[[30,0,[\"user\",\"error\",\"role\"]],\"validation\"],null],[30,0,[\"isTouched\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@value\"],[[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,3],[\"global.roles.\",[30,2,[\"value\"]],\".title\"],null]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]]],[\"option\",\"role\"],false,[\"ui-select\",\"t\",\"if\",\"concat\",\"action\",\"mut\",\"get\"]]",
    "moduleName": "additive-account/templates/components/user-role-form.hbs",
    "isStrictMode": false
  });
});