define("additive-account/serializers/email-template", ["exports", "additive-account/serializers/default"], function (_exports, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmailTemplateSerializer extends _default.default {
    modelNameFromPayloadKey() {
      return 'email-template';
    }
  }
  _exports.default = EmailTemplateSerializer;
});