define("additive-account/routes/instances/instance/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    redirect(params, transition) {
      transition.abort();
      this.transitionTo('instances.instance.organization', params);
    }
  });
});