define("additive-account/components/aa-card/content", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="aa-card-content {{@class}} {{@classNames}} " ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "xe3tc0K1",
    "block": "[[[11,0],[16,0,[29,[\"aa-card-content \",[30,1],\" \",[30,2],\" \"]]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "additive-account/components/aa-card/content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});