define("additive-account/components/aa-organization/info-item", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _emberArgTypes, _propTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex w-100 pt3 pb1 primary {{@class}}">
    <div class="icon mr2">
      {{svg-jar this.icon}}
    </div>
    <div class="font-sm flex-grow-1">
      <div class="secondary">
        {{this.label}}
      </div>
      <div class="primary">
        {{this.value}}
        {{yield}}
      </div>
    </div>
  </div>
  */
  {
    "id": "2eI92y8P",
    "block": "[[[10,0],[15,0,[29,[\"flex w-100 pt3 pb1 primary \",[30,1]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"icon mr2\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"font-sm flex-grow-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"primary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"value\"]]],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@class\",\"&default\"],false,[\"svg-jar\",\"yield\"]]",
    "moduleName": "additive-account/components/aa-organization/info-item.hbs",
    "isStrictMode": false
  });
  let AaOrganizationInfoItem = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object | _propTypes.string), _class = class AaOrganizationInfoItem extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "icon", _descriptor, this);
      _initializerDefineProperty(this, "label", _descriptor2, this);
      _initializerDefineProperty(this, "value", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaOrganizationInfoItem);
});