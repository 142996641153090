define("additive-account/components/user-role-selection", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed", "ember-concurrency", "additive-account/utils/app-management", "@additive-apps/auth/utils/roles-map", "additive-account/helpers/can-update-role", "ember-changeset"], function (_exports, _component, _object, _service, _computed, _emberConcurrency, _appManagement, _rolesMap, _canUpdateRole, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const APPS_WITH_ROLES = ['2', '4', '7', '9', '11', '12', '50', '51', '100'];
  const USER_PARTNER_APPS_PROPERTY = 'partnerAppRoles';
  const USER_APPS_PROPERTY = 'appRoles';
  var _default = _exports.default = _component.default.extend({
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    getApps: _appManagement.getApps,
    /**
     * The existing org-apps.
     *
     * @property apps
     * @type {Object}
     */
    apps: null,
    /**
     * The global user roles which can be choosed as role.
     *
     * @property globalUserRoles
     * @type {Object}
     */
    globalUserRoles: null,
    /**
     * The user object.
     *
     * @property apps
     * @type {Object}
     */
    user: null,
    /**
     * Wether it should use/update the partner props of the user object (partnerRole, partnerAppRoles).
     *
     * @property usePartnerProperties
     * @type {Boolean}
     */
    usePartnerProperties: false,
    /**
     * property that controls the behaviour when a user role gets updated
     *
     * @property isOrgPartner
     * @type {Boolean}
     */
    isOrgPartner: false,
    isLoadingApps: (0, _computed.alias)('getApps.task.isRunning'),
    /**
     * The apps for which roles can be set.
     *
     * @property availableApps
     * @type {Object}
     */
    availableApps: (0, _object.computed)('usePartnerProperties', 'apps.[]', {
      get() {
        const apps = this.apps;
        if (!apps) {
          return [];
        }
        if (this.usePartnerProperties) {
          // Return all apps to allow setting roles to each app (partner related)
          return APPS_WITH_ROLES.map(appId => {
            return {
              id: appId
            };
          });
        }
        if (apps) {
          return apps.filter(app => APPS_WITH_ROLES.indexOf(app.id) > -1);
        }
        return [];
      }
    }),
    userAppRoles: (0, _object.computed)('userAppRolesProperty', 'user.{appRoles,partnerAppRoles}', {
      get() {
        return this.user && this.user[this.userAppRolesProperty];
      }
    }),
    userAppRolesProperty: (0, _object.computed)('usePartnerProperties', {
      get() {
        return this.usePartnerProperties ? USER_PARTNER_APPS_PROPERTY : USER_APPS_PROPERTY;
      }
    }),
    role: (0, _object.computed)('rolesProperty', 'user.{role,partnerRole}', {
      get() {
        return this.user && this.user[this.rolesProperty];
      }
    }),
    rolesProperty: (0, _object.computed)('usePartnerProperties', {
      get() {
        return this.usePartnerProperties ? 'partnerRole' : 'role';
      }
    }),
    /**
     * A partner_role is nullable so extend the global roles by none, which indicates that
     * a user has no rights as partner.
     */
    appRoles: (0, _object.computed)('usePartnerProperties', 'globalUserRoles', {
      get() {
        if (this.usePartnerProperties) {
          return (this.globalUserRoles || []).concat([{
            value: 'none'
          }]);
        }
        return this.globalUserRoles;
      }
    }),
    didInsertElement() {
      this._super(...arguments);
      _appManagement.getApps.task.perform(this).then(apps => (0, _object.set)(this, 'apps', apps));
    },
    setUserRole: (0, _emberConcurrency.task)(function* (role) {
      const user = this.user;
      const userChangeset = new _emberChangeset.default(user);

      // take a snapshot of the current changeset state
      let snapshot = userChangeset.snapshot();

      // partner_role is nullable, in case its set to none, serialize as null
      if (this.usePartnerProperties && role === 'none') {
        (0, _object.set)(userChangeset, 'partnerRole', null);
      } else {
        (0, _object.set)(userChangeset, this.rolesProperty, role);
      }

      // clear appRoles for non-member users
      if (role !== _rolesMap.ROLE_MEMBER) {
        this.usePartnerProperties ? (0, _object.set)(userChangeset, USER_PARTNER_APPS_PROPERTY, null) : (0, _object.set)(userChangeset, USER_APPS_PROPERTY, null);
      }
      const isPartner = this.isOrgPartner;
      const currentUserRole = this.currentUser && this.currentUser.organizationUser && this.currentUser.organizationUser.role;
      const hasPartnerPrivileges = isPartner && this.currentUser && this.currentUser.isManager;
      const canUpdateUser = hasPartnerPrivileges ? hasPartnerPrivileges : (0, _canUpdateRole.canUpdateRole)([currentUserRole, user.role]);
      (0, _object.set)(userChangeset, 'isDisabled', !canUpdateUser);
      const intl = this.intl;
      try {
        yield userChangeset.save();
        this.uiToast.showToast({
          type: 'success',
          title: intl.t('global.toasts.success.changes.title'),
          description: intl.t('global.toasts.success.changes.description'),
          duration: 2000
        });
      } catch (e) {
        this.uiDialog.showError();
        // restore snapshot on errors
        userChangeset.restore(snapshot);
      } finally {
        (0, _object.set)(this, 'isRoleSelectDialog', false);
        // changeset cleanup
        (0, _object.set)(this, 'userChangeset', null);
      }
    })
  });
});