define("additive-account/routes/instances/instance/settings/communication", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstancesInstanceSettingsCommunication extends _route.default {
    async setupController(controller) {
      super.setupController(...arguments);
      controller.setup();
    }
    resetController(controller) {
      controller.settings = null;
      super.resetController(...arguments);
    }
  }
  _exports.default = InstancesInstanceSettingsCommunication;
});