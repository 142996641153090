define("additive-account/templates/instances/instance", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{outlet}}
  
  {{#if this.onboarding}}
    <UiOnboarding
      @activeOnboarding={{this.onboarding}}
      @onboarding={{this.onboardingOptions}}
      @onFinish={{this.finishOnboarding}}
    />
  {{/if}}
  */
  {
    "id": "yXqk1tpQ",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"onboarding\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@activeOnboarding\",\"@onboarding\",\"@onFinish\"],[[30,0,[\"onboarding\"]],[30,0,[\"onboardingOptions\"]],[30,0,[\"finishOnboarding\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"component\",\"-outlet\",\"if\",\"ui-onboarding\"]]",
    "moduleName": "additive-account/templates/instances/instance.hbs",
    "isStrictMode": false
  });
});