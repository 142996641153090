define("additive-account/helpers/aa-app-modules", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.aaAppModules = aaAppModules;
  _exports.default = void 0;
  /**
   * Helper to format modules display
   *
   * @export
   * @param {Array} modules
   * @returns {String}
   */
  function aaAppModules(_ref) {
    let [modules] = _ref;
    let formatted = [];
    modules === null || modules === void 0 || modules.forEach(element => {
      formatted.push(element.name);
    });
    if (formatted.length) return formatted.join(', ');
    return '';
  }
  var _default = _exports.default = (0, _helper.helper)(aaAppModules);
});