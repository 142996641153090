define("additive-account/utils/me-actions", ["exports", "additive-account/config/environment", "ember-concurrency", "rsvp", "@ember/object"], function (_exports, _environment, _emberConcurrency, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updatePassword = _exports.refreshCredentials = void 0;
  /**
   * Handles a simple HTTP_PUT on the current user
   * in order to update the users current password
   */
  const updatePassword = _exports.updatePassword = {};
  (0, _object.defineProperty)(updatePassword, 'task', (0, _emberConcurrency.task)(function* (password, self) {
    const request = self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/me/password`, {
      method: 'PUT'
    }, {
      password
    });
    return yield request.then(_ref => {
      let {
        status
      } = _ref;
      return status >= 400 ? (0, _rsvp.reject)(status) : (0, _rsvp.resolve)(status);
    });
  }));
  /**
   * Refresh the users auth-status
   *
   * - 204: the provided pwd is correct
   * - 422: incorrect credentials
   */
  const refreshCredentials = _exports.refreshCredentials = {};
  (0, _object.defineProperty)(refreshCredentials, 'task', (0, _emberConcurrency.task)(function* (password, self) {
    const request = self.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/auth/refresh`, {
      method: 'POST'
    }, {
      password
    });
    return yield request.then(_ref2 => {
      let {
        status
      } = _ref2;
      return status >= 400 ? (0, _rsvp.reject)(status) : (0, _rsvp.resolve)(status);
    });
  }));
});