define("additive-account/routes/instances/instance/email-templates", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    uiFilter: (0, _service.inject)(),
    setupController() {
      this._super(...arguments);
      this.uiFilter.register('emailTemplates', 'email-template', {
        filterable: false,
        searchable: false,
        paginated: false
      });
    },
    resetController(_, isExiting) {
      this._super(...arguments);
      isExiting && this.uiFilter.unregister('emailTemplates');
    }
  });
});