define("additive-account/templates/logout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="flex flex-column flex-grow-1 items-center justify-center w-100 h-100 pa3 font-lg bg-primary white"
  >
    {{t "auth.logout.wait" htmlSafe=true}}
    <UiLoading @class="mt3" />
  
    {{outlet}}
  </div>
  */
  {
    "id": "vNoV3Zng",
    "block": "[[[10,0],[14,0,\"flex flex-column flex-grow-1 items-center justify-center w-100 h-100 pa3 font-lg bg-primary white\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"auth.logout.wait\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n  \"],[8,[39,1],null,[[\"@class\"],[\"mt3\"]],null],[1,\"\\n\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"t\",\"ui-loading\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-account/templates/logout.hbs",
    "isStrictMode": false
  });
});