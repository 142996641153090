define("additive-account/helpers/first-existing", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.firstExisting = firstExisting;
  /**
   * Pass arguments, the first of the arguments (starting at index 0) which exists will be passed back
   */
  function firstExisting(params) {
    if (!params) {
      return;
    }
    let found = null;
    params.some(param => {
      if (param) {
        found = param;
        return true;
      }
      return false;
    });
    return found;
  }
  var _default = _exports.default = (0, _helper.helper)(firstExisting);
});