define("additive-account/helpers/app-assets", ["exports", "@ember/component/helper", "additive-account/config/environment"], function (_exports, _helper, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appAssets = appAssets;
  _exports.default = void 0;
  const ASSETS_URL = `${_environment.default.APP.cdnHost}/shared-assets`;

  /**
   * Return a apps assets by it's id
   *
   * Currently only 'logo' is supported as type.
   *
   * @param {String} params the type of the desired asset
   * @param {Int} id the app id
   * @return {String}
   */
  function appAssets(params, _ref) {
    let {
      id
    } = _ref;
    if (params.indexOf('additive') > -1) {
      // TODO: The desired file-type should be passed in as well
      return `${ASSETS_URL}/app-logos/additive-apps-logo.svg`;
    } else if (!id) {
      // Change to return a default asset
      return '';
    }
    if (params.indexOf('logo') > -1) {
      // TODO: The desired file-type should be passed in as well
      return `${ASSETS_URL}/app-logos/app-${id}.png`;
    }
    if (params.indexOf('svg') > -1) {
      // TODO: The desired file-type should be passed in as well
      return `${ASSETS_URL}/app-logos/svg/app-${id}.svg`;
    }
    return params;
  }

  /**
   * ```hbs
   * {{ui-app-selection assetType appId}}
   * ```
   * @class app-assets
   */
  var _default = _exports.default = (0, _helper.helper)(appAssets);
});