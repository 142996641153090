define("additive-account/controllers/instances/create", ["exports", "additive-account/config/environment", "additive-account/utils/organization-utils", "additive-account/mixins/global-user", "@ember/controller", "@ember/service", "@ember/object", "ember-concurrency", "rsvp"], function (_exports, _environment, _organizationUtils, _globalUser, _controller, _service, _object, _emberConcurrency, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    queryParams: ['app_id', 'plan_name'],
    app_id: null,
    plan_name: null,
    partnerOptions: null,
    currentNavIndex: 0,
    changeset: null,
    isAppSelectionOpen: false,
    // prop that opens app-selection dialog

    isChangesetInvalid: _object.computed.alias('changeset.isInvalid'),
    isOverviewDisabled: _object.computed.or('isChangesetInvalid', 'createOrganizationTask.isRunning'),
    isPartner: _object.computed.bool('globalUser.partnerSlug'),
    init() {
      this._super(...arguments);
      this.fetchPartners.perform();
    },
    validateSlug: (0, _emberConcurrency.task)(function* (slug) {
      (0, _object.set)(this, 'isFormTouched', true);
      const changeset = this.changeset;
      yield changeset.validate('slug');
      if (!changeset.get('error.slug')) {
        yield (0, _emberConcurrency.timeout)(350);
        const {
          ok,
          message
        } = yield _organizationUtils.validateOrganization.slugUniqueness.perform(slug, this);
        if (ok) {
          (0, _object.set)(this, 'slugState', 'success');
        } else {
          if (message === 'not_unique') {
            changeset.addError('slug', this.intl.t('global.errors.apiQueryErrors.validation.unique'));
          }
          (0, _object.set)(this, 'slugState', 'error');
        }
      } else {
        (0, _object.set)(this, 'slugState', 'error');
      }
    }).restartable(),
    fetchPartners: (0, _emberConcurrency.task)(function* () {
      const url = `${_environment.default.APP.apiBaseHost}/api/partners`;
      try {
        const response = yield this.authenticatedFetch.fetch(url);
        if (!response || !response.ok) {
          return (0, _rsvp.reject)();
        }
        const {
          partners
        } = yield response.json();
        (0, _object.set)(this, 'partnerOptions', partners);
      } catch (error) {
        this.uiDialog.showError();
      }
    }),
    createOrganizationTask: (0, _emberConcurrency.task)(function* (data) {
      const request = this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations`, {
        method: 'post'
      }, data);

      // saves organization
      const {
        organization,
        errors
      } = yield request.then(response => response.json());
      const intl = this.intl;
      if (errors) {
        if (errors.website) {
          this.uiDialog.showAlert(intl.t('create.appSelection.errors.uniqueWebsite.title'), intl.t('create.appSelection.errors.uniqueWebsite.description'));
        } else if (errors.email) {
          this.uiDialog.showAlert(intl.t('create.appSelection.errors.invalidEmail.title'), intl.t('create.appSelection.errors.invalidEmail.description'));
        } else {
          // We should consider reacting to wrong_app_id separately
          // show error dialog
          this.uiDialog.showError();
        }
      } else {
        // in case the org has successfully been created, the user gets redirected to the organization account
        this.transitionToRoute('instances.instance', organization.id);
        this.clearData();
      }
    }).drop(),
    // This adds the given app+plan to the cart
    addApp(appId, appKey, planName, amount) {
      const changeset = this.changeset;
      const appPlans = changeset.get('appPlans') || [];

      // keep appPlans-array changes immutable
      const appPlansDraft = appPlans.filter(el => el.appId !== appId);

      // only existing roles get serialized
      appPlansDraft.push({
        appId,
        appKey,
        planName,
        amount
      });
      changeset.set('appPlans', appPlansDraft);
    },
    clearData() {
      // remove the model dummy from store
      this.model.deleteRecord();
      // remove changeset from memory
      (0, _object.set)(this, 'changeset', null);
      // reset initial controller state
      (0, _object.set)(this, 'currentNavIndex', 0);
      (0, _object.set)(this, 'isAppSelectionOpen', false);
    },
    actions: {
      /**
       * Check if given org.-data is valid and call creation-task if so.
       * Otherwise we trigger the whole form-validation which handles
       * possible errors properly.
       */
      createOrganisation() {
        const changeset = this.changeset;
        return changeset.validate().then(() => {
          if (changeset.get('isValid')) {
            var _organization$appPlan;
            // remove the amount form the app-plans before sending the payload
            const appPlans = changeset.get('appPlans').map(el => {
              return {
                appId: el.appId,
                planName: el.planName
              };
            });
            let organization = {
              name: changeset.get('name'),
              website: changeset.get('website'),
              email: changeset.get('email'),
              slug: changeset.get('slug'),
              appPlans,
              language: this.uiLocale.locale,
              isPartner: changeset.get('isPartner'),
              partnerId: changeset.get('partnerId')
            };
            if (changeset.get('phone')) {
              organization['phone'] = changeset.get('phone');
            }
            if (((_organization$appPlan = organization.appPlans) === null || _organization$appPlan === void 0 ? void 0 : _organization$appPlan.length) === 0 && organization.isPartner) {
              delete organization.appPlans;
            }
            return this.createOrganizationTask.perform(organization);
          } else {
            // in case the org is not valid, check actual reason
            return this.send('validate');
          }
        });
      },
      addApp(appId, appKey, planName, amount) {
        this.addApp(appId, appKey, planName, amount);
      },
      setAsPartner(value) {
        this.changeset.set('partnerId', null);
        this.changeset.set('isPartner', value);
      },
      setPartner(partner) {
        const partnerId = typeof partner == 'object' ? partner.id : partner;
        this.changeset.set('partnerId', partnerId);
      },
      removeApp(appId) {
        const changeset = this.changeset;
        const appPlans = changeset.get('appPlans') || [];

        // keep appPlans-array changes immutable
        const appPlansDraft = appPlans.filter(el => el.appId !== appId);
        changeset.set('appPlans', appPlansDraft);
      },
      generateSlug(value) {
        // check if slug should be generated from website
        if (this.isSlugTouched) {
          return;
        }
        // generate slug based on website value
        const slug = (0, _organizationUtils.sluggifyWebsite)(value);
        // set slug to new value
        this.changeset.set('slug', slug);
        this.validateSlug.perform(slug);
      },
      validate() {
        const changeset = this.changeset;
        return changeset.validate().then(() => {
          /* The validation of the changeset overwrites the error "unique" of the slug.
           * Therefore we have to check if the slug is correct.
           */
          if (this.slugState === 'error' && changeset.get('error').slug === undefined) {
            changeset.addError('slug', this.intl.t('global.errors.apiQueryErrors.validation.unique'));
          }
          const errors = changeset.get('errors');
          if (this.validateSlug && this.validateSlug.isIdle && changeset.get('isValid')) {
            return (0, _rsvp.resolve)();
          } else if (errors && errors.length === 1 && errors[0].key === 'appPlans') {
            const intl = this.intl;
            // we use the confirm function as it's currently the only one that works properly
            this.uiDialog.showConfirm(intl.t('create.appSelection.errors.noAppPlansSelected.title'), intl.t('create.appSelection.errors.noAppPlansSelected.description'), () => (0, _object.set)(this, 'isAppSelectionOpen', true), intl.t('create.appSelection.errors.noAppPlansSelected.action'));
            return (0, _rsvp.reject)();
          } else {
            (0, _object.set)(this, 'isFormTouched', true);
            return (0, _rsvp.reject)();
          }
        });
      },
      nextPage(nextIndex) {
        (0, _object.set)(this, 'currentNavIndex', nextIndex);
      }
    }
  });
});