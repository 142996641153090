define("additive-account/validators/salutation", ["exports", "additive-account/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateSalutationPlaceholders = validateSalutationPlaceholders;
  /**
   *  @module Validators
   */

  /**
   * @function validateSalutationPlaceholders
   *
   * @param {Any} value
   * @param {Object} options
   * @param {String} options.message Error message
   *
   * @return {Boolean|ValidationErr}
   */
  function validateSalutationPlaceholders() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const message = options && options.message || false;
    const validPlaceholders = Object.keys(_constants.SALUTATION_PLACEHOLDER);
    return (key, value) => {
      let isValid = true;
      const placeholders = value.match(/{#[a-zA-Z]*#}/g) || [];
      placeholders.forEach(placeholder => {
        if (validPlaceholders.indexOf(placeholder.toUpperCase()) === -1) {
          isValid = false;
        }
      });
      const openingBracketsCount = (value.match(/{#/g) || []).length;
      const closingBracketsCount = (value.match(/#}/g) || []).length;

      /**
       * check if there are incomplete placeholders
       * or other variations ignore by the regex above
       */
      if (openingBracketsCount !== closingBracketsCount || openingBracketsCount !== placeholders.length) {
        isValid = false;
      }
      return isValid || message;
    };
  }
});