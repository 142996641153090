define("additive-account/routes/invite", ["exports", "@ember/routing/route", "@ember/object", "additive-account/utils/user-management"], function (_exports, _route, _object, _userManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   */
  var _default = _exports.default = _route.default.extend({
    setupController(controller) {
      this._super(...arguments);
      const {
        token
      } = controller;
      // Check if its a regular sign-up or an invite
      if (token) {
        (0, _object.set)(controller, 'isInvite', true);
        _userManagement.getInvitationData.task.perform(token).then(_ref => {
          let {
            newUser
          } = _ref;
          if (newUser) {
            this.transitionTo('auth.signup', {
              queryParams: {
                token
              }
            });
          } else {
            // accept invite
            this.transitionTo('instances.me', {
              queryParams: {
                token
              }
            });
          }
        }).catch(() => {
          (0, _object.set)(controller, 'token', null);
          this.transitionTo('auth.login');
        });
      } else {
        this.transitionTo('auth.login');
      }
    }
  });
});