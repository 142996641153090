define("additive-account/routes/instances/instance/users/list", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "additive-account/utils/user-management", "additive-account/helpers/can-update-role"], function (_exports, _route, _object, _service, _userManagement, _canUpdateRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    currentUser: (0, _service.inject)(),
    setupController(controller) {
      var _controller$globalUse, _controller$globalUse2, _this$currentUser, _this$currentUser2, _this$currentUser3;
      this._super(...arguments);
      const isAdditive = (_controller$globalUse = controller.globalUser) === null || _controller$globalUse === void 0 ? void 0 : _controller$globalUse.isAdditive;
      const isPartner = (_controller$globalUse2 = controller.globalUser) === null || _controller$globalUse2 === void 0 ? void 0 : _controller$globalUse2.isOrgPartner;
      const currentUserRole = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.organizationUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.role;
      const hasPartnerPrivileges = isAdditive || isPartner && ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isManager);
      const hasPrivileges = hasPartnerPrivileges || ((_this$currentUser3 = this.currentUser) === null || _this$currentUser3 === void 0 || (_this$currentUser3 = _this$currentUser3.user) === null || _this$currentUser3 === void 0 ? void 0 : _this$currentUser3.isSuperAdmin);
      _userManagement.getUsers.task.perform(this, controller).then(users => {
        var _this$currentUser4;
        const currentUserId = (_this$currentUser4 = this.currentUser) === null || _this$currentUser4 === void 0 || (_this$currentUser4 = _this$currentUser4.user) === null || _this$currentUser4 === void 0 ? void 0 : _this$currentUser4.id;
        users.forEach(user => {
          // checks if the current users role is greater then the organisations user
          const canUpdateUser = hasPrivileges ? hasPrivileges : (0, _canUpdateRole.canUpdateRole)([currentUserRole, user.role]);
          (0, _object.set)(user, 'isDisabled', !canUpdateUser || user.id === currentUserId);
        });
        (0, _object.set)(controller, 'users', users);
      });
    }
  });
});