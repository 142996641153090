define("additive-account/utils/fonts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadFont = void 0;
  /**
   * loads a font into the dom
   *
   * @function loadFont
   * @param {String} fontFamily, the name of the fontface
   * @param {String} url, the url to load from
   */
  const loadFont = (fontFamily, url) => {
    // check for properties and dom features, if a font is called Barlow it is not loaded to avoid
    // interfering with the font of the application
    if (!fontFamily || !url || !document.fonts || fontFamily === 'Barlow') return;
    try {
      const newFont = new FontFace(fontFamily, `url(${url})`);
      return newFont.load().then(loadedFont => document.fonts.add(loadedFont));
    } catch (e) {
      // ignore errors
      return;
    }
  };
  _exports.loadFont = loadFont;
});