define("additive-account/templates/invite", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="auth-views bg-primary">
    <UiContent @isCentered={{true}}>
      <UiLoading />
    </UiContent>
  </div>
  */
  {
    "id": "Ve7K4Ila",
    "block": "[[[10,0],[14,0,\"auth-views bg-primary\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@isCentered\"],[true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"ui-content\",\"ui-loading\"]]",
    "moduleName": "additive-account/templates/invite.hbs",
    "isStrictMode": false
  });
});