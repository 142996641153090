define("additive-account/templates/instances/instance/settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiContentViews @title={{t "routes.organization.title"}} @theme="grey" as |view|>
    <view.navbar />
  
    <view.content>
      <UiRoutableTabs @tabRoutes={{this.routableTabs}} as |tabs|>
        <tabs.header @classNames="pb2" as |header|>
          {{#each this.routableTabs as |route|}}
            <header.tab @tabName={{route.name}} @routePath={{route.path}} />
          {{/each}}
        </tabs.header>
  
        <tabs.content @class="flex flex-column">
          {{outlet}}
        </tabs.content>
      </UiRoutableTabs>
    </view.content>
  
    <view.footer @classNames="flex flex-column items-end views__footer w-100">
      <div id="settings-fab-button-container"></div>
    </view.footer>
  </UiContentViews>
  */
  {
    "id": "5sCoRlTo",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"routes.organization.title\"],null],\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@tabRoutes\"],[[30,0,[\"routableTabs\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"header\"]],null,[[\"@classNames\"],[\"pb2\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"routableTabs\"]]],null]],null],null,[[[1,\"          \"],[8,[30,3,[\"tab\"]],null,[[\"@tabName\",\"@routePath\"],[[30,4,[\"name\"]],[30,4,[\"path\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"      \"]],[3]]]]],[1,\"\\n\\n      \"],[8,[30,2,[\"content\"]],null,[[\"@class\"],[\"flex flex-column\"]],[[\"default\"],[[[[1,\"\\n        \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,[[\"@classNames\"],[\"flex flex-column items-end views__footer w-100\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,1,\"settings-fab-button-container\"],[12],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\",\"tabs\",\"header\",\"route\"],false,[\"ui-content-views\",\"t\",\"ui-routable-tabs\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-account/templates/instances/instance/settings.hbs",
    "isStrictMode": false
  });
});