define("additive-account/templates/confirm", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <UiFullscreenIllustration
    @class="w-100"
    @illustration={{this.viewContent.illustration}}
    @title={{this.viewContent.title}}
    @description={{this.viewContent.description}}
    @buttonRoute={{this.viewContent.buttonRoute}}
    @actionLabel={{this.viewContent.actionLabel}}
  >
    {{#if this.viewContent.isPendingInvite}}
      <UiButton @label={{this.viewContent.actionLabel}} @onClick={{action "acceptInvitation"}} />
    {{else if this.viewContent.isWrongUser}}
      <UiButton @label={{this.viewContent.actionLabel}} @onClick={{action "logout"}} />
    {{/if}}
  </UiFullscreenIllustration>
  */
  {
    "id": "Wr/4gOaj",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@illustration\",\"@title\",\"@description\",\"@buttonRoute\",\"@actionLabel\"],[\"w-100\",[30,0,[\"viewContent\",\"illustration\"]],[30,0,[\"viewContent\",\"title\"]],[30,0,[\"viewContent\",\"description\"]],[30,0,[\"viewContent\",\"buttonRoute\"]],[30,0,[\"viewContent\",\"actionLabel\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"viewContent\",\"isPendingInvite\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@label\",\"@onClick\"],[[30,0,[\"viewContent\",\"actionLabel\"]],[28,[37,3],[[30,0],\"acceptInvitation\"],null]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"viewContent\",\"isWrongUser\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@label\",\"@onClick\"],[[30,0,[\"viewContent\",\"actionLabel\"]],[28,[37,3],[[30,0],\"logout\"],null]]],null],[1,\"\\n  \"]],[]],null]],[]]]],[]]]]]],[],false,[\"ui-fullscreen-illustration\",\"if\",\"ui-button\",\"action\"]]",
    "moduleName": "additive-account/templates/confirm.hbs",
    "isStrictMode": false
  });
});