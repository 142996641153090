define("additive-account/components/aa-file-upload", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency", "ember-arg-types", "prop-types", "additive-account/utils/constants", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _emberConcurrency, _emberArgTypes, _propTypes, _constants, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  {{yield
    (hash
      input=(component
        "aa-file-upload/input"
        showDeleteButton=@showDeleteButton
        showLoading=this.isLoading
        isDisabled=@isDisabled
        isSmall=@isSmall
        accept=@accept
        url=@url
        alt=@alt
        placeholderTitle=@placeholderTitle
        style=@style
        class=@class
        onChange=this.onChange
        onDelete=this.onDelete
      )
      fontInput=(component
        "aa-file-upload/font-input"
        showDeleteButton=@showDeleteButton
        showLoading=this.uploadFont.isRunning
        isDisabled=@isDisabled
        accept=@accept
        url=@url
        alt=@alt
        title=@placeholderTitle
        style=@style
        class=@class
        onChange=this.onChange
      )
      showDeleteButton=@showDeleteButton
      isLoading=this.isLoading
      isDisabled=@isDisabled
      accept=@accept
      url=@url
      alt=@alt
      onChange=this.onChange
      onDelete=this.onDelete
    )
  }}
  */
  {
    "id": "0fJfX5YC",
    "block": "[[[18,10,[[28,[37,1],null,[[\"input\",\"fontInput\",\"showDeleteButton\",\"isLoading\",\"isDisabled\",\"accept\",\"url\",\"alt\",\"onChange\",\"onDelete\"],[[50,\"aa-file-upload/input\",0,null,[[\"showDeleteButton\",\"showLoading\",\"isDisabled\",\"isSmall\",\"accept\",\"url\",\"alt\",\"placeholderTitle\",\"style\",\"class\",\"onChange\",\"onDelete\"],[[30,1],[30,0,[\"isLoading\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,0,[\"onChange\"]],[30,0,[\"onDelete\"]]]]],[50,\"aa-file-upload/font-input\",0,null,[[\"showDeleteButton\",\"showLoading\",\"isDisabled\",\"accept\",\"url\",\"alt\",\"title\",\"style\",\"class\",\"onChange\"],[[30,1],[30,0,[\"uploadFont\",\"isRunning\"]],[30,2],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,0,[\"onChange\"]]]]],[30,1],[30,0,[\"isLoading\"]],[30,2],[30,4],[30,5],[30,6],[30,0,[\"onChange\"]],[30,0,[\"onDelete\"]]]]]]]],[\"@showDeleteButton\",\"@isDisabled\",\"@isSmall\",\"@accept\",\"@url\",\"@alt\",\"@placeholderTitle\",\"@style\",\"@class\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "additive-account/components/aa-file-upload.hbs",
    "isStrictMode": false
  });
  const MB = 1024 * 1024;
  let AaFileUpload = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.number), _dec8 = (0, _emberArgTypes.arg)(_propTypes.array), _dec9 = (0, _emberArgTypes.arg)(_propTypes.string), _dec10 = (0, _emberArgTypes.arg)(_propTypes.string), _dec11 = (0, _emberArgTypes.arg)(_propTypes.string), _dec12 = (0, _emberArgTypes.arg)(_propTypes.string), _dec13 = (0, _emberConcurrency.task)(function* (file) {
    try {
      const fileEnding = file.name.split('.').pop();
      if (_constants.FONT_FILE_ENDINGS.indexOf(fileEnding) < 0) {
        return this.uiDialog.showError(this.intl.t('global.errors.userErrors.fontUpload.title'), this.intl.t(`global.errors.userErrors.fontUpload.type`));
      }
      const seconds = new Date().getTime();
      const response = yield this.s3UploadFile.uploadFile({
        file: file,
        uploadPath: `image-upload/${seconds}/${file.name}`,
        fallbackFileType: `font/${fileEnding}`
      });
      this.onFileChange(response);
    } catch (e) {
      this.uiDialog.showError(this.intl.t('global.errors.userErrors.fontUpload.title'), this.intl.t('global.errors.userErrors.fontUpload.error'));
    }
  }), _dec14 = (0, _emberConcurrency.task)(function* (file) {
    const currentImageUrl = this.url && this.url.substr();
    try {
      // copy currentImageUrl
      const image = yield this._loadImage(file);
      if (!image) throw new Error();

      // validate image and show error
      const error = this._validateImage(file, image);
      if (error) {
        return this.uiDialog.showError(this.intl.t('global.errors.userErrors.imageUpload.title'), this.intl.t(`global.errors.userErrors.imageUpload.${error.key}`, error.params));
      }

      // set temporary image
      this.onFileChange(null, true);

      // upload file to s3
      const seconds = new Date().getTime();
      const response = yield this.s3UploadFile.uploadFile({
        file: file,
        uploadPath: `image-upload/${seconds}/${file.name}`
      });
      if (!response) throw new Error();

      // set uploaded url
      let body = {
        path: response,
        type: this.type
      };
      const apiResponse = yield this.authenticatedFetch.fetch(this.uploadUrl, {
        method: 'POST',
        body: JSON.stringify(body)
      });
      if (!apiResponse || apiResponse.status !== 200) throw new Error();
      const json = yield apiResponse.json();
      const imageUrl = json && json[this.responsePropertyName];
      if (!imageUrl) throw new Error();

      // notify view about url change
      this.onFileChange(imageUrl);
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toasts.success.image.upload.title'),
        description: this.intl.t('global.toasts.success.image.upload.description'),
        duration: 2000
      });
    } catch (e) {
      // reset to old image url in case an error occurs
      this.onFileChange(currentImageUrl);
      this.uiDialog.showError(this.intl.t('global.errors.userErrors.imageUpload.title'), this.intl.t('global.errors.userErrors.imageUpload.error'));
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    try {
      const response = yield this.authenticatedFetch.fetch(this.uploadUrl, {
        method: 'DELETE'
      }, {
        type: this.type
      });
      if (!response || response.status !== 204) throw new Error();
      this.onFileChange('');
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toasts.success.image.delete.title'),
        description: this.intl.t('global.toasts.success.image.delete.description', {
          htmlSafe: true
        }),
        duration: 2000
      });
    } catch (e) {
      this.uiDialog.showAlert(this.intl.t('global.errors.imageDelete.failed.title'), this.intl.t('global.errors.imageDelete.failed.text', {
        htmlSafe: true
      }));
    }
  }), _dec16 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AaFileUpload extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "s3UploadFile", _descriptor3, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      /**
       * if set to true, the delete button is shown
       *
       * @argument accept
       * @type {String}
       * @default ''
       */
      _initializerDefineProperty(this, "accept", _descriptor6, this);
      /**
       * if set to true, the delete button is shown
       *
       * @property showDeleteButton
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showDeleteButton", _descriptor7, this);
      /**
       * if set to true, the loading animation is shown
       *
       * @property showLoading
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showLoading", _descriptor8, this);
      /**
       * the placeholder text to show instead of the default icon
       *
       * @property placeholderTitle
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "placeholderTitle", _descriptor9, this);
      /**
       * if set to true the upload should be disabled
       *
       * @property isDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor10, this);
      /**
       * whether the upload wrapper is small
       *
       * @property isSmall
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSmall", _descriptor11, this);
      /**
       * the maximal image size in bytes
       *
       * @property maxImageSize
       * @type {Number}
       * @default 2097152
       */
      _initializerDefineProperty(this, "maxImageSize", _descriptor12, this);
      /**
       * the minimal image dimensions passed as an array
       * `[width, height]`
       *
       * @property minDimensions
       * @type {Array}
       * @default undefined
       */
      _initializerDefineProperty(this, "minDimensions", _descriptor13, this);
      /**
       * the url to which the uploaded url shoud be sent
       *
       * @property uploadUrl
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "uploadUrl", _descriptor14, this);
      /**
       * the property name of the request result
       *
       * @property responsePropertyName
       * @type {String}
       * @default imageUrl
       */
      _initializerDefineProperty(this, "responsePropertyName", _descriptor15, this);
      /**
       * the type of file upload
       * `image` | `font`
       *
       * @property fileType
       * @type {String}
       * @default 'image'
       */
      _initializerDefineProperty(this, "fileType", _descriptor16, this);
      _initializerDefineProperty(this, "type", _descriptor17, this);
      /**
       * uploads a valid font to s3 and calls the callback with the url
       *
       * @function uploadFont
       * @type {Task}
       * @param {File} file
       */
      _initializerDefineProperty(this, "uploadFont", _descriptor18, this);
      /**
       * uploads a valid file to s3 and sets the returned url in the api
       *
       * @function uploadImage
       * @type {Task}
       * @param {File} file
       */
      _initializerDefineProperty(this, "uploadImage", _descriptor19, this);
      _initializerDefineProperty(this, "deleteImage", _descriptor20, this);
      /**
       * callback that sets the uploaded image
       *
       * @function onFileChange
       * @param imageUrl
       */
      _initializerDefineProperty(this, "onFileChange", _descriptor21, this);
    }
    /**
     * whether an upload is running
     *
     * @computed isLoading
     * @type {Boolean}
     */
    get isLoading() {
      var _this$uploadFont, _this$uploadImage;
      return ((_this$uploadFont = this.uploadFont) === null || _this$uploadFont === void 0 ? void 0 : _this$uploadFont.isRunning) || ((_this$uploadImage = this.uploadImage) === null || _this$uploadImage === void 0 ? void 0 : _this$uploadImage.isRunning);
    }
    /**
     * creates an image object, uploading the file into local storage
     *
     * @function _loadImage
     * @param {File} file
     */
    _loadImage(file) {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      return new Promise(resolve => {
        image.onload = () => {
          return resolve(image);
        };
        image.onerror = () => {
          return resolve();
        };
      });
    }

    /**
     * Validates the type, size and dimensions of the file
     *
     * @function _validateImage
     * @param {File} file the uploaded file
     * @param {Image} image the image object uploaded into local storage
     */
    _validateImage(file, image) {
      const {
        naturalHeight = 0,
        naturalWidth = 0
      } = image;

      // validate image
      if (_constants.IMAGE_MIME_TYPES.indexOf(file.type) < 0) {
        return {
          key: 'type'
        };
      } else if (file.size > this.maxImageSize) {
        return {
          key: 'size',
          params: {
            size: `${this.maxImageSize / MB} MB`
          }
        };
      } else if (naturalWidth < this.minDimensions[0] || naturalHeight < this.minDimensions[1]) {
        return {
          key: 'dimensions',
          params: {
            width: this.minDimensions[0],
            height: this.minDimensions[1]
          }
        };
      }
      return;
    }
    onChange(event) {
      var _event$target;
      if (!(event !== null && event !== void 0 && (_event$target = event.target) !== null && _event$target !== void 0 && (_event$target = _event$target.files) !== null && _event$target !== void 0 && _event$target.length)) return;
      const file = event.target.files[0];
      if (this.fileType === 'image') {
        this.uploadImage.perform(file);
      } else if (this.fileType === 'font') {
        this.uploadFont.perform(file);
      }
    }
    onDelete() {
      if (this.showDeleteButton) {
        return this.uiDialog.showConfirm(this.intl.t('global.dialogs.deleteImageWarning.title'), this.intl.t('global.dialogs.deleteImageWarning.description'), () => this.deleteImage.perform(), this.intl.t('global.actions.delete'));
      }
      this.deleteImage.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "s3UploadFile", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accept", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showDeleteButton", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showLoading", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "placeholderTitle", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isSmall", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "maxImageSize", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 2097152;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "minDimensions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "uploadUrl", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "responsePropertyName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'imageUrl';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "fileType", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'image';
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "uploadFont", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "uploadImage", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "deleteImage", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "onFileChange", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaFileUpload);
});