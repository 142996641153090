define("additive-account/mirage/helpers/medium", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRandomMedium = void 0;
  const getRandomMedium = _exports.getRandomMedium = function getRandomMedium() {
    let nullable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    let pool = [{
      height: '5846',
      id: 'a06fdb20-c2c5-4716-b904-d3527ed1e6af',
      mediumId: '74ece181-4d50-4aef-b0a9-de2efa4c377f',
      name: 'Beautiful Flamingo',
      position: 0,
      url: 'https://voucher-staging.additive-apps.tech/testhotel-post-com/images/a06fdb20-c2c5-4716-b904-d3527ed1e6af?ts=1583137931',
      width: '3897'
    }, {
      height: '3385',
      id: '1ea08a99-78cc-4ab2-b18b-0d205405273f',
      mediumId: '1973f7ba-6dc3-4e07-b562-59f0d622d122',
      name: 'Beautiful dog in most photographed place in Canada',
      position: 1,
      url: 'https://voucher-staging.additive-apps.tech/testhotel-post-com/images/1ea08a99-78cc-4ab2-b18b-0d205405273f?ts=1583137931',
      width: '5077'
    }, {
      height: '2763',
      id: '82fc047d-9d34-40ea-9bff-c499a3cc464b',
      mediumId: '8d2747f6-35be-4809-9d0c-502e2e62bd53',
      name: 'An Atlantic Puffin stands tall and stares straigh ahead while on a rock on Machias Seal Island.',
      position: 2,
      url: 'https://voucher-staging.additive-apps.tech/testhotel-post-com/images/82fc047d-9d34-40ea-9bff-c499a3cc464b?ts=1583137932',
      width: '1839'
    }];
    nullable && pool.push(null);
    return _faker.default.random.arrayElement(pool);
  };
});