define("additive-account/components/aa-corporate-design/fonts/font-type-dialog", ["exports", "@ember/component", "@ember/object", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FONT_WEIGHTS = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  /**
   * Dialog to change name and weight of a font type
   *
   * @class aa-corporate-design/fonts/font-type-dialog
   */
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    /**
     * the changeset containing the font-weight
     *
     * @property changeset
     * @type {Changeset}
     * @default null
     */
    changeset: null,
    /**
     * the key of the fontweight
     * `light` | `normal` | `bold`
     *
     * @property key
     * @type {String}
     * @default null
     */
    key: null,
    /**
     * the id of the organization
     *
     * @computed organizationId
     * @type {String}
     */
    organizationId: _object.computed.alias('currentUser.currentOrganization.id'),
    /**
     * the title of the dialog
     *
     * @computed title
     * @type {String}
     */
    title: (0, _object.computed)('key', {
      get() {
        const {
          intl,
          changeset
        } = this;

        // get the font weight translation
        const fontWeight = intl.t(`routes.corporateDesign.typography.fonts.types.${changeset.get('identifier')}`);
        return `"${fontWeight}" ${intl.t('routes.corporateDesign.typography.fonts.upload')}`;
      }
    }),
    init() {
      this._super(...arguments);

      // the font weight options
      (0, _object.set)(this, 'weightOptions', FONT_WEIGHTS.map(fontWeight => {
        return {
          value: fontWeight,
          name: fontWeight
        };
      }));
    },
    onClose() {},
    onSave() {},
    /**
     * saves the fontweight
     *
     * @function save
     * @type {Task}
     */
    save: (0, _emberConcurrency.task)(function* () {
      const {
        key,
        changeset,
        organizationId
      } = this;
      try {
        yield changeset.validate();
        if (!changeset.get('isValid')) return;

        // save underlying object
        yield changeset.save();
        const id = changeset.get('id');
        const adapter = this.store.adapterFor('corporate-design');
        const url = adapter.buildURL('corporate-design', organizationId);
        const response = yield this.authenticatedFetch.fetch(`${url}/fonts/${key}/weights${id ? `/${id}` : ''}`, {
          method: id ? 'PUT' : 'POST'
        }, Object.assign({}, changeset.get('data')));
        if (!response || !response.ok) {
          throw new Error();
        }
        const data = yield response.json();
        // update font object
        yield this.onSave(data.fontWeight, changeset.get('identifier'));
        this.onClose();
      } catch (e) {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('global.toasts.error.title'),
          description: this.intl.t('global.toasts.error.description'),
          duration: 2000
        });
      }
    }),
    actions: {
      close() {
        if (this.changeset.get('isPristine')) {
          this.onClose();
        } else {
          (0, _object.set)(this, 'isDiscardChangesDialog', true);
        }
      }
    }
  });
});