define("additive-account/mirage/scenarios/saas-owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * User is saas owner.
   * Organization has no partner-dependency (SaaS org);
   * 2 existing org-apps:
   *    - Voucher in trial til year 3018
   */
  function _default(server) {
    server.get('/api/me', () => {
      return {
        user: {
          id: '619855fe-a4c2-453a-8a17-8fcc963a46a4',
          givenName: 'Saas',
          familyName: 'Owner',
          fullName: 'Saas Owner',
          email: 'saas.owner@additive.eu',
          emailVerified: true,
          organizationCount: 2,
          isConfirmedAuth: false,
          isSupport: false,
          isSuperAdmin: false
        }
      };
    });
    server.get('/api/organizations/1/me', () => {
      return {
        user: {
          id: '619855fe-a4c2-453a-8a17-8fcc963a46a4',
          email: 'saas.owner@additive.eu',
          fullName: 'Saas Owner',
          isSupport: false,
          isSuperAdmin: false,
          role: 'owner',
          appRoles: []
        }
      };
    });
    server.get('/api/organizations/1', () => {
      return {
        organization: {
          id: 1,
          name: 'SaaS Org',
          email: 'info@saas.org',
          website: 'http://www.saas.org'
        }
      };
    });
    server.get('/api/organizations/1/apps', () => {
      return {
        apps: [{
          id: 2,
          name: 'voucher',
          planName: 'starter_yearly',
          active: true,
          trialEndsAt: '3018-10-11'
        }]
      };
    });
  }
});