define("additive-account/controllers/instances/instance/users/partner", ["exports", "@ember/controller", "additive-account/utils/user-management", "@ember/object"], function (_exports, _controller, _userManagement, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    /**
     * list of partner users
     *
     * @property users
     * @type {Object[]}
     */
    users: undefined,
    /**
     * The name of the partner organisation
     *
     * @property partnerName
     * @type {String}
     */
    partnerName: null,
    /**
     * Fetch Partner users task
     *
     * @property task
     * @type {Task}
     */
    getPartnerUsers: _userManagement.getPartnerUsers,
    /**
     * whether the users have loaded
     *
     * @property isLoading
     * @type {Boolean}
     */
    isLoading: _object.computed.alias('getPartnerUsers.task.isRunning')
  });
});