define("additive-account/routes/instances/instance/users/partner", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "additive-account/utils/user-management"], function (_exports, _route, _object, _service, _userManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    setupController(controller) {
      _userManagement.getPartnerUsers.task.perform(this, controller).then(response => response.users).then(users => {
        (0, _object.set)(controller, 'users', users);
      });
      (0, _object.set)(controller, 'partnerName', this.modelFor('instances.instance.users').name);
    }
  });
});