define("additive-account/components/aa-email-template-configurator/typography", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _emberArgTypes, _propTypes, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.editor.group @title={{t "components.aaEmailTemplateConfigurator.general.layout.title"}}>
    <AwTypographyList
      @corporateDesignTypography={{this.corporateDesignTypography}}
      @typographyKeys={{array "h2" "p" "label" "button"}}
      @styles={{this.styles}}
      @fonts={{this.fonts}}
      @fontOptions={{this.fontOptions}}
      @onUpdate={{this._update}}
      @showCustomFontWarning={{false}}
      @intlBaseKey="routes.emailTemplates.configurator.typography"
      @isReadOnly={{@isReadOnly}}
    />
  </this.editor.group>
  */
  {
    "id": "lX2yJRFZ",
    "block": "[[[8,[30,0,[\"editor\",\"group\"]],null,[[\"@title\"],[[28,[37,0],[\"components.aaEmailTemplateConfigurator.general.layout.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@corporateDesignTypography\",\"@typographyKeys\",\"@styles\",\"@fonts\",\"@fontOptions\",\"@onUpdate\",\"@showCustomFontWarning\",\"@intlBaseKey\",\"@isReadOnly\"],[[30,0,[\"corporateDesignTypography\"]],[28,[37,2],[\"h2\",\"p\",\"label\",\"button\"],null],[30,0,[\"styles\"]],[30,0,[\"fonts\"]],[30,0,[\"fontOptions\"]],[30,0,[\"_update\"]],false,\"routes.emailTemplates.configurator.typography\",[30,1]]],null],[1,\"\\n\"]],[]]]]]],[\"@isReadOnly\"],false,[\"t\",\"aw-typography-list\",\"array\"]]",
    "moduleName": "additive-account/components/aa-email-template-configurator/typography.hbs",
    "isStrictMode": false
  });
  let AaEmailTemplateConfiguratorTypography = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.array), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.object), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AaEmailTemplateConfiguratorTypography extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the typogrpahy defined in the corporate design
       * @argument corporateDesignTypography
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "corporateDesignTypography", _descriptor, this);
      _initializerDefineProperty(this, "editor", _descriptor2, this);
      _initializerDefineProperty(this, "fonts", _descriptor3, this);
      _initializerDefineProperty(this, "fontOptions", _descriptor4, this);
      _initializerDefineProperty(this, "isReadOnly", _descriptor5, this);
      _initializerDefineProperty(this, "styles", _descriptor6, this);
      _initializerDefineProperty(this, "onUpdate", _descriptor7, this);
    }
    _update(key, value) {
      this.onUpdate(`styles.${key}`, value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "corporateDesignTypography", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "editor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fonts", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fontOptions", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "styles", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_update"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaEmailTemplateConfiguratorTypography);
});