define("additive-account/components/aa-corporate-design/logo", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "additive-account/utils/organization-utils"], function (_exports, _component, _object, _computed, _service, _organizationUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component to upload the default and alternative logo of the corporate design
   *
   * ```hbs
   * {{aa-corporate-design/logo}}
   * ```
   *
   * @class logo-upload
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['aa-corporate-design__logo'],
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * the fetched corporate design of the organization
     *
     * @property corporateDesign
     * @type {Object}
     * @default undefined
     */
    corporateDesign: undefined,
    /**
     * the fetched logo url of the organization
     *
     * @property logoUrl
     * @type {String}
     * @default undefined
     */
    logoUrl: (0, _computed.alias)('corporateDesign.logoUrl'),
    /**
     * the fetched signet url of the organization
     *
     * @property signetUrl
     * @type {String}
     * @default undefined
     */
    signetUrl: (0, _computed.alias)('corporateDesign.signetUrl'),
    /**
     * the fetched alternative logo url of the organization
     *
     * @property alternativeLogoUrl
     * @type {String}
     * @default undefined
     */
    alternativeLogoUrl: (0, _computed.alias)('corporateDesign.alternativeLogoUrl'),
    /**
     * task fetching the corporate design of the organization
     *
     * @property _fetchCorporateDesignTask
     * @type {Task}
     * @default null
     * @private
     */
    _fetchCorporateDesignTask: null,
    /**
     * whether logo tab is active or not
     *
     * @computed _isLogoTabActive
     * @type {Boolean}
     * @private
     */
    _isLogoTabActive: (0, _computed.equal)('router.currentRouteName', 'instances.instance.corporate-design.logo'),
    /**
     * whether loading skeletons are shown or not
     * skeletons are only displayed if the fetch corporate design task is running
     * and the logo tab is active
     *
     * @computed _isLoading
     * @type {Boolean}
     * @private
     */
    _isLoading: (0, _computed.and)('_fetchCorporateDesignTask.isRunning', '_isLogoTabActive'),
    didInsertElement() {
      this._super(...arguments);
      const fetchCorporateDesignTask = _organizationUtils.fetchCorporateDesign.task;
      (0, _object.set)(this, '_fetchCorporateDesignTask', fetchCorporateDesignTask);
      fetchCorporateDesignTask.perform(this).then(corporateDesign => {
        (0, _object.set)(this, 'corporateDesign', corporateDesign);
      });
    },
    actions: {
      onLogoChanged(imageUrl, propertyName) {
        this.corporateDesign && propertyName && (0, _object.set)(this, `corporateDesign.${propertyName}`, imageUrl);
      }
    }
  });
});