define("additive-account/mirage/endpoints/index", ["exports", "additive-account/config/environment", "additive-account/mirage/endpoints/auth-routes", "additive-account/mirage/endpoints/cors-interaction-routes", "additive-account/mirage/endpoints/templates", "additive-account/mirage/endpoints/organization-routes", "additive-account/mirage/endpoints/audit-log-routes"], function (_exports, _environment, _authRoutes, _corsInteractionRoutes, _templates, _organizationRoutes, _auditLogRoutes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initAll = void 0;
  const initAll = server => {
    /* Only fake requests for tests */
    if (_environment.default.environment === 'test') {
      (0, _authRoutes.authRoutes)(server);
      (0, _organizationRoutes.organizationRoutes)(server);
      (0, _corsInteractionRoutes.externalRoutes)(server);
    }
    (0, _templates.list)(server);
    (0, _templates.detail)(server);
    (0, _templates.detail)(server);
    (0, _auditLogRoutes.auditLogsRoute)(server);
  };
  _exports.initAll = initAll;
});