define("additive-account/templates/instances/instance/settings/integrations", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this._fetch.isRunning}}
    <UiSkeleton @classNames="pv3" @items={{1}} @itemsPerRow={{1}} @cardHeight={{32}} />
    <UiSkeleton @classNames="pt3" @items={{3}} @itemsPerRow={{1}} @cardHeight={{72}} @gap={{4}} />
  {{else}}
    {{#unless this.hasDefinedIntegrations}}
      <div class="flex flex-column items-center">
        {{svg-jar "cycle"}}
  
        <div class="font-sm primary">
          {{t "routes.settings.integrations.empty.text"}}
        </div>
  
        <div class="font-sm secondary text-center pt2 pb4">
          {{t "routes.settings.integrations.empty.info" htmlSafe=true}}
        </div>
  
        {{#if this.isManager}}
          <UiButton
            @class="mt2"
            @label={{t "routes.settings.integrations.empty.button.text"}}
            @onClick={{fn (mut this.isAddIntegrationDialog) true}}
          />
        {{/if}}
      </div>
    {{/unless}}
  
    {{#if this.hasDefinedIntegrations}}
      <UiIntro @title={{t "routes.settings.integrations.title"}} @size="xl" @class="mb2" />
  
      <AaSettings::Integrations::List @pms={{this.pms}} @onSuccess={{perform this._fetch}} />
    {{/if}}
  
    {{#if this.isAddIntegrationDialog}}
      <AaSettings::Integrations::AddDialog
        @onAdd={{this.setPms}}
        @onClose={{fn (mut this.isAddIntegrationDialog) false}}
      />
    {{/if}}
  {{/if}}
  */
  {
    "id": "si2tJxTT",
    "block": "[[[41,[30,0,[\"_fetch\",\"isRunning\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@classNames\",\"@items\",\"@itemsPerRow\",\"@cardHeight\"],[\"pv3\",1,1,32]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@classNames\",\"@items\",\"@itemsPerRow\",\"@cardHeight\",\"@gap\"],[\"pt3\",3,1,72,4]],null],[1,\"\\n\"]],[]],[[[41,[51,[30,0,[\"hasDefinedIntegrations\"]]],[[[1,\"    \"],[10,0],[14,0,\"flex flex-column items-center\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"cycle\"],null]],[1,\"\\n\\n      \"],[10,0],[14,0,\"font-sm primary\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"routes.settings.integrations.empty.text\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"font-sm secondary text-center pt2 pb4\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"routes.settings.integrations.empty.info\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isManager\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@class\",\"@label\",\"@onClick\"],[\"mt2\",[28,[37,4],[\"routes.settings.integrations.empty.button.text\"],null],[28,[37,6],[[28,[37,7],[[30,0,[\"isAddIntegrationDialog\"]]],null],true],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasDefinedIntegrations\"]],[[[1,\"    \"],[8,[39,8],null,[[\"@title\",\"@size\",\"@class\"],[[28,[37,4],[\"routes.settings.integrations.title\"],null],\"xl\",\"mb2\"]],null],[1,\"\\n\\n    \"],[8,[39,9],null,[[\"@pms\",\"@onSuccess\"],[[30,0,[\"pms\"]],[28,[37,10],[[30,0,[\"_fetch\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isAddIntegrationDialog\"]],[[[1,\"    \"],[8,[39,11],null,[[\"@onAdd\",\"@onClose\"],[[30,0,[\"setPms\"]],[28,[37,6],[[28,[37,7],[[30,0,[\"isAddIntegrationDialog\"]]],null],false],null]]],null],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"ui-skeleton\",\"unless\",\"svg-jar\",\"t\",\"ui-button\",\"fn\",\"mut\",\"ui-intro\",\"aa-settings/integrations/list\",\"perform\",\"aa-settings/integrations/add-dialog\"]]",
    "moduleName": "additive-account/templates/instances/instance/settings/integrations.hbs",
    "isStrictMode": false
  });
});