define("additive-account/templates/components/aa-settings/room-counts/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  {{#if this._showList}}
    <UiList
      @title={{t (concat "routes.settings.roomCounts." this.key)}}
      @items={{this.items}}
      @class={{this.classNames}}
      @isLoading={{this.isLoading}}
      @placeholderCount={{3}}
      @isError={{this.isError}}
      as |list item|
    >
      <list.item
        @title={{t "routes.settings.roomCounts.numOfRooms" count=item.count}}
        @description={{format-date item.startsAt day="2-digit" month="long" year="numeric"}}
        @onClick={{action this.onClick item}}
        @isDisabled={{this.isReadOnly}}
        @state={{this.state}}
        as |controls|
      >
        {{#if this.state}}
          {{controls.state}}
        {{/if}}
  
        {{#unless this.isReadOnly}}
          <controls.right>
            <UiItemMenu as |content|>
              <content.menuItem
                @label={{t "global.actions.delete"}}
                @isDanger={{true}}
                @onClick={{action this.onDelete item}}
              />
            </UiItemMenu>
          </controls.right>
        {{/unless}}
      </list.item>
  
      <list.error>
        <div class="flex justify-center font-sm secondary pt3">
          <UiButton @label={{t "global.actions.retry"}} @onClick={{perform this.fetchRoomCounts}} />
        </div>
      </list.error>
    </UiList>
  {{/if}}
  */
  {
    "id": "H+P2C7qr",
    "block": "[[[41,[30,0,[\"_showList\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@items\",\"@class\",\"@isLoading\",\"@placeholderCount\",\"@isError\"],[[28,[37,2],[[28,[37,3],[\"routes.settings.roomCounts.\",[30,0,[\"key\"]]],null]],null],[30,0,[\"items\"]],[30,0,[\"classNames\"]],[30,0,[\"isLoading\"]],3,[30,0,[\"isError\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"item\"]],null,[[\"@title\",\"@description\",\"@onClick\",\"@isDisabled\",\"@state\"],[[28,[37,2],[\"routes.settings.roomCounts.numOfRooms\"],[[\"count\"],[[30,2,[\"count\"]]]]],[28,[37,4],[[30,2,[\"startsAt\"]]],[[\"day\",\"month\",\"year\"],[\"2-digit\",\"long\",\"numeric\"]]],[28,[37,5],[[30,0],[30,0,[\"onClick\"]],[30,2]],null],[30,0,[\"isReadOnly\"]],[30,0,[\"state\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"state\"]],[[[1,\"        \"],[1,[30,3,[\"state\"]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,0,[\"isReadOnly\"]]],[[[1,\"        \"],[8,[30,3,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,4,[\"menuItem\"]],null,[[\"@label\",\"@isDanger\",\"@onClick\"],[[28,[37,2],[\"global.actions.delete\"],null],true,[28,[37,5],[[30,0],[30,0,[\"onDelete\"]],[30,2]],null]]],null],[1,\"\\n          \"]],[4]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[3]]]]],[1,\"\\n\\n    \"],[8,[30,1,[\"error\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"flex justify-center font-sm secondary pt3\"],[12],[1,\"\\n        \"],[8,[39,8],null,[[\"@label\",\"@onClick\"],[[28,[37,2],[\"global.actions.retry\"],null],[28,[37,9],[[30,0,[\"fetchRoomCounts\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1,2]]]]],[1,\"\\n\"]],[]],null]],[\"list\",\"item\",\"controls\",\"content\"],false,[\"if\",\"ui-list\",\"t\",\"concat\",\"format-date\",\"action\",\"unless\",\"ui-item-menu\",\"ui-button\",\"perform\"]]",
    "moduleName": "additive-account/templates/components/aa-settings/room-counts/list.hbs",
    "isStrictMode": false
  });
});