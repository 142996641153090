define("additive-account/components/aa-email-template-preview", ["exports", "@ember/component", "additive-account/config/environment", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "ember-arg-types", "prop-types", "ember-concurrency", "@ember/template-factory"], function (_exports, _component, _environment, _component2, _tracking, _service, _object, _emberArgTypes, _propTypes, _emberConcurrency, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <iframe
    id="email-preview-iframe"
    class="w-100 dn flex flex-column flex-grow-1"
    frameBorder="0"
    scrolling="no"
    title="email-preview"
    {{did-insert this.updateTemplate}}
    {{did-update this.updateTemplate this.urlQueryParams}}
  />
  
  {{#if this._isError}}
    <div class="flex flex-column justify-center items-center h-100">
      <div class="font-sm secondary pb2">
        {{t "global.errors.unknown"}}
      </div>
      <UiButton @label={{t "global.actions.retry"}} @onClick={{perform this._loadMailTemplate}} />
    </div>
  {{/if}}
  
  {{#if this._isInitialLoading}}
    <div class="flex h-100 justify-center items-center">
      <UiLoading />
    </div>
  {{/if}}
  */
  {
    "id": "KO/+SEIH",
    "block": "[[[11,\"iframe\"],[24,1,\"email-preview-iframe\"],[24,0,\"w-100 dn flex flex-column flex-grow-1\"],[24,\"frameBorder\",\"0\"],[24,\"scrolling\",\"no\"],[24,\"title\",\"email-preview\"],[4,[38,0],[[30,0,[\"updateTemplate\"]]],null],[4,[38,1],[[30,0,[\"updateTemplate\"]],[30,0,[\"urlQueryParams\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"_isError\"]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-column justify-center items-center h-100\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-sm secondary pb2\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"global.errors.unknown\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],null,[[\"@label\",\"@onClick\"],[[28,[37,3],[\"global.actions.retry\"],null],[28,[37,5],[[30,0,[\"_loadMailTemplate\"]]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"_isInitialLoading\"]],[[[1,\"  \"],[10,0],[14,0,\"flex h-100 justify-center items-center\"],[12],[1,\"\\n    \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"did-insert\",\"did-update\",\"if\",\"t\",\"ui-button\",\"perform\",\"ui-loading\"]]",
    "moduleName": "additive-account/components/aa-email-template-preview.hbs",
    "isStrictMode": false
  });

  /**
   * Displays the email template of the current organization
   *
   * @class aa-mail-template-preview
   */
  let AaEmailTemplatePreview = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      const tasks = [];
      tasks.push(this.authenticatedFetch.fetch(this._url));
      tasks.push((0, _emberConcurrency.timeout)(250));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      const html = yield response.text();
      const iframe = document.getElementById('email-preview-iframe');
      const iframeDocument = iframe.contentDocument;
      iframeDocument.open();
      iframeDocument.write(html);
      iframeDocument.close();
      iframe.classList.remove('dn');
      iframe.style.height = `${iframeDocument.body.scrollHeight}px`;
      /* Reset height after iframe did load (as images might load slower but effects iframe-height) */
      iframe.onload = () => iframe.style.height = `${iframeDocument.body.scrollHeight}px`;
      this._isError = false;
      this.onUpdate();
    } catch (e) {
      const iframe = document.getElementById('email-preview-iframe');
      if (iframe) {
        const iframeDocument = iframe.contentDocument;
        iframeDocument.open();
        iframeDocument.write('');
        iframeDocument.close();
      }
      this._isError = true;
    } finally {
      this._isInitialLoading = false;
    }
  }), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AaEmailTemplatePreview extends _component2.default {
    /**
     * The url for the email html
     *
     * @computed _url
     * @type {Object}
     * @default null
     * @private
     */
    get _url() {
      const mailEndpoint = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.organization.id}/email`;
      return mailEndpoint + (this._urlQueryParams ? `?${this._urlQueryParams}` : '');
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      /**
       * The url query params
       *
       * @argument urlQueryParams
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "urlQueryParams", _descriptor4, this);
      /**
       * The organization resource
       *
       * @argument organization
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "organization", _descriptor5, this);
      _initializerDefineProperty(this, "_defaultUrlQueryParams", _descriptor6, this);
      /**
       * Whether we have an error
       *
       * @property _isError
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isError", _descriptor7, this);
      /**
       * Whether it is initialy loading
       *
       * @property _isInitialLoading
       * @type {Boolean}
       * @default true
       * @private
       */
      _initializerDefineProperty(this, "_isInitialLoading", _descriptor8, this);
      /**
       * All query params (default + args)
       *
       * @argument _urlQueryParams
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_urlQueryParams", _descriptor9, this);
      /**
       * loads the email template and writes it into the iframe
       *
       * @function _loadMailTemplate
       * @type {Task}
       * @private
       */
      _initializerDefineProperty(this, "_loadMailTemplate", _descriptor10, this);
      /**
       * When iframe did update
       *
       * @argument onUpdate
       * @type {Function}
       */
      _initializerDefineProperty(this, "onUpdate", _descriptor11, this);
      if (this.urlQueryParams) {
        return;
      }
      const {
        intl,
        organization
      } = this;

      // set default mail content
      const title = intl.t('components.aaEmailTemplatePreview.title');
      const greeting = intl.t('components.aaEmailTemplatePreview.greeting');
      const text = intl.t('components.aaEmailTemplatePreview.text');
      const linkText = intl.t('components.aaEmailTemplatePreview.linkText');
      let urlParams = `contents[0][greeting]=${greeting}`;
      urlParams += `&contents[0][title]=${title}`;
      urlParams += `&contents[1][line]=${text}`;
      urlParams += `&contents[2][action][url]=${organization.website}`;
      urlParams += `&contents[2][action][text]=${linkText}`;
      this._defaultUrlQueryParams = urlParams;
    }
    updateTemplate() {
      let urlParams = this._defaultUrlQueryParams;
      if (this.urlQueryParams) {
        urlParams = `${urlParams}&${this.urlQueryParams}`;
      }
      this._urlQueryParams = urlParams;
      this._loadMailTemplate.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "urlQueryParams", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "organization", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_defaultUrlQueryParams", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_isInitialLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_urlQueryParams", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_loadMailTemplate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateTemplate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateTemplate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AaEmailTemplatePreview);
});