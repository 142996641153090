define("additive-account/controllers/instances/me/inbox/message", ["exports", "@ember/controller", "@ember/object", "@ember/template", "@ember/service"], function (_exports, _controller, _object, _template, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    intl: (0, _service.inject)(),
    /**
     * Check for every line in the content of the notification whether it is
     * an action, to display it in the template.
     *
     * @property messageContent
     * @type {Object[]}
     */
    messageContent: (0, _object.computed)('model.data.text', {
      get() {
        const content = this.model && this.model.data && this.model.data.text;
        let messageContent = [];
        if (content) {
          content.forEach(item => {
            if (item.type === 'action') {
              messageContent.push({
                isAction: true,
                text: item.text
              });
            } else {
              messageContent.push({
                isAction: false,
                text: (0, _template.htmlSafe)(item.text)
              });
            }
          });
        } else {
          messageContent.push({
            isAction: false,
            text: this.intl.t('routes.messages.message.notFound')
          });
        }
        return messageContent;
      }
    }),
    actions: {
      back() {
        if (this.model) {
          const {
            id
          } = this.model;
          this.send('markMessageAsRead', id);
        }
        this.transitionToRoute('instances.me.inbox');
      }
    }
  });
});