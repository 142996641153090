define("additive-account/routes/instances/instance/apps", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "@additive-apps/auth/mixins/access-guard", "additive-account/utils/app-management", "additive-account/mixins/global-user"], function (_exports, _route, _object, _service, _accessGuard, _appManagement, _globalUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_accessGuard.default, _globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiState: (0, _service.inject)(),
    routeIfUnauthorized: 'instances.instance',
    requiredRole: 'member',
    currentOrganization: _object.computed.alias('currentUser.currentOrganization'),
    resetController(controller) {
      this._super(...arguments);
      (0, _object.set)(controller, 'currentApp', null);
      (0, _object.set)(controller, 'apps', null);
      (0, _object.set)(controller, 'availableApps', null);
      (0, _object.set)(controller, 'addableApps', null);
      this.store.unloadAll('app');
    },
    setupController(controller) {
      this._super(...arguments);
      _appManagement.getApps.task.perform(this).then(apps => (0, _object.set)(controller, 'apps', apps));
      const currentOrganization = this.currentUser && this.currentUser.currentOrganization;

      /**
       * Fetch all bought apps with plans, needed to upgrade apps
       */
      _appManagement.getBoughtApps.task.perform(this, currentOrganization.id).then(response => {
        const availableApps = response.apps;
        (0, _object.set)(controller, 'availableApps', availableApps);
      });

      // fetch all apps a user can still purchase, needed to add apps
      _appManagement.getBuyableApps.task.perform(this, currentOrganization.id).then(response => {
        (0, _object.set)(controller, 'addableApps', response);
      });
    }
  });
});