define("additive-account/components/apps-list", ["exports", "additive-account/config/environment", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "rsvp", "@ember/utils", "@additive-apps/auth/utils/roles-map", "additive-account/utils/app-management", "additive-account/mixins/global-user", "@additive-apps/ui/constants"], function (_exports, _environment, _component, _object, _service, _emberConcurrency, _rsvp, _utils, _rolesMap, _appManagement, _globalUser, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    classNames: ['list-bottom-padding'],
    getApps: _appManagement.getApps,
    /**
     * All apps of the organization
     *
     * @property apps
     * @type {Object[]}
     */
    apps: undefined,
    /**
     * All available apps containing all the possible plans
     *
     * @property availableApps
     * @type {Object[]}
     */
    availableApps: undefined,
    /**
     * The app the user wants to upgrade
     *
     * @property activeApp
     * @type {Object}
     */
    activeApp: null,
    /**
     * All plans of the app that the user intends to upgrade
     *
     * @property activeAppPlans
     * @type {undefined}
     */
    activeAppPlans: undefined,
    /**
     * The plan that is currently active of the app the user indends to upgrade
     *
     * @property activeAppPlans
     * @type {undefined}
     */
    activePlan: undefined,
    /**
     * Wether the the dialog to to upgrade an app is shown
     *
     * @property isUpgradePlanDialog
     * @type {Boolean}
     * @default false
     */
    isUpgradePlanDialog: false,
    /**
     * Wether the the dialog to unsubscribe from an app is shown
     *
     * @property isUnsubscribeDialog
     * @type {Boolean}
     * @default false
     */
    isUnsubscribeDialog: false,
    /**
     * Sets the duration of the toast that is displayed
     *
     * @property toastDuration
     * @type {Integer}
     */
    toastDuration: 2500,
    /**
     * Array holding the apps that can be marked as managed
     *
     * @property manageableApps
     * @type {Object[]}
     * @default null
     */
    manageableApps: null,
    /**
     * Whether the managed app dialog is opened
     *
     * @property _isManagedDialog
     * @type {Boolean}
     */
    _isManagedAppDialog: false,
    /**
     * Array holding all active apps
     * An app is considered in trial if the app is active and in trial
     * The property isTrial already checks if the apps is active
     *
     * @property trialApps
     * @type {Object[]}
     */
    trialApps: _object.computed.filterBy('apps', 'isTrial', true),
    /**
     * Array holding all inactive apps
     *
     * @property inactiveApps
     * @type {Object[]}
     */
    inactiveApps: _object.computed.filterBy('apps', 'active', false),
    /**
     * Sets loading placeholder list while apps model is loading
     *
     * @property isLoading
     * @type {Boolean}
     */
    isLoading: _object.computed.alias('getApps.task.isRunning'),
    /**
     * Organizations partner slug
     *
     * @property _orgPartnerSlug
     * @type {String}
     * @private
     */
    _orgPartnerSlug: _object.computed.alias('currentUser.currentOrganization.partnerSlug'),
    /**
     * Organizations partner name
     *
     * @property _orgPartnerName
     * @type {String}
     * @private
     */
    _orgPartnerName: _object.computed.alias('currentUser.currentOrganization.partnerName'),
    /**
     * Global users partner slug
     *
     * @property _userPartnerSlug
     * @type {String}
     * @private
     */
    _userPartnerSlug: _object.computed.alias('globalUser.partnerSlug'),
    /**
     * Global users partner role
     *
     * @property _userPartnerRole
     * @type {String}
     * @private
     */
    _userPartnerRole: _object.computed.alias('globalUser.partnerRole'),
    /**
     * Current user is admin
     *
     * @property _isAdmin
     * @type {Boolean}
     * @private
     */
    _isAdmin: _object.computed.alias('currentUser.isAdmin'),
    /**
     * Current user is superadmin
     *
     * @property _isSuperAdmin
     * @type {Boolean}
     * @private
     */
    _isSuperAdmin: _object.computed.alias('currentUser.user.isSuperAdmin'),
    /**
     * Current user is an partner admin of the current organization
     *
     * @property isPartnerAdmin
     * @type {Boolean}
     */
    isPartnerAdmin: (0, _object.computed)('_orgPartnerSlug', '_userPartnerRole', '_userPartnerSlug', 'currentUser.isAdmin', 'globalUser.isAdditive', {
      get() {
        const orgPartnerSlug = this._orgPartnerSlug || 'additive-eu';
        const userPartnerSlug = this._userPartnerSlug;
        const userPartnerRole = this._userPartnerRole;
        if (this.globalUser.isAdditive && this.currentUser.isAdmin) {
          return true;
        }
        return orgPartnerSlug && userPartnerSlug && orgPartnerSlug === userPartnerSlug && (0, _rolesMap.hasAccess)('admin', userPartnerRole);
      }
    }),
    /**
     * Whether the option to set a app to manged should be visible
     *
     * @property showManagedSetting
     * @type {Boolean}
     */
    showManagedSetting: (0, _object.computed)('currentOrganization', 'currentUser.isAdmin', 'globalUser.isAdditive', {
      get() {
        const {
          currentOrganization
        } = this.currentUser;

        // Should not be visible for additive organisation
        if (currentOrganization.id === 'additive-eu') {
          return false;
        }
        return this.globalUser.isAdditive && this.currentUser.isAdmin;
      }
    }),
    /**
     * Array holding all active apps
     * An app is considered active if it is active not in trial and has an activated
     * plan
     *
     * @property activeApps
     * @type {Object[]}
     */
    activeApps: (0, _object.computed)('_isAdmin', '_isSuperAdmin', 'apps.@each.{active,isSaasBuyable,isTrial,planName}', 'availableApps.[]', 'globalUser.isAdditive', 'isPartnerAdmin', {
      get() {
        const apps = this.apps;
        if (apps) {
          const isAdmin = this._isAdmin;
          const isPartnerAdmin = this.isPartnerAdmin;
          const hasPrivileges = isPartnerAdmin || this._isSuperAdmin;
          const filteredApps = apps.filter(app => app.active && !app.isTrial);
          const upgradableApps = filteredApps.map(app => {
            // get available plans for app
            const appWithPlans = (this.availableApps || []).find(_app => _app.id === parseInt(app.id));
            const plans = appWithPlans && appWithPlans.plans.map(plan => plan.id) || [];

            // get the index of the plan
            const indexOfPlan = plans.indexOf(app.planName);
            // if the plan is standard it can be upgraded if there are plans available
            const isUpgradableStandard = app.planName === 'standard' && plans.length > 1;
            // if the plan is the last one it can not be upgraded
            const isBestPlan = indexOfPlan === plans.length - 1 || indexOfPlan === -1;
            const canUpgrade = (hasPrivileges || app.isSaasBuyable) && (!isBestPlan || isUpgradableStandard);
            const canDeactivate = hasPrivileges || isAdmin && app.isSaasBuyable;
            /* eslint-disable */
            (0, _object.set)(app, 'canUpgrade', canUpgrade);
            (0, _object.set)(app, 'canDeactivate', canDeactivate);
            (0, _object.set)(app, 'canToggleManaged', hasPrivileges);
            (0, _object.set)(app, 'isManaged', app === null || app === void 0 ? void 0 : app.managed);
            (0, _object.set)(app, 'modules', app === null || app === void 0 ? void 0 : app.modules);
            (0, _object.set)(app, 'hasDropDownActions', this.globalUser.isAdditive);
            (0, _object.set)(app, 'hasMultiplePlans', plans.length > 1);
            /* eslint-enable */

            return app;
          });
          return upgradableApps;
        }
        return apps;
      }
    }),
    /**
     * Whether the empty state of the list is displayed
     *
     * @property isEmpty
     * @type {Boolean}
     */
    isEmpty: (0, _object.computed)('apps.[]', 'isLoading', {
      get() {
        return (0, _utils.isEmpty)(this.apps) && !this.isLoading;
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'manageableApps', [{
        app: 'CRM',
        id: `${_constants.APP_ID_CRM}`
      }, {
        app: 'CON',
        id: `${_constants.APP_ID_CONTENT}`
      }, {
        app: 'MA',
        id: `${_constants.APP_ID_MARKETING_CLOUD}`
      }, {
        app: 'MI',
        id: `${_constants.APP_ID_MARKETING_INSIGHTS}`
      }]);
      const selectablePartners = [{
        name: 'ADDITIVE',
        value: 'additive'
      }];
      if (this._orgPartnerSlug && this._orgPartnerName) {
        selectablePartners.push({
          name: this._orgPartnerName,
          value: 'partner'
        });
      }
      (0, _object.set)(this, 'selectablePartners', selectablePartners);
    },
    toggleDetail() {},
    toggleModules() {},
    toggleManaged: (0, _emberConcurrency.task)(function* () {
      try {
        var _this$currentApp;
        const url = `${_environment.default.APP.apiBaseHost}/api/organizations/${this.currentUser.currentOrganization.id}/apps/${(_this$currentApp = this.currentApp) === null || _this$currentApp === void 0 ? void 0 : _this$currentApp.id}/toggle-managed`;
        const body = {
          managed: !this.currentApp.managed,
          managedBy: this.currentApp.managedBy
        };
        const response = yield this.authenticatedFetch.fetch(url, {
          method: 'POST',
          body: JSON.stringify(body)
        });
        if (!(response !== null && response !== void 0 && response.ok)) throw response;
        const json = yield response.json();
        yield this.store.pushPayload('app', json);
        (0, _object.set)(this, '_isManagedAppDialog', false);
        this.uiToast.showToast({
          type: 'success',
          title: this.intl.t('global.toasts.success.savedChanges')
        });
      } catch (e) {
        if (e.status === 400) {
          this.uiToast.showToast({
            type: 'error',
            title: this.intl.t('global.dialogs.managedDialog.appManagedError')
          });
        } else {
          this.uiToast.showToast({
            type: 'error',
            title: this.intl.t('global.toasts.error.savedChanges')
          });
        }
      }
    }),
    actions: {
      toggleDetail(app) {
        this.toggleDetail(app);
      },
      /**
       * Finds all the available plans the user can upgrade to and opens the upgrade plan dialog
       *
       * @param {Object} app the users app
       */
      openUpgradePlanDialog(app) {
        const appPlans = this.availableApps.findBy('id', parseInt(app.id)).plans;
        let activePlan;

        // standard plan is not returned by API
        if (app.planName === 'standard') {
          activePlan = {
            id: 'standard'
          };
        } else {
          // get the currently active plan out ouf the available ones
          const appPlanIds = appPlans.mapBy('id');
          const planIndex = appPlanIds.indexOf(app.planName);
          activePlan = appPlans[planIndex];
        }
        (0, _object.set)(this, 'activeAppPlans', appPlans);
        (0, _object.set)(this, 'activeApp', app);
        (0, _object.set)(this, 'activePlan', activePlan);
        (0, _object.set)(this, 'isUpgradePlanDialog', true);
      },
      /**
       * Upgrades the given apps plan
       *
       * @param {Int} id, the apps id
       * @param {String} planName the desired plan
       */
      upgradeApp(id, planName) {
        const currentOrganization = this.currentUser && this.currentUser.currentOrganization && this.currentUser.currentOrganization.id;
        return this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${currentOrganization}/apps/${id}/upgrade`, {
          method: 'POST'
        }, {
          planName: planName
        }).then(response => {
          const status = response.status;
          if (response.ok) {
            return (0, _rsvp.resolve)();
          } else {
            return response.json().then(response => {
              return (0, _rsvp.reject)({
                status: status,
                message: response.message
              });
            });
          }
        }).then(() => {
          const intl = this.intl;
          this.uiToast.showToast({
            type: 'success',
            title: intl.t('global.toasts.success.changes.title'),
            description: intl.t('global.toasts.success.changes.description'),
            duration: this.toastDuration
          });
          (0, _object.set)(this, 'activeApp.planName', planName);
          return (0, _rsvp.resolve)();
        }).catch(error => {
          const uiDialog = this.uiDialog;
          const intl = this.intl;
          // user is prompted to fill in billing data
          if (error.message === 'not_subscribed') {
            uiDialog.showConfirm(intl.t('routes.apps.dialogs.error.title'), intl.t('routes.apps.dialogs.upgradePlan.errorDialog.notSubscribed'), () => this.router.transitionTo('instances.instance.billings'), intl.t('routes.apps.dialogs.upgradePlan.errorDialog.notSubscribedAction'));
          } else {
            uiDialog.showError();
          }
        }).finally(() => {
          (0, _object.set)(this, 'isUpgradePlanDialog', false);
        });
      },
      /**
       * Determines whether the app is in trial or not and shows a confirm dialog
       * to make sure the user really wants to deactivate the app
       *
       * @param {Object} app the users app
       */
      confirmDeactivation(app) {
        if (app && app.isTrial) {
          // user wants to end app-trial
          (0, _object.set)(this, 'removalType', 'trial');
        } else if (this.isPartnerAdmin) {
          (0, _object.set)(this, 'removalType', 'deactivate');
        } else {
          (0, _object.set)(this, 'removalType', 'subscription');
        }
        (0, _object.set)(this, 'currentApp', app);
        (0, _object.set)(this, 'isUnsubscribeDialog', true);
      },
      openManageAppDialog(app) {
        (0, _object.set)(this, 'currentApp', app);
        if (!app.managed) {
          // Set "additive" as pre-selected item
          (0, _object.set)(this, 'currentApp.managedBy', 'additive');
          (0, _object.set)(this, '_isManagedAppDialog', true);
          return;
        }

        // Reset managed options if managed is currently active
        (0, _object.set)(this, 'currentApp.managedBy', null);
        this.toggleManaged.perform();
      },
      /**
       * Deactivates a given app
       *
       * @param {Object} app, the app to be deactivated
       */
      async deactivateApp(app) {
        const intl = this.intl;
        const currentOrganization = this.currentUser && this.currentUser.currentOrganization && this.currentUser.currentOrganization.id;
        try {
          const response = await this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${currentOrganization}/apps/${app.id}/deactivate`, {
            method: 'post'
          });
          if (!(response !== null && response !== void 0 && response.ok)) {
            throw response;
          }
          const deactivatedApp = await response.json();
          if (deactivatedApp && deactivatedApp.app) {
            this.store.pushPayload(deactivatedApp);
            this.store.findAll('app');
            this.uiToast.showToast({
              type: 'success',
              title: intl.t('global.toasts.success.changes.title'),
              description: intl.t('global.toasts.success.changes.description'),
              duration: this.toastDuration
            });
          }
        } catch (e) {
          if (e.status === 400) {
            this.uiDialog.showError(this.intl.t('routes.apps.dialogs.appRemoval.error.title'), this.intl.t('routes.apps.dialogs.appRemoval.error.description'));
          }
        } finally {
          (0, _object.set)(this, 'isUnsubscribeDialog', false);
        }
      },
      /**
       * Activate deactivated app
       *
       * @param {Int} id, the app-id the user wants to activate
       */
      activateApp(id) {
        const currentOrganization = this.currentUser && this.currentUser.currentOrganization && this.currentUser.currentOrganization.id;
        return this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/organizations/${currentOrganization}/apps/${id}/activate`, {
          method: 'post'
        }).then(response => response.ok ? response.json() : (0, _rsvp.reject)(response)).then(activatedApp => {
          if (activatedApp && activatedApp.app) {
            this.store.pushPayload(activatedApp);
            this.store.findAll('app');
            const intl = this.intl;
            this.uiToast.showToast({
              type: 'success',
              title: intl.t('global.toasts.success.changes.title'),
              description: intl.t('global.toasts.success.changes.description'),
              duration: this.toastDuration
            });
            return (0, _rsvp.resolve)();
          } else {
            return (0, _rsvp.reject)();
          }
        }).catch(error => {
          const intl = this.intl;
          if (error && error.status) {
            error.json().then(errorJson => {
              switch (errorJson.message) {
                case 'trial_has_ended':
                  this.uiDialog.showConfirm(intl.t('routes.apps.dialogs.error.title'), intl.t('routes.apps.dialogs.appActivation.errorDialog.notSubscribed.message'), () => this.router.transitionTo('instances.instance.billings'), intl.t('routes.apps.dialogs.appActivation.errorDialog.notSubscribed.action'));
                  break;
                case 'card_declined':
                  this.uiDialog.showConfirm(intl.t('routes.apps.dialogs.error.title'), intl.t('routes.apps.dialogs.appActivation.errorDialog.cardDeclined.message'), () => this.router.transitionTo('instances.instance.billings'), intl.t('routes.apps.dialogs.appActivation.errorDialog.cardDeclined.action'));
                  break;
                case 'expired_card':
                  this.uiDialog.showConfirm(intl.t('routes.apps.dialogs.error.title'), intl.t('routes.apps.dialogs.appActivation.errorDialog.cardExpired.message'), () => this.router.transitionTo('instances.instance.billings'), intl.t('routes.apps.dialogs.appActivation.errorDialog.cardExpired.action'));
                  break;
                default:
                  this.uiDialog.showError();
                  break;
              }
            });
          } else {
            this.uiDialog.showError();
          }
        });
      }
    }
  });
});