define("additive-account/mirage/endpoints/templates/detail", ["exports", "additive-account/mirage/helpers/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detail = void 0;
  const detail = _exports.detail = function detail(server) {
    server.get('/api/organizations/*/templates/:id', (schema, request) => {
      return (0, _model.findOrFail)(schema.templates, request.params.id);
    });
  };
});