define("additive-account/validations/organization", ["exports", "ember-changeset-validations/validators", "additive-account/validators/valid-color", "@additive-apps/utils/validators/validate-if"], function (_exports, _validators, _validColor, _validateIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.organizationLegals = _exports.layoutValidation = _exports.fullOrganization = _exports.extendedOrganization = _exports.default = void 0;
  const baseOrganization = intl => {
    const presenceMessage = intl.t('global.errors.apiQueryErrors.validation.required');
    const emailMessage = intl.t('global.errors.apiQueryErrors.validation.email');
    const invalidFormatMessage = intl.t('global.errors.apiQueryErrors.validation.invalidFormat');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      website: (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([-.]{1}[A-Za-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        message: invalidFormatMessage
      }),
      email: (0, _validators.validateFormat)({
        type: 'email',
        message: emailMessage
      })
    };
  };
  const extendedOrganization = intl => {
    const presenceMessage = intl.t('global.errors.apiQueryErrors.validation.required');
    const organization = baseOrganization(intl);
    const invalidFormatMessage = intl.t('global.errors.apiQueryErrors.validation.invalidFormat');
    return Object.assign({}, organization, {
      cmsLegacyUrl: (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        allowBlank: true,
        message: invalidFormatMessage
      }),
      currency: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      legalName: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      phone: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      addressStreet: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      addressPostalCode: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      addressLocality: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      addressRegion: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      addressCountry: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      vatId: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    });
  };
  _exports.extendedOrganization = extendedOrganization;
  const fullOrganization = intl => {
    const organization = baseOrganization(intl);
    const invalidFormatMessage = intl.t('global.errors.apiQueryErrors.validation.invalidFormat');
    const betweenMessage = intl.t('global.errors.apiQueryErrors.validation.between', {
      min: 5,
      max: 25
    });
    return Object.assign({}, organization, {
      appPlans: (0, _validateIf.default)({
        if: {
          isPartner: false
        },
        validate: (0, _validators.validateLength)({
          min: 1
        }),
        firstErrorMessage: true
      }),
      slug: [(0, _validators.validateLength)({
        min: 5,
        max: 25,
        message: betweenMessage
      }), (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(?!-)(?!.*--)[a-z0-9-]+.*[^\-]$/,
        message: invalidFormatMessage
      })]
    });
  };
  _exports.fullOrganization = fullOrganization;
  const organizationLegals = intl => {
    const invalidFormatMessage = intl.t('global.errors.apiQueryErrors.validation.invalidFormat');
    const lengthExceededMessage = intl.t('global.errors.apiQueryErrors.validation.maxLength');
    return {
      disclaimerLink: [(0, _validators.validateLength)({
        max: 255,
        message: lengthExceededMessage
      }), (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        allowBlank: true,
        message: invalidFormatMessage
      })],
      privacyLink: [(0, _validators.validateLength)({
        max: 255,
        message: lengthExceededMessage
      }), (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        allowBlank: true,
        message: invalidFormatMessage
      })],
      termsLink: [(0, _validators.validateLength)({
        max: 255,
        message: lengthExceededMessage
      }), (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        allowBlank: true,
        message: invalidFormatMessage
      })]
    };
  };
  _exports.organizationLegals = organizationLegals;
  const layoutValidation = intl => {
    const presenceOptions = {
      presence: true,
      message: intl.t('global.errors.apiQueryErrors.validation.required')
    };
    const colorOptions = {
      presence: true,
      message: intl.t('global.errors.apiQueryErrors.validation.color')
    };
    return {
      buttonBackgroundColor: [(0, _validators.validatePresence)(presenceOptions), (0, _validColor.default)(colorOptions)],
      buttonColor: [(0, _validators.validatePresence)(presenceOptions), (0, _validColor.default)(colorOptions)],
      fontColor: [(0, _validators.validatePresence)(presenceOptions), (0, _validColor.default)(colorOptions)]
    };
  };
  _exports.layoutValidation = layoutValidation;
  var _default = _exports.default = fullOrganization;
});