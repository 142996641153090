define("additive-account/components/upgrade-plan-card", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    currentUser: (0, _service.inject)(),
    /**
     * the app that should be upgraded
     *
     * @property app
     * @type {Object}
     * @default null
     */
    app: null,
    /**
     * the displayed plan
     *
     * @property plan
     * @type {Object}
     * @default null
     */
    plan: null,
    /**
     * the active plan, used to compare the prices
     *
     * @property activePlan
     * @type {Object}
     * @default null
     */
    activePlan: null,
    /**
     * passed function to upgrade app
     *
     * @function upgrade
     */
    upgrade() {}
  });
});