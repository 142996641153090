define("additive-account/helpers/or", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.or = or;
  function or(params /*, hash*/) {
    return params.reduce((value, currentValue) => value || currentValue);
  }
  var _default = _exports.default = (0, _helper.helper)(or);
});