define("additive-account/routes/instances/instance/email-templates/edit", ["exports", "@ember/routing/route", "@ember/service", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _service, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_uiFullWidthView.default, {
    store: (0, _service.inject)(),
    model(params) {
      const model = this.store.peekRecord('email-template', params.email_template_id);
      return model || this.store.findRecord('email-template', params.email_template_id);
    }
  });
});