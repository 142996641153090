define("additive-account/mirage/config", ["exports", "ember-cli-mirage", "additive-account/config/environment", "additive-account/mirage/endpoints"], function (_exports, _emberCliMirage, _environment, _endpoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.passthrough('/translations/*');
    this.passthrough('https://api.marker.io/**');
    this.passthrough('https://ssr.marker.io/**');
    this.urlPrefix = _environment.default.APP.apiBaseHost;
    (0, _endpoints.initAll)(this);
    this.post('/api/organizations/:id/settings/pms', () => {
      return {
        status: '200'
      };
    });
    this.get('/api/organizations/casablanca-2-eu/settings/pms', () => {
      return {
        pms: {
          type: 'casablanca',
          settings: {
            user: 'user',
            hasPassword: true
          }
        }
      };
    });
    this.get('/api/organizations/testhotel-post-com/settings/pms', () => {
      return {
        pms: {
          type: 'asa'
        }
      };
    });
    this.get('/api/organizations/test-com/settings/pms', () => {
      return {
        pms: {
          type: 'protel',
          settings: {
            hasToken: true
          }
        }
      };
    });
    this.get('/api/organizations/test-com/settings/pms/protel', () => {
      return {
        pms: {
          type: 'protel',
          settings: {
            hasToken: true
          }
        }
      };
    });
    this.put('/api/organizations/:id/settings/pms/protel', () => {
      return {
        status: '200'
      };
    });

    /* Only fake requests for tests */
    if (_environment.default.environment !== 'test') {
      this.passthrough('*');
      return;
    }
    this.get('/api/organizations/1/users/1', () => {
      return {
        user: {
          id: 1,
          name: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          role: 'owner',
          organizationCount: 0
        }
      };
    });
    this.get(`/api/organizations/1/me`, () => {
      return {
        user: {
          id: 1,
          name: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          role: 'admin',
          organizationCount: 0
        }
      };
    });
    this.get(`/api/organizations/instance/me`, () => {
      return {
        user: {
          id: 1,
          name: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          role: 'admin',
          organizationCount: 0
        }
      };
    });
    this.get(`/api/organizations/:id/apps`, () => {
      return {
        apps: [{
          id: 2,
          name: 'voucher',
          planName: 'starter_monthly',
          active: true,
          trialEndsAt: '3018-09-11'
        }, {
          id: 3,
          name: 'social-wall',
          planName: 'starter_monthly',
          trialEndsAt: '2010-09-11',
          active: true
        }]
      };
    });
    this.post('/api/organizations/:id/apps/2/deactivate', () => {
      return {
        app: {
          id: 2,
          name: 'voucher',
          planName: 'starter_monthly',
          active: false,
          trialEndsAt: '3018-09-11'
        }
      };
    });
    this.post('/api/organizations/:id/apps/3/deactivate', () => {
      return {
        app: {
          id: 3,
          name: 'social-wall',
          planName: 'starter_monthly',
          active: false
        }
      };
    });
    this.post('/api/organizations/:id/apps/2/activate', () => {
      return {
        app: {
          id: 2,
          name: 'voucher',
          planName: 'starter_monthly',
          active: true,
          trialEndsAt: '2018-09-11'
        }
      };
    });
    this.post('/api/organizations/:id/apps/3/activate', () => {
      return {
        app: {
          id: 3,
          name: 'social-wall',
          planName: 'starter_monthly',
          active: true
        }
      };
    });
    this.post('/api/organizations/:id/apps/2/upgrade', () => {
      return new _emberCliMirage.default.Response(204, {}, {});
    }), this.post('/api/organizations/:id/apps/', () => {
      return {
        app: {
          id: 4,
          name: 'content',
          planName: 'starter_monthly',
          trialEndsAt: '9000-09-11'
        }
      };
    });
    this.get('/api/apps', () => {
      return {
        apps: [{
          id: 2,
          name: 'voucher',
          plans: [{
            id: 'professional_monthly'
          }, {
            id: 'enterprise_monthly'
          }]
        }, {
          id: 3,
          name: 'social-wall',
          plans: [{
            id: 'professional_monthly'
          }, {
            id: 'enterprise_monthly'
          }]
        }, {
          id: 4,
          name: 'content',
          plans: [{
            id: 'professional_monthly'
          }, {
            id: 'enterprise_monthly'
          }]
        }]
      };
    });
    this.get('/create', () => {
      return new _emberCliMirage.default.Response(201, {}, {});
    });
    this.post('/create', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      return schema.organizations.create(attrs);
    });
    /**
     * We set the orgCount to 1 as default to cover the more
     * common usecase in all acceptance tests.
     */
    this.get('/api/me', () => {
      return {
        user: {
          id: 1,
          givenName: 'First',
          familyName: 'First',
          email: 'first@g.mail',
          website: 'http://www.first-comes-first.com',
          organizationCount: 1,
          emailVerified: true
        }
      };
    });
    this.get('/api/notifications', () => {
      return {
        notifications: [{
          id: '0',
          notifiableId: '36db0442-11e7-4204-b43e-8cb159ec1afd',
          data: {
            title: 'Luis hat Ihre Einladung angenommen',
            text: [{
              type: 'line',
              text: 'Luis hat Ihre Einladung angenommen.'
            }, {
              type: 'action',
              text: ['Benutzer verwalten', 'http://www.test.it']
            }]
          }
        }, {
          id: '1',
          notifiableId: '36db0442-11e7-4204-b43e-8cb159ec1afd',
          data: {
            title: 'Arno hat Ihre Einladung angenommen',
            text: [{
              type: 'line',
              text: 'Luis hat Ihre Einladung angenommen.'
            }, {
              type: 'action',
              text: ['Benutzer verwalten', 'http://www.test.it']
            }]
          }
        }]
      };
    });
    this.get('/api/notifications/:id', () => {
      return {
        notification: {
          id: '0',
          notifiableId: '36db0442-11e7-4204-b43e-8cb159ec1afd',
          data: {
            title: 'Luis hat Ihre Einladung angenommen',
            text: [{
              type: 'line',
              text: 'Luis hat Ihre Einladung angenommen.'
            }, {
              type: 'action',
              text: ['Benutzer verwalten', 'http://www.test.it']
            }]
          }
        }
      };
    });
    this.get('/api/countries', () => {
      return {
        countries: [{
          name: 'Italy',
          code: 'IT'
        }, {
          name: 'Burkina Faso',
          code: 'BF'
        }]
      };
    });
  }
});