define("additive-account/routes/instances/instance/email-templates/list", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    /* Overwrite default model, otherwise it will try to fetch */
    model() {
      return;
    }
  });
});