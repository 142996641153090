define("additive-account/mirage/helpers/server", ["exports", "miragejs"], function (_exports, _miragejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFoundResponse = void 0;
  /**
   * Returns a 404 Response
   *
   * @param {Object?} headers Optional headers
   * @param {Object?} body Optional body
   */
  const notFoundResponse = _exports.notFoundResponse = function notFoundResponse() {
    let headers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new _miragejs.Response(404, headers, body);
  };
});