define("additive-account/controllers/instances/me/index", ["exports", "additive-account/config/environment", "@ember/controller", "@ember/service", "@ember/object", "ember-concurrency", "ember-changeset", "additive-account/validations/auth", "ember-changeset/utils/is-changeset", "ember-changeset-validations", "additive-account/utils/me-actions", "additive-account/mixins/global-user"], function (_exports, _environment, _controller, _service, _object, _emberConcurrency, _emberChangeset, _auth, _isChangeset, _emberChangesetValidations, _meActions, _globalUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiLocale: (0, _service.inject)(),
    uiState: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    queryParams: ['email_verification_code', 'token'],
    /**
     * token query param
     *
     * @property token
     * @type {String}
     */
    token: null,
    /**
     * email_verification_code query param
     *
     * @property email_verification_code
     * @type {String}
     */
    email_verification_code: null,
    /**
     * whether an image is currently being uploaded
     * @property isUploadingImage
     * @type {Boolean}
     */
    isUploadingImage: false,
    /**
     * the min dimensions for the image upload
     *
     * @property minImageDimensions
     * @type {Array}
     */
    minImageDimensions: undefined,
    /**
     * the image upload url
     *
     * @property uploadUrl
     * @type {String}
     */
    uploadUrl: null,
    hasConfirmedPrivileges: _object.computed.alias('globalUser.isConfirmedAuth'),
    hasEmptyImage: _object.computed.not('globalUser.imagePath'),
    isDeleteImageDisabled: _object.computed.or('hasEmptyImage', 'isUploadingImage'),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'minImageDimensions', [512, 512]);
      (0, _object.set)(this, 'uploadUrl', `${_environment.default.APP.apiBaseHost}/api/me/image`);
    },
    /**
     * Handles a simple HTTP_PUT on the current user
     */
    updateUser: (0, _emberConcurrency.task)(function* (changeset) {
      if ((0, _isChangeset.default)(changeset) && changeset.get('isDirty')) {
        yield changeset.validate();
        if (changeset.get('isValid')) {
          try {
            const language = changeset.get('language');
            const response = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/me`, {
              method: 'PUT'
            }, {
              givenName: changeset.get('givenName'),
              familyName: changeset.get('familyName'),
              language
            });
            if (!response || response.status !== 200) throw new Error();
            const {
              user
            } = yield response.json();

            // switch locale if user changed language
            if (language !== this.uiLocale.locale) {
              yield this.uiLocale.setLocale(language);
            }
            (0, _object.setProperties)(this, {
              globalUser: user,
              isDetailDialog: false
            });
          } catch (e) {
            this.uiDialog.showAlert(this.intl.t('global.dialogs.billingsError.title'), this.intl.t('global.dialogs.billingsError.description', {
              htmlSafe: true
            }));
          }
        }
      }
    }),
    /**
     * Check the current users auth-status
     *
     * in case the user performed a login recently
     * the isConfirmedAuth-property will be true, otherwise false.
     *
     * The confirmed auth is requried to perform certain 'restricted' actions.
     * If the isConfirmedAuth is false the user has to validate
     * it's credentials, before he can perform the intended action.
     *
     * @function updateCredentials
     * @type {Task}
     */
    updateCredentials: (0, _emberConcurrency.task)(function* () {
      (0, _object.set)(this, 'isFetchingAuthStatus', true);
      const validation = (0, _auth.changePasswordValidation)(this.intl);
      const userChangeset = new _emberChangeset.default({
        password: '',
        passwordConfirmation: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.setProperties)(this, {
        userChangeset,
        isChangePasswordDialog: true
      });
      const user = yield this.currentUser.getUser();
      if (user && user.isConfirmedAuth === true) {
        (0, _object.set)(this, 'isFetchingAuthStatus', false);
      } else {
        (0, _object.set)(this, 'isConfirmCredentialsDialog', true);
      }
    }),
    /**
     * verifies the confirmed password
     *
     * @function confirmPassword
     * @type {Task}
     */
    confirmPassword: (0, _emberConcurrency.task)(function* () {
      const changeset = this.userChangeset;
      yield changeset.validate('password');
      if (changeset.get('isValid')) {
        try {
          yield _meActions.refreshCredentials.task.perform(changeset.get('password'), this);
          changeset.rollback();
          (0, _object.set)(this, 'isFetchingAuthStatus', false);
          (0, _object.set)(this, 'isConfirmCredentialsDialog', false);
        } catch (e) {
          const errorMessage = this.intl.t('global.errors.passwordInvalid');
          changeset.addError('password', errorMessage);
        }
      }
    }).drop(),
    /**
     * updates the password of the user
     *
     * @function updatePassword
     * @type {Task}
     */
    updatePassword: (0, _emberConcurrency.task)(function* () {
      const changeset = this.userChangeset;
      yield changeset.validate();
      if (changeset.get('isValid')) {
        try {
          yield _meActions.updatePassword.task.perform(changeset.get('password'), this);
          (0, _object.set)(this, 'isChangePasswordDialog', false);
          this.uiToast.showToast({
            type: 'success',
            title: this.intl.t('global.toasts.success.changes.title'),
            description: this.intl.t('global.toasts.success.changes.description'),
            duration: 2000
          });
        } catch (e) {
          // check statusCode and respond accordingly
          if (status === 422) {
            const errorMessage = this.intl.t('global.errors.passwordInvalid');
            changeset.addError('password', errorMessage);
          } else if (status === 403) {
            // the confirmation expired in the meantime
            changeset.rollback();
            // prompt for new password confirmation
            (0, _object.set)(this, 'isConfirmCredentialsDialog', true);
          }
        }
      }
    }).drop(),
    actions: {
      closeCredentialsConfirmation() {
        (0, _object.set)(this, 'isChangePasswordDialog', false);
        (0, _object.set)(this, 'isConfirmCredentialsDialog', false);
      },
      uploadImage(imageUrl, isUploading) {
        (0, _object.setProperties)(this, {
          'globalUser.imagePath': imageUrl,
          isUploadingImage: isUploading
        });
      }
    }
  });
});