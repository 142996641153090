define("additive-account/routes/instances/instance/settings/room-counts", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    setupController(controller, model) {
      this._super(controller, model);
      controller.fetchRoomCounts.perform();
    },
    resetController(controller) {
      (0, _object.set)(controller, 'roomCounts', null);
    }
  });
});