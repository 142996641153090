define("additive-account/templates/instances/instance/corporate-design/typography", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  <div class="pa1">
    {{#if this.fonts}}
      <div class="font-sm secondary mw6">
        {{t "routes.corporateDesign.typography.fonts.subtitle" htmlSafe=true}}
      </div>
      <AaCorporateDesign::Fonts
        @fonts={{this.fonts}}
        @updateFonts={{action "updateFonts"}}
        @reloadTypography={{this.fetchCD}}
        @isReadOnly={{not this.isManager}}
      />
    {{else if this.fetchCD.isRunning}}
      <div class="mt3">
        <UiSkeleton @items={{3}} @itemsPerRow={{3}} @cardHeight={{160}} />
      </div>
    {{/if}}
  
    {{#if this.fetchCD.isRunning}}
      <UiSkeleton @items={{7}} @itemsPerRow={{1}} @cardHeight={{80}} @gap={{1}} />
    {{else if this.typography}}
      <div class="font-sm secondary mw6 pv2">
        {{t "routes.corporateDesign.typography.description" htmlSafe=true}}
      </div>
      <div class="bt b--black-10">
        <AaCorporateDesign::Typography
          @fonts={{this.fonts}}
          @typography={{this.typography}}
          @isReadOnly={{not this.isManager}}
          @colors={{this.colors}}
          @onSave={{this.updateProperty}}
        />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "mwBFynqk",
    "block": "[[[10,0],[14,0,\"pa1\"],[12],[1,\"\\n\"],[41,[30,0,[\"fonts\"]],[[[1,\"    \"],[10,0],[14,0,\"font-sm secondary mw6\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"routes.corporateDesign.typography.fonts.subtitle\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@fonts\",\"@updateFonts\",\"@reloadTypography\",\"@isReadOnly\"],[[30,0,[\"fonts\"]],[28,[37,3],[[30,0],\"updateFonts\"],null],[30,0,[\"fetchCD\"]],[28,[37,4],[[30,0,[\"isManager\"]]],null]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"fetchCD\",\"isRunning\"]],[[[1,\"    \"],[10,0],[14,0,\"mt3\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@items\",\"@itemsPerRow\",\"@cardHeight\"],[3,3,160]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[1,\"\\n\"],[41,[30,0,[\"fetchCD\",\"isRunning\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@items\",\"@itemsPerRow\",\"@cardHeight\",\"@gap\"],[7,1,80,1]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"typography\"]],[[[1,\"    \"],[10,0],[14,0,\"font-sm secondary mw6 pv2\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"routes.corporateDesign.typography.description\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"bt b--black-10\"],[12],[1,\"\\n      \"],[8,[39,6],null,[[\"@fonts\",\"@typography\",\"@isReadOnly\",\"@colors\",\"@onSave\"],[[30,0,[\"fonts\"]],[30,0,[\"typography\"]],[28,[37,4],[[30,0,[\"isManager\"]]],null],[30,0,[\"colors\"]],[30,0,[\"updateProperty\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[13]],[],false,[\"if\",\"t\",\"aa-corporate-design/fonts\",\"action\",\"not\",\"ui-skeleton\",\"aa-corporate-design/typography\"]]",
    "moduleName": "additive-account/templates/instances/instance/corporate-design/typography.hbs",
    "isStrictMode": false
  });
});