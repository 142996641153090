define("additive-account/components/ui-app-selection", ["exports", "additive-account/config/environment", "@ember/component", "@ember/object", "@ember/service", "additive-account/mixins/global-user", "@additive-apps/ui/utils/app-utils", "ember-concurrency"], function (_exports, _environment, _component, _object, _service, _globalUser, _appUtils, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ```hbs
   * {{ui-app-selection}}
   * ```
   * @class ui-app-selection
   */
  var _default = _exports.default = _component.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    tagName: '',
    /**
     * if set there are displayed some loading-placeholders
     * instead of the actual content.
     *
     * @property isOpen
     * @type {Boolean}
     * @default false
     */
    isOpen: false,
    /**
     * All available and addable apps to cart
     *
     * @property availableApps
     * @type {Array}
     * @default null
     */
    availableApps: null,
    /**
     * The selected apps in the cart
     *
     * @property selectedApps
     * @type {Array}
     * @default null
     */
    selectedApps: null,
    /**
     * The active-app to activate, will be passed to app-selection-dialog component.
     *
     * @property activeApp
     * @type {Object}
     * @default null
     */
    activeApp: null,
    /**
     * Contains all apps that are not yet in cart
     *
     * @property addableApps
     * @type {Array}
     * @default []
     */
    addableApps: (0, _object.computed)('availableApps.[]', 'selectedApps.@each.id', {
      get() {
        if (!this.availableApps) {
          return [];
        }
        const selectedApps = this.selectedApps;
        const selectedAppIds = selectedApps && selectedApps.mapBy('appId');
        if (!selectedAppIds) {
          return this.availableApps;
        }

        // Only get apps that are not currently in cart
        const addableApps = this.availableApps.filter(availableApp => false === selectedAppIds.includes(availableApp.id));
        return addableApps;
      }
    }),
    loadAvailableApps: (0, _emberConcurrency.task)(function* () {
      const request = this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/apps?buyable=1`);
      const availableApps = yield request.then(response => response.json());
      if (!availableApps) {
        return;
      }
      availableApps.apps && availableApps.apps.sort((item, next) => item.id < next.id ? -1 : 1);
      (0, _object.set)(this, 'availableApps', availableApps.apps ? availableApps.apps : []);
      const activeApp = this.activeApp;
      if (activeApp && activeApp.id && !activeApp.plans) {
        const activeAppModel = this._getAppModelById(activeApp.id);
        activeAppModel && (0, _object.set)(this, 'activeApp', activeAppModel);
      }
    }).on('didInsertElement'),
    /**
     * addApp event.
     *
     * @event addApp
     * @param {Event} event The click event
     * @return {Array}
     */
    addApp() {},
    /**
     * removeAppFromCart event.
     *
     * @event removeApp
     * @param {Event} event The click event
     * @return {Array}
     */
    removeApp() {},
    /**
     * Find by the given app-id param the complete app-model with plans etc. from the fetched availableApps
     *
     * @function _getAppModelById
     * @private
     * @return {Object}
     */
    _getAppModelById(appId) {
      const availableApps = this.availableApps;
      const activeAppModel = availableApps && availableApps.findBy('id', appId);
      return activeAppModel;
    },
    actions: {
      /**
       * Adds a new object to the selectedApps-Array
       *
       * @param {Int} app The app to add
       * @param {String} name The stripe plan-name
       * @param {Int} amount The amount the plan costs
       */
      addApp(app, plan) {
        const appId = app.id;
        const {
          id,
          amount
        } = plan;
        const appKey = (0, _appUtils.getAppKeyById)(appId);
        // Check if app is already selected
        this.addApp(appId, appKey, id, amount);
        this.send('closeAppList');
      },
      /**
       * Removes a new object to the selectedApps-Array
       *
       * @param {Int} appId The if of the app selected app
       */
      removeApp(appId) {
        this.removeApp(appId);
      },
      toggleOpen() {
        const open = this.isOpen;
        (0, _object.set)(this, 'isOpen', !open);
      },
      openAppList() {
        (0, _object.set)(this, 'isAppListDialog', true);
      },
      closeAppList() {
        // Close dialog and reset to list-view
        (0, _object.set)(this, 'isAppListDialog', false);
        // Reset activeApp to not re-open the app-plans dialog
        (0, _object.set)(this, 'activeApp', null);
      }
    }
  });
});