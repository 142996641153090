define("additive-account/components/typography-item", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/template", "ember-changeset", "ember-changeset-validations", "additive-account/validations/corporate-design", "ember-concurrency", "additive-account/utils/constants-fonts", "additive-account/utils/fonts"], function (_exports, _component, _object, _computed, _service, _template, _emberChangeset, _emberChangesetValidations, _corporateDesign, _emberConcurrency, _constantsFonts, _fonts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which displays and allows to change typography styles:
   *   * color
   *   * fontFamily
   *   * fontSize
   *   * fontWeight (only displayed, not possible to change)
   *   * lineHeight
   *   * letter-spacing
   *   * uppercase
   *
   * It requires a changeset with the typography styles, the type (e.g. h1, h2, p, ...)
   * and a colorCompositions object
   *
   * @class typography-item
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    classNames: ['typography-item', 'flex flex-column flex-row-sm items-center-sm', 'pv2 ph2-sm bb b--black-10 cpointer'],
    /**
     * Changeset with the typography data
     *
     * @property changeset
     * @type {Object}
     * @default null
     */
    changeset: null,
    /**
     * object of all available color compositions
     *
     * @property colorCompositions
     * @type {Object}
     * @default null
     */
    colorCompositions: null,
    /**
     * The type of the typography item
     *
     * @property type
     * @type {String}
     * @default undefined
     */
    type: undefined,
    /**
     * Whether the detail dialog is open or not
     *
     * @property _isDetailOpen
     * @type {Boolean}
     * @default false
     */
    _isDetailOpen: false,
    isLoading: (0, _computed.alias)('onSave.isRunning'),
    color: (0, _computed.alias)('changeset.color'),
    fontFamily: (0, _computed.alias)('changeset.fontFamily'),
    fontSize: (0, _computed.alias)('changeset.fontSize'),
    letterSpacing: (0, _computed.alias)('changeset.letterSpacing'),
    lineHeight: (0, _computed.alias)('changeset.lineHeight'),
    uppercase: (0, _computed.alias)('changeset.uppercase'),
    styles: (0, _computed.alias)('changeset.data'),
    /**
     * computed CSS style for the background color of the color dot
     *
     * @computed colorDotStyle
     * @type {String}
     */
    colorDotStyle: (0, _object.computed)('color', 'colorCompositions', {
      get() {
        var _this$colorCompositio;
        return (0, _template.htmlSafe)(`background-color: ${(_this$colorCompositio = this.colorCompositions) === null || _this$colorCompositio === void 0 || (_this$colorCompositio = _this$colorCompositio[this.color]) === null || _this$colorCompositio === void 0 ? void 0 : _this$colorCompositio.color};`);
      }
    }),
    /**
     * computed object containing the name and color code of the currently selected color
     *
     * @computed fontColor
     * @type {Object}
     */
    fontColor: (0, _object.computed)('color', 'colorCompositions', {
      get() {
        const {
          color,
          colorCompositions,
          intl
        } = this;
        return {
          name: intl.t(`routes.corporateDesign.colors.${color}.name`),
          colorCode: colorCompositions && colorCompositions[color] && colorCompositions[color].color
        };
      }
    }),
    /**
     * computed styles of the typography name
     *
     * @computed fontStyle
     * @type {String}
     */
    fontStyle: (0, _object.computed)('fontFamily', 'fontSize', 'lineHeight', 'uppercase', 'letterSpacing', 'fontOptions.@each.value', {
      get() {
        const {
          fontFamily,
          fontOptions,
          fontSize,
          lineHeight,
          letterSpacing,
          uppercase
        } = this;
        const font = fontOptions.find(option => option.value === fontFamily);
        const fontFamilyWithFallbacks = this._addFallbackFonts(font);
        const styles = `
        font-family: ${fontFamilyWithFallbacks};
        font-size: ${fontSize};
        line-height: ${lineHeight};
        letter-spacing: ${letterSpacing}em;
        ${uppercase ? 'text-transform: uppercase;' : ''}
      `;
        return (0, _template.htmlSafe)(styles);
      }
    }),
    /**
     * computed styles of the typography type
     *
     * @computed typeStyle
     * @type {String}
     */
    typeStyle: (0, _object.computed)('fontSize', {
      get() {
        return this.fontSize ? (0, _template.htmlSafe)(`height: ${this.fontSize};`) : '';
      }
    }),
    /**
     * computed textual description of the current font size
     *
     * @computed fontSizesText
     * @type {String}
     */
    fontSizesText: (0, _object.computed)('fontSize', 'lineHeight', {
      get() {
        return `font-size: ${this.fontSize}, line-height: ${this.lineHeight}`;
      }
    }),
    /**
     * computed array containing the font options
     *
     * @computed fontOptions
     * @type {Array}
     */
    fontOptions: (0, _object.computed)('fonts.@each.fontFamily', {
      get() {
        const {
          fonts
        } = this;
        const customFonts = [];
        Object.keys(fonts || {}).forEach(key => {
          _constantsFonts.FONT_TYPES.forEach(type => {
            if (fonts[key] && fonts[key][type]) {
              const {
                fontFamily,
                url,
                weight
              } = fonts[key][type];
              customFonts.push({
                // the value displayed in the select is the name of the fontFamily
                name: fontFamily,
                // the internal value is the key.type. e.g.: `primary.normal`
                value: `${key}.${type}`,
                url,
                serif: fonts[key].serif,
                weight,
                isCustom: true,
                isFirstOfCustom: customFonts.length === 0
              });
            }
          });
        });
        return [...customFonts, {
          name: this.intl.t('routes.corporateDesign.defaultFonts.sansSerif'),
          value: _constantsFonts.sansSerifFontsFallbacks,
          serif: false,
          isFirstOfGroup: true
        }, ..._constantsFonts.sansSerifFonts, {
          name: this.intl.t('routes.corporateDesign.defaultFonts.serif'),
          value: _constantsFonts.serifFontsFallbacks,
          serif: true,
          isFirstOfGroup: true
        }, ..._constantsFonts.serifFonts];
      }
    }),
    /**
     * the name of the fontfamily used in the preview
     *
     * @computed previewFontFamily
     * @type {String}
     */
    previewFontFamily: (0, _object.computed)('fontFamily', 'fonts.@each.fontFamily', {
      get() {
        if (_constantsFonts.sansSerifFontsFallbacks === this.fontFamily) {
          return (0, _template.htmlSafe)('Standard <span class="font-xs secondary">&nbsp;(Serifenlos)<span>');
        } else if (_constantsFonts.serifFontsFallbacks === this.fontFamily) {
          return (0, _template.htmlSafe)('Standard <span class="font-xs secondary">&nbsp;(Serifen)<span>');
        } else if (this.fontFamily.indexOf('primary') >= 0 || this.fontFamily.indexOf('secondary') >= 0) {
          var _this$fonts, _font;
          const fontFamilyParts = this.fontFamily.split('.');
          let font = (_this$fonts = this.fonts) === null || _this$fonts === void 0 ? void 0 : _this$fonts[fontFamilyParts[0]];
          if (fontFamilyParts.lenght > 1) {
            font = font[fontFamilyParts[1]];
          }
          return (_font = font) === null || _font === void 0 ? void 0 : _font.fontFamily;
        }
        return this.fontFamily;
      }
    }),
    init() {
      this._super(...arguments);
      const typographyValidation = (0, _corporateDesign.typographyValidations)(this.intl);
      const typographyChangeset = new _emberChangeset.default(this.typography[this.type], (0, _emberChangesetValidations.default)(typographyValidation), typographyValidation);
      (0, _object.set)(this, 'changeset', typographyChangeset);
      (0, _object.set)(this, 'colorOptions', [{
        value: 'main'
      }, {
        value: 'accent'
      }, {
        value: 'ambient'
      }]);
    },
    didInsertElement() {
      this._super(...arguments);
      this._loadFont();
    },
    /**
     * Function executed when component is clicked
     * @function click
     */
    click() {
      (0, _object.set)(this, '_isDetailOpen', true);
    },
    /**
     * Task which gets performed after save click.
     *
     * @param {Object} changeset
     * @property onSave
     * @type {Task}
     * @return {Promise|Void} Returns a promise
     * @private
     */
    onSave: (0, _emberConcurrency.task)(function* () {}).drop(),
    /**
     * Closes the details modal
     * @function _close
     * @private
     */
    _close() {
      (0, _object.set)(this, '_isDetailOpen', false);
    },
    /**
     * searches the suitable fallback fonts for a given fontFamily
     *
     * @param {String} fontFamily
     * @function _getFallbackFonts
     * @private
     */
    _addFallbackFonts(font) {
      if (font) {
        const fontFamily = font.isCustom ? `${font.name}-${font.weight}` : font.name;
        return `'${fontFamily}', ${font.serif ? _constantsFonts.serifFontsFallbacks : _constantsFonts.sansSerifFontsFallbacks}`;
      }
    },
    /**
     * loads the active font family into the dom
     *
     * @function _loadFont
     * @private
     */
    _loadFont() {
      const font = this.fontOptions.find(font => font.value === this.fontFamily && font.isCustom);
      if (font && font.url) {
        // load fonts as `font:weight` to allow multiple fonts using the same name
        (0, _fonts.loadFont)(`${font.name}-${font.weight}`, font.url);
      }
    },
    actions: {
      _closeDialog() {
        const changeset = this.changeset;
        if (changeset && changeset.isDirty) {
          this.uiDialog && this.uiDialog.showDiscardChangesConfirm(() => {
            if (this.isDestroying) {
              return;
            }
            changeset.rollback();
            this._close();
          });
        } else {
          this._close();
        }
      },
      onSave(changeset) {
        return this.onSave.perform(changeset, 'typography').then(() => {
          this._close();
          this._loadFont();
        });
      },
      onSelectChange(font) {
        this.changeset.set('fontFamily', font.value);
        this.changeset.set('serif', font.serif);
      }
    }
  });
});