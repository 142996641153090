define("additive-account/routes/instances/me/inbox", ["exports", "additive-account/config/environment", "@ember/routing/route", "@ember/service", "@ember/object", "@ember/template"], function (_exports, _environment, _route, _service, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    async model() {
      var _json$notifications;
      const response = await this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/api/notifications`);
      const json = await response.json();
      json.notifications = (_json$notifications = json.notifications) === null || _json$notifications === void 0 ? void 0 : _json$notifications.map(notification => {
        if (!notification.data || !notification.data.text) {
          return notification;
        }
        notification.data.text = notification.data.text.map(text => {
          text.type === 'line' && (text.text = (0, _template.htmlSafe)(text.text));
          return text;
        });
        return notification;
      });
      return json.notifications;
    },
    actions: {
      /**
       * Mark a message as read when the dialog-detail is closed
       */
      markMessageAsRead(id) {
        const detailModel = this.currentModel.findBy('id', id);
        if (detailModel && !detailModel.readAt) {
          (0, _object.set)(detailModel, 'readAt', true);
        }
      }
    }
  });
});