define("additive-account/templates/components/aa-corporate-design/typography", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.typographyTypes as |typographyType|}}
    <TypographyItem
      @colorCompositions={{this.colors}}
      @typography={{this.typography}}
      @type={{typographyType}}
      @fonts={{this.fonts}}
      @onSave={{this.onSave}}
      @isReadOnly={{this.isReadOnly}}
    />
  {{/each}}
  */
  {
    "id": "pwGREhf5",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"typographyTypes\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@colorCompositions\",\"@typography\",\"@type\",\"@fonts\",\"@onSave\",\"@isReadOnly\"],[[30,0,[\"colors\"]],[30,0,[\"typography\"]],[30,1],[30,0,[\"fonts\"]],[30,0,[\"onSave\"]],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n\"]],[1]],null]],[\"typographyType\"],false,[\"each\",\"-track-array\",\"typography-item\"]]",
    "moduleName": "additive-account/templates/components/aa-corporate-design/typography.hbs",
    "isStrictMode": false
  });
});