define("additive-account/components/app-selection-dialog", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "additive-account/mixins/global-user", "@additive-apps/auth/utils/roles-map"], function (_exports, _component, _object, _service, _emberConcurrency, _globalUser, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ADDITIVE_SLUG = 'additive-eu';
  var _default = _exports.default = _component.default.extend(_globalUser.default, {
    uiDialog: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    tagName: '',
    /**
     *  Active app in the dialog
     *
     * @property activeApp
     * @type {Object}
     * @default null
     */
    activeApp: null,
    /**
     *  Active plan in the dialog
     *
     * @property activePlan
     * @type {Object}
     * @default null
     */
    activePlan: null,
    /**
     *  Apps
     *
     * @property availableApps
     * @type {Object[]}
     * @default undefined
     */
    availableApps: undefined,
    /**
     *  Text displayed in the button when activating app
     *
     * @property actionText
     * @type {String}
     * @default null
     */
    actionText: null,
    /**
     * Whether a organization exists
     *
     * @property hasOrganization
     * @type {Boolean}
     * @default false
     */
    hasOrganization: false,
    /**
     *  whether the plan selection dialog is shown
     *
     * @property showPlans
     * @type {Boolean}
     * @default false
     */
    showPlans: false,
    /**
     *  Apps with plans are shown first
     *
     * @property sortedApps
     * @type {Object[]}
     * @default undefined
     */
    sortedApps: _object.computed.sort('availableApps', 'plans'),
    /**
     *  Whether an app is being added
     *
     * @property addingApp
     * @type {Boolean}
     * @default false
     */
    isAddingApp: _object.computed.alias('addAppTask.isRunning'),
    /**
     * Organizations partner slug
     *
     * @property _orgPartnerSlug
     * @type {String}
     * @private
     */
    _orgPartnerSlug: _object.computed.alias('currentUser.currentOrganization.partnerSlug'),
    /**
     * Global users partner slug
     *
     * @property _userPartnerSlug
     * @type {String}
     * @private
     */
    _userPartnerSlug: _object.computed.alias('globalUser.partnerSlug'),
    /**
     * Global users partner role
     *
     * @property _userPartnerRole
     * @type {String}
     * @private
     */
    _userPartnerRole: _object.computed.alias('globalUser.partnerRole'),
    /**
     * Display CMS only for admins of the partner organization ADDITIVE
     *
     * @property _isCMSEnabled
     * @type {Boolean}
     * @private
     */
    _isCMSEnabled: _object.computed.and('_isAdditiveUser', '_isPartnerAdmin'),
    /**
     * Current user is ADDITIVE user
     *
     * @property _isAdditiveUser
     * @type {Boolean}
     * @private
     */
    _isAdditiveUser: (0, _object.computed)('_userPartnerSlug', function () {
      return this._userPartnerSlug === ADDITIVE_SLUG;
    }),
    /**
     * Current user is a partner admin of the current organization
     *
     * @property _isPartnerAdmin
     * @type {Boolean}
     * @private
     */
    _isPartnerAdmin: (0, _object.computed)('_orgPartnerSlug', '_userPartnerRole', '_userPartnerSlug', 'hasOrganization', {
      get() {
        const orgPartnerSlug = this._orgPartnerSlug;
        const userPartnerSlug = this._userPartnerSlug;
        const userPartnerRole = this._userPartnerRole;
        if (this.hasOrganization) {
          return orgPartnerSlug && userPartnerSlug && orgPartnerSlug === userPartnerSlug && (0, _rolesMap.hasAccess)('admin', userPartnerRole);
        } else {
          return (0, _rolesMap.hasAccess)('admin', userPartnerRole);
        }
      }
    }),
    didReceiveAttrs() {
      this._super();
      // if app is preselected show plan selection dialog
      const activeApp = this.activeApp;
      if (activeApp && this._appHasSelectablePlans(activeApp)) {
        (0, _object.set)(this, 'showPlans', true);
      }
    },
    addAppTask: (0, _emberConcurrency.task)(function* (app, plan) {
      yield this.addApp(app, plan);
    }).drop(),
    addApp() {},
    /**
     *  Determine whether the app has plans that the user can choose from
     *
     * @param {*} app
     * @returns {Boolean}
     */
    _appHasSelectablePlans(app) {
      // get the complete app-model by app-id from param
      const appModel = this.availableApps.findBy('id', app.id);
      return appModel && appModel.plans && appModel.plans.length > 1;
    },
    actions: {
      showPlans(app) {
        if (this.availableApps && app) {
          (0, _object.set)(this, 'activeApp', app);
          (0, _object.set)(this, 'showPlans', true);
        }
      },
      onAddApp(app, plan) {
        return this.addAppTask.perform(app, plan);
      },
      closePlans() {
        (0, _object.set)(this, 'showPlans', false);
        (0, _object.set)(this, 'activeApp', null);
      }
    }
  });
});