define("additive-account/templates/instances", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  {{#if this.session.isAuthenticated}}
    {{outlet}}
  
    <UiPusher
      @channelName={{this.pusherChannelName}}
      @event="Illuminate\Notifications\Events\BroadcastNotificationCreated"
      @destinationElementSelector=".views__footer"
      as |pusher|
    >
      {{#each pusher.data as |notification|}}
        {{#unless notification.isClosed}}
          <UiNotification
            @title={{notification.title}}
            @icon="people"
            @isFloating={{true}}
            @onClose={{action (mut notification.isClosed) true}}
          >
            <div class="flex items-center">
              <UiButton
                @class="mr1"
                @isSuccess={{true}}
                @label={{get notification "text.2.text.0"}}
                @href={{get notification "text.2.text.1"}}
              />
            </div>
          </UiNotification>
        {{/unless}}
      {{/each}}
    </UiPusher>
  {{/if}}
  */
  {
    "id": "Q03CoHpg",
    "block": "[[[41,[30,0,[\"session\",\"isAuthenticated\"]],[[[1,\"  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@channelName\",\"@event\",\"@destinationElementSelector\"],[[30,0,[\"pusherChannelName\"]],\"Illuminate\\\\Notifications\\\\Events\\\\BroadcastNotificationCreated\",\".views__footer\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1,[\"data\"]]],null]],null],null,[[[41,[51,[30,2,[\"isClosed\"]]],[[[1,\"        \"],[8,[39,7],null,[[\"@title\",\"@icon\",\"@isFloating\",\"@onClose\"],[[30,2,[\"title\"]],\"people\",true,[28,[37,8],[[30,0],[28,[37,9],[[30,2,[\"isClosed\"]]],null],true],null]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n            \"],[8,[39,10],null,[[\"@class\",\"@isSuccess\",\"@label\",\"@href\"],[\"mr1\",true,[28,[37,11],[[30,2],\"text.2.text.0\"],null],[28,[37,11],[[30,2],\"text.2.text.1\"],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"pusher\",\"notification\"],false,[\"if\",\"component\",\"-outlet\",\"ui-pusher\",\"each\",\"-track-array\",\"unless\",\"ui-notification\",\"action\",\"mut\",\"ui-button\",\"get\"]]",
    "moduleName": "additive-account/templates/instances.hbs",
    "isStrictMode": false
  });
});